import { ethers } from "ethers";
import React from "react";
import styled from "styled-components";
import { chainList } from "../../../../controller/chainId";
import { NFTItemProps } from "../../../../interface/components/interface";
import theme from "../../../../theme";
import { calculateDateDifference } from "../../../../utils/DiffDate";
import {
  addressTrim,
  capitalizeFirstLetter,
  tokenIdTrim,
} from "../../../../utils/TrimString";
import { useStandard } from "../../../../contexts/StandardContext";
import { handleCopyClick } from "../../../../utils/Copy";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../../../modules/Modal";

export const Detail = (props: NFTItemProps) => {
  const { standard, setStandard } = useStandard();
  const alertModal = useSetRecoilState(alertModalState);

  const check = async () => {
    const rpcUrl = chainList[props.chainId]?.rpcUrls[0];
    if (!rpcUrl) {
      throw new Error(`No RPC URL found for chain ID ${props.chainId}`);
    }
    const otherProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
    const contract = new ethers.Contract(
      props.tokenAddress,
      [
        "function supportsInterface(bytes4 interfaceID) external view returns (bool)",
      ],
      otherProvider
    );
    const is721 = await contract.supportsInterface("0x80ac58cd");
    if (is721) {
      if (props.network === "ethereum" || props.network === "polygon") {
        setStandard("ERC-721");
        return;
      } else if (props.network === "binance") {
        setStandard("BEP-721");
        return;
      } else if (props.network === "klaytn") {
        setStandard("KIP-17");
        return;
      }
    }
    const is1155 = await contract.supportsInterface("0xd9b67a26");
    if (is1155) {
      if (props.network === "ethereum" || props.network === "polygon") {
        setStandard("ERC-1155");
        return;
      } else if (props.network === "binance") {
        setStandard("BEP-1155");
        return;
      } else if (props.network === "klaytn") {
        setStandard("KIP-37");
        return;
      }
    }
    setStandard("unknown");
    return;
  };

  React.useEffect(() => {
    check();
  }, [props]);

  return (
    <Wrap>
      <Row>
        <Text>Contract Address</Text>
        <Text
          color={theme.colors.amount}
          onClick={() => {
            // handleCopyClick(props.tokenAddress)
            window.open(
              `${chainList[props.chainId].host}/address/${props.tokenAddress}`,
              "_blank"
            );
          }}
        >
          {addressTrim(props.tokenAddress, 6, 4)}
        </Text>
      </Row>
      <Row>
        <Text>Token ID</Text>
        <Text
          color={theme.colors.amount}
          onClick={() => {
            handleCopyClick(props.tokenId.toString());
            alertModal({
              isModal: true,
              content: "Copied Token ID!",
            });
          }}
        >
          {tokenIdTrim(props.tokenId, 10)}
        </Text>
      </Row>
      <Row>
        <Text>Token Standard</Text>
        <Text>{standard}</Text>
      </Row>
      <Row>
        <Text>Chain</Text>
        <Text>{capitalizeFirstLetter(props.network)}</Text>
      </Row>
      <Row>
        <Text>Last Updated</Text>
        <Text>{calculateDateDifference(props.lastDate)}</Text>
      </Row>
    </Wrap>
  );
};

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 800px) {
    gap: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.span<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : theme.colors.white)};
  cursor: ${(props) => (props.color ? "pointer" : "")};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1.3vw;
  line-height: 1.4vw;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 680px) {
    font-size: 12px;
    line-height: 15px;
  }
`;
