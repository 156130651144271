import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { useList } from '../../contexts/ListContext';


export const viewType1 = {grid: true, row:2};
export const viewType2 = {grid: true, row:3};
export const viewType3 = {grid: false, row:0};

export interface selectedViewOptionType {
    grid: boolean;
    row: number;
};

interface ListStyleButtonProps {
    selectedViewOption: selectedViewOptionType;
    setSelectedViewOption: Dispatch<SetStateAction<selectedViewOptionType>>;
  }

const ListStyleButton = () => {

  const {selectedViewOption,
    setSelectedViewOption} = useList();

    // const handleOptionClick = (item: selectedViewOptionType) => {
    //   setSelectedViewOption(item);
      
    //   const params = new URLSearchParams(location.search);
    //   params.set('gr', item.value);
  
    //   navigate({
    //     search: params.toString(),
    //   });
    // };
  

  return (
    <Container>
      <Button active={selectedViewOption.row === 2} onClick={() => setSelectedViewOption(viewType1)}>
        <Grid4Icon />
      </Button>
      <Button active={selectedViewOption.row === 3} onClick={() => setSelectedViewOption(viewType2)}>
        <Grid9Icon />
      </Button>
      <Button active={selectedViewOption.row === 0} onClick={() => setSelectedViewOption(viewType3)}>
        <ListIcon />
      </Button>
    </Container>
  );
};


const Container = styled.div`
  display: flex;
  background-color: #2d2d2d;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 680px) {
    display: none;
  }
`;

const Button = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ active }) => (active ? theme.colors.white : theme.colors.side)};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  svg {
    fill: ${({ active }) => (active ? '#8E2FF9' : '#fff')};
  }
`;

const Grid4Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <rect x="3" y="3" width="8" height="8" />
    <rect x="13" y="3" width="8" height="8" />
    <rect x="3" y="13" width="8" height="8" />
    <rect x="13" y="13" width="8" height="8" />
  </svg>
);

const Grid9Icon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
    <rect x="2" y="2" width="6" height="6" />
    <rect x="9" y="2" width="6" height="6" />
    <rect x="16" y="2" width="6" height="6" />
    <rect x="2" y="9" width="6" height="6" />
    <rect x="9" y="9" width="6" height="6" />
    <rect x="16" y="9" width="6" height="6" />
    <rect x="2" y="16" width="6" height="6" />
    <rect x="9" y="16" width="6" height="6" />
    <rect x="16" y="16" width="6" height="6" />
  </svg>
  );

  
const ListIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <rect x="3" y="5" width="18" height="2" />
    <rect x="3" y="11" width="18" height="2" />
    <rect x="3" y="17" width="18" height="2" />
  </svg>
);

export default ListStyleButton;
