import activeGridIcon from '../assets/icons/active-grid.png'
import inactiveGridIcon from '../assets/icons/inactive-grid.png'
import bottomArrowIcon from '../assets/icons/bottom-arrow.png'
import topArrowIcon from '../assets/icons/top-arrow.png'
import personalIcon from '../assets/icons/personal.png'
import walletIcon from '../assets/icons/wallet.png'
import closeIcon from '../assets/icons/close.png'
import xIcon from '../assets/icons/twitter.png'
import telegramIcon from '../assets/icons/telegram.png'
import instagramIcon from '../assets/icons/instagram.png'
import mailIcon from '../assets/icons/mail.png'

import main from '../assets/imgs/main.png'
import homeTop from '../assets/imgs/homeTop.png'
import decorate from '../assets/imgs/decorate.png'
import homeBottom from '../assets/imgs/homeBottom.png'
import testImage from '../assets/imgs/test.png'

import walletConnect from '../assets/wallet/walletConnect.png'
import coinbase from '../assets/wallet/coinbase.png'
import metamask from '../assets/wallet/metamask.png'
import kaikas from '../assets/wallet/kaikas.png'

import test2Image from '../assets/imgs/test2.png'

import Logo from '../assets/logo/logo.png'

const icons = {
    activeGrid: activeGridIcon,
    inactiveGrid: inactiveGridIcon,
    bottomArrow: bottomArrowIcon,
    topArrow: topArrowIcon,
    personal: personalIcon,
    wallet: walletIcon,
    close: closeIcon,
    x: xIcon,
    instagram: instagramIcon,
    telegram: telegramIcon,
    mail: mailIcon,
}

const images = {
    main: main,
    homeTop: homeTop,
    homeBottom: homeBottom,
    decorate: decorate,
    test: testImage,
    test2: test2Image,
}

const wallet = {
    walletConnect: walletConnect,
    coinbase: coinbase,
    metamask: metamask,
    kaikas: kaikas,
}

export const assets = {
    logo: Logo,
    wallet: wallet,
    icons,
    images,
}