import DefaultClient from "./DefaultClient";


/**
  로그인
   * @param {string} id 아이디
   * @param {string} password 비밀번호
*/
const login = async (id: string, password: string) => {
    const postData = {
        id: id,
        password: password
    };
    const res = await DefaultClient.post('/api/admin/login', postData);
    return res.data;
}

/**
  NFT 메타데이터 긁어오기
   * @param {string} header 헤더
   * @param {string} network chain
   * @param {string} contract nft컨트렉트 주소
   * @param {string} standard nft 표준
   * @param {number} startBlock 시작 블록
*/
const getContractMeta = async (header: string, network: string, contract: string, standard: string, startBlock: string) => {
    const postData = {
        network: network,
        contractAddress: contract,
        standard: standard,
        startBlock: startBlock,
    };
    const res = await DefaultClient.post('/api/admin/nft', postData, {
        headers: {
            'x-access-token': header
        }
    });
    return res.data;
}

/**
  등록 전 NFT 리스트 조회(최근 등록된 NFT 조회)
   * @param {string} header 헤더
   * @param {string} network chain
   * @param {string} contract nft컨트렉트 주소
   * @param {number} size 가져올 갯수
   * @param {number} currentPage 페이지
   * @param {string?} tokenId nft 토큰ID
*/
const getNFTList = async (header: string, network: string, contract: string, size: number, currentPage: number, tokenId?: string) => {
    const postData = {
        network: network,
        tokenAddress: contract,
        size: size,
        page: currentPage,
        tokenId: tokenId,
    };
    const res = await DefaultClient.post('/api/admin/nft/before', postData, {
        headers: {
            'x-access-token': header
        }
    });
    return res.data;
}


/**
  NFT 등록
   * @param {string} header 헤더
   * @param {number} pk pk
   * @param {string} category 카테고리
   * @param {string} description 설명
   * @param {string} name NFT 이름
   * @param {number} series 시리즈 여부(0 없음, 1 있음)
*/
const registerNFT = async (header: string, pk: number, category: string, description: string, name: string, series: number) => {
    const postData = {
        pk: pk,
        category: category,
        description: description,
        name: name,
        series: series,
    };
    const res = await DefaultClient.post('/api/admin/nft/enroll', postData, {
        headers: {
            'x-access-token': header
        }
    });
    return res.data;
}

/**
  NFT 수정
   * @param {string} header 헤더
   * @param {number} pk pk
   * @param {string} category 카테고리
   * @param {string} description 설명
   * @param {string} name NFT 이름
   * @param {number} series 시리즈 여부(0 없음, 1 있음)
*/
const updateNFT = async (header: string, pk: number, category: string, description: string, name: string, series: number) => {
    const postData = {
        pk: pk,
        category: category,
        description: description,
        name: name,
        series: series,
    };
    const res = await DefaultClient.post('/api/admin/nft/after/update', postData, {
        headers: {
            'x-access-token': header
        }
    });
    return res.data;
}


/**
  수수료 저장
   * @param {string} header 헤더
   * @param {string} fee 수수료
*/
const setSetting = async (header: string, fee: string) => {
    const postData = {
        fee: fee
    }
    const res = await DefaultClient.post('/api/admin/fee', postData, {
        headers: {
            'x-access-token': header
        }
    });
    return res.data;
}



const adminApi = {
    login,
    getContractMeta,
    getNFTList,
    registerNFT,
    updateNFT,
    setSetting
};

export default adminApi;