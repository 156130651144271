import React, { useState } from 'react';
import styled from 'styled-components';
import { FaFilter, FaTimes, FaCheck } from 'react-icons/fa';
import { selectBoxItem, useList } from '../../../contexts/ListContext';
import { NFTInfoItemProps, SelectBoxItemProps } from '../../../interface/components/interface';
import { useNavigate } from 'react-router-dom';
// import Overlay from '../Common/Overlay';

import { isDesktop } from "react-device-detect";
import { NFTFilter } from '../../Filter/NFTFilter';
import { NFTInfo } from '../../NFTItem/Info/NFTInfo';
import { StatusChild } from '../../Filter/Children.tsx/StatusChild';
import { PriceChild } from '../../Filter/Children.tsx/PriceChild';



interface OptionProps {
    selected: boolean;
    onClick: () => void;
  }

  
export const FilterModal = () => {
    const {selectedOption, setSelectedOption, mFilterOpen, setMFilterOpen,} = useList();
    const navigate = useNavigate();

    const handleOptionClick = (item: SelectBoxItemProps) => {
        setSelectedOption(item);
        setMFilterOpen(false);
        
        const params = new URLSearchParams(location.search);
        params.set('order', item.value);
    
        navigate({
          search: params.toString(),
        });
        setMFilterOpen(false);
      };

  if (!mFilterOpen) {
    return null;
  }


  const items: NFTInfoItemProps[] = [
    {
      title: "Status",
      child: <StatusChild />,
      radius: "10px 10px 0 0",
      topRadius: "10px 10px 0 0",
      bottomRadius: "0",
      openState: true,
    },
    {
      title: "Price",
      child: <PriceChild />,
      // radius: "0",
      radius: "0 0 10px 10px",
      topRadius: "0",
      // bottomRadius: "0",
      bottomRadius: "0 0 10px 10px",
      openState: true,
    },
    // {
    //   title: "Currency",
    //   child: <CurrencyChild />,
    //   radius: "0",
    //   topRadius: "0",
    //   bottomRadius: "0",
    //   openState: false,
    // },
    // {
    //   title: "Traits",
    //   child: <TraitsChild />,
    //   radius: "0 0 10px 10px",
    //   topRadius: "0",
    //   bottomRadius: "0 0 10px 10px",
    //   openState: false,
    // },
  ];


  return (
    <Overlay>
      <Popup>
        <Header>
          <FaFilter />
          <Title>Filter</Title>
          <CloseButton onClick={() => setMFilterOpen(false)}>
            <FaTimes />
          </CloseButton>
        </Header>
        <FilterContainer>
      {/* <FilterHeader>Filter</FilterHeader> */}
      <NFTInfo items={items} />
    </FilterContainer>

      </Popup>
    </Overlay>
  );
};


const FilterContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  color: white;
`;


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
`;

const Popup = styled.div`
  position: ${isDesktop ? 'relative' : 'fixed'};
  bottom: 0;
  background: #1c1f24;
  border-radius: ${isDesktop ? '15px' : '15px 15px 0 0'};
  width: ${isDesktop ? '50vw' : '100%'};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #333;
`;

const Title = styled.h2`
  font-size: 18px;
  margin: 0;
  color: #fff;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

const OptionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Option = styled.li<OptionProps>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: ${({ selected }) => (selected ? '#2b2f35' : 'none')};
  color: ${({ selected }) => (selected ? '#4caf50' : '#fff')};

  &:hover {
    background: #2b2f35;
  }

  svg {
    color: #4caf50;
  }
`;
