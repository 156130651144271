import styled from "styled-components";
import theme from "../../theme";
import React from 'react';

export default function BigButton({
  text,
  white = false,
  submit = false,
  onClick,
  isPrev = false,
}: {
  text: string;
  white?: boolean;
  submit?: boolean;
  onClick?: () => void;
  isPrev?: boolean;
}) {
  return (
    <BigButtonContainer
      isPrev={isPrev}
      type={submit ? "submit" : "button"}
      $white={white}
      onClick={onClick}
      className={`${isPrev && "prev"}`}
    >
      {text}
    </BigButtonContainer>
  );
}

const BigButtonContainer = styled.button<{ $white: boolean; isPrev: boolean }>`
  width: 204px;
  height: 58px;
  background: ${props => props.$white ? theme.colors.white : theme.colors.peacok};
  border-radius: 64px;
  font-weight: 700;
  font-size: ${theme.fontType.medium16.fontSize};
  font-weight: ${theme.fontType.medium16.bold};
  color: ${props => props.$white ? theme.colors.peacok : theme.colors.white};
  text-align: center;
  /* border: 1px solid ${props => props.$white ? theme.colors.peacok : theme.colors.white}; */
  &.prev {
    background-color: #fff;
    /* border: 1px solid ${theme.colors.peacok}; */
    color: ${theme.colors.peacok};
  }


  @media (max-width: 680px) {
    width: 30vw;
    height: auto;
    padding: 10px;
  }
`;
