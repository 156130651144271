import React from "react";
import { useList } from "../../../contexts/ListContext";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../../modules/Modal";
import { removeComma, setComma } from "../../../utils/Comma";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme";
import styled from "styled-components";

export const PriceChild = () => {
  const alertModal = useSetRecoilState(alertModalState);
  const navigate = useNavigate();
  const { minPrice, setMinPrice, maxPrice, setMaxPrice } = useList();

  const onApply = () => {
    if (minPrice.trim() !== "" && maxPrice.trim() !== "") {
      if (Number(minPrice) > Number(maxPrice)) {
        alertModal({
          isModal: true,
          content: "Please set the price again.",
          type: "check",
          onClick() {
            setMaxPrice("");
            setMinPrice("");
          },
        });
        return;
      }
    }
    const params = new URLSearchParams(location.search);
    if (minPrice.trim()) {
      params.set("startPrice", removeComma(minPrice.trim()));
    } else {
      params.delete("startPrice");
    }
    if (maxPrice.trim()) {
      params.set("endPrice", removeComma(maxPrice.trim()));
    } else {
      params.delete("endPrice");
    }
    navigate({
      search: params.toString(),
    });
  };

  return (
    <FilterSection>
      <SectionContent>
        {/* <Select>
                <option>ETH</option>
              </Select> */}
        <PriceInputContainer>
          <PriceInput
            type="text"
            placeholder="min"
            value={setComma(minPrice)}
            onChange={(e) => setMinPrice(removeComma(e.target.value))}
          />
          <span>to</span>
          <PriceInput
            type="text"
            placeholder="max"
            value={setComma(maxPrice)}
            onChange={(e) => setMaxPrice(removeComma(e.target.value))}
          />
        </PriceInputContainer>
      </SectionContent>
      <ApplyButton onClick={onApply}>Apply</ApplyButton>
    </FilterSection>
  );
};

const FilterSection = styled.div`
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const PriceInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* justify-content: space-between; */
`;

const PriceInput = styled.input`
  width: 100%;
  padding: 10px 5px;
  border: none;
  background-color: ${theme.colors.blueDeep};
  color: ${theme.colors.primary};
  border-radius: 5px;
  text-align: center;
  &::placeholder {
    color: ${theme.colors.gray3};
  }
`;

const ApplyButton = styled.button`
  width: 100%;
  padding: 10px;
  background-color: ${theme.colors.secondary};
  text-align: center;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
`;
