import styled from "styled-components";
import { LayoutProps } from "../../interface/components/interface";
import ViewHeader from "../Header/Header";
import ViewFooter from "../Footer/Footer"
import { SideBar } from "../SideBar/SideBar";
import ScrollToTopButton from "../Button/ScrollToTopButton";

export const Layout = (props: LayoutProps) => {

  return (
    <LayoutSidebar>
      <ScrollToTopButton />
      <SideBar {...props} />
      <LayoutHeaderWithBody>
        <ViewHeader/>
        <ChildBox>{props.children}</ChildBox>
        {/*<ViewFooter/>*/}
      </LayoutHeaderWithBody>
    </LayoutSidebar>
  );
};

const LayoutSidebar = styled.div`
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100vw;
  @media (max-width: 1000px) {
    /* position: fixed; */
    z-index: 99999;
  }
  @media (min-width: 1920px) {
      width: 1920px;
  }
`;

const LayoutHeaderWithBody = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: auto; */
`;

const ChildBox = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-bottom: 3vh;
  width: calc(100vw - 300px);

  @media (min-width: 1920px) {
    width: 1560px;
  }
  @media (max-width: 1550px) {
    width:  calc(100vw - 250px);
  }

  @media (max-width: 1250px) {
    width:  calc(100vw - 200px);
  }
  @media (max-width: 1000px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
