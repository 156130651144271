import React from "react";
import { useNavigate } from "react-router-dom";
import { ImageLayoutProps } from "../../../interface/components/interface";
import styled from "styled-components";
import theme from "../../../theme";
import "./ImageLayout.css";
import { LinearGradient } from "react-text-gradients";
import { assets } from "../../../theme/assets";
import userApi from "../../../api/userApi";

const ImageLayout = (props: ImageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <Wrap>
      {props.itemList.map((item, i) => {
        return (
          <Container
            key={i}
            background={item.background}
            onClick={() => navigate(item.path)}
          >
            <div style={{position: 'relative', width: 'min-content'}}>
            <Img
              alt=""
              src={item.image}
              onContextMenu={(e) => {
                e.preventDefault();
              }}
            />{
              item.decorate &&
              <>
                <img className="img-decorate-1" src={assets.images.decorate} alt="꾸미기 효과1"/>
                <img className="img-decorate-2" src={assets.images.decorate} alt="꾸미기 효과2"/>
              </>
            }
            </div>
            <div
              className="img-order-layout"
              style={{ order: `${i % 2 === 0 ? -1 : 0}` }}
            >
              <div className="flex_column">
                <span className="title">The Permier Market&nbsp;</span>
                <span className="title">
                  for&nbsp;<span className="nfts">NFTs</span>
                </span>
                <LinearGradient
                  className="linear_gradient"
                  gradient={["to left", "#9846FF, #6EFFB1, #3AC7FF"]}
                >
                  Market Blings&nbsp;
                </LinearGradient>
                <Description>
                Nifty Gateway is the premier marketplace for Nifties,<br />
                which are digital items you can truly own.<br />
                Digital Item have existed for a long time, but never like this.
                </Description>
                {/* <ViewButton>
                  View More
                </ViewButton> */}
              </div>
            </div>
          </Container>
        );
      })}
    </Wrap>
  );
};

export default ImageLayout;

const Wrap = styled.section`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div<{ background?: string }>`
  display: flex;
  background-color: ${(props) =>
    props.background ? props.background : theme.colors.primary};
  justify-content: space-between;
  padding: ${theme.commonMargin.padding};
  gap: ${theme.commonMargin.gap1};
  align-items: center;

  @media (max-width: 1000px) {
    padding: 3vh 10vw;
  }
  @media (max-width: 500px) {
    padding: 3vh 5vw;
  }
`;

const Img = styled.img`
    width: 35vw;
    min-width: 100px;
    max-width: 680px;
    /* height: fit-content; */
`;

const Title = styled.div`
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.describe.bold};
  /* font-size: ${theme.fontType.describe.fontSize}; */
  /* line-height: ${theme.fontType.describe.lineHeight}; */
  font-size: 1.1vw;
  line-height: 1.5vw;
  color: ${theme.colors.white};
  padding: 10% 0;

  @media (min-width: 1920px) {
    font-size: 30px;
    line-height: 34px;
  }
`;

const Description = styled.div`
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.describe.bold};
  /* font-size: ${theme.fontType.describe.fontSize}; */
  /* line-height: ${theme.fontType.describe.lineHeight}; */
  font-size: 1.1vw;
  line-height: 1.5vw;
  color: ${theme.colors.white};
  padding: 10% 0;

  @media (min-width: 1920px) {
    font-size: 30px;
    line-height: 34px;
  }
`;

const ViewButton = styled.button`
  background: ${theme.colors.white};
  border-radius: 68px;
  border: 1px solid #fff;
  font-family: ${theme.fontFamily.pretendard};
  font-size: 1.1vw;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding: 1.2vw 3vw;
`;