import React from 'react'
import styled from 'styled-components';
import theme from '../../../../theme';

interface DescriptionProps {
  description: string;
}

export const Description = (props: DescriptionProps) => {
  return (
    // <Text>By <span style={{color: theme.colors.amount}}>TeamImaginaryOnes</span></Text>
    <div style={{width: '100%', height: '100%'}}>
      <Text>{props.description}</Text>
    </div>
  )
}


const Text = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1vw;
  line-height: 1vw;
  white-space: pre-wrap;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
    @media (max-width: 680px) {
      font-size: 11px;
      line-height: 14px;
    }
`;
