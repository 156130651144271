import React, { Dispatch, SetStateAction } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import theme from "../../theme";
import { useList } from "../../contexts/ListContext";

interface SearchInputProps {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
}

const SearchBar = () => {
  const navigate = useNavigate();
  const {search, setSearch} = useList();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleClearInput = () => {
    setSearch("");
  };

  const submit = () => {
    const params = new URLSearchParams(location.search);
    if (search.trim()) {
      params.set("search", search.trim());
    } else {
      params.delete("search");
    }
    navigate({
      search: params.toString(),
    });
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter") {
      submit();
    }
  };

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder={"Search by name, contract, tokenID"}
        value={search}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      {search && (
        <IconButton onClick={handleClearInput} aria-label="clear">
          <FaTimes />
        </IconButton>
      )}
      <IconButton onClick={submit} aria-label="search">
        <FaSearch />
      </IconButton>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.side};
  border-radius: 8px;
  padding: 10px;
  width: 100%;
  max-width: 500px;
  @media (max-width: 1110px) {
    max-width: 30vw;
    padding: 0 10px;

  }
  @media (max-width: 680px) {
    max-width: 100%;
  }
`;

const SearchInput = styled.input`
  background: transparent;
  border: none;
  color: ${theme.colors.white};
  flex-grow: 1;
  font-size: 18px;
  outline: none;

  &::placeholder {
    color: #aaa;
  }
  @media (max-width: 1110px) {
    font-size: 15px;
  }
  @media (max-width: 680px) {
    max-width: 100%;
    padding: 0 10px;
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #aaa;

  &:hover {
    opacity: 0.7;
  }
`;

export default SearchBar;
