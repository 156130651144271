export const shortenAddress = (addr: string | null) =>{
    if(addr === null){
        return ''
    }
    return `${addr.slice(0, 4)}....${addr.slice(-4)}`;
}
  
export const shortenBalance = (balance: string | null) => {
    if(balance === null){
        return ''
    }
    const dotIndex = balance.indexOf('.');
    if (dotIndex === -1) return balance; // 소수점이 없으면 원래 문자열 반환
    const returnData = balance.slice(0, dotIndex + 5); // 소수점 4자리까지 잘라냄

    return returnData === '0.' ? '0' : returnData;
  }