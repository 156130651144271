import { useEffect } from "react";
import { RingLoader } from "react-spinners";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { loadingModalState } from "../../../modules/Modal";
import theme from "../../../theme";
import Overlay from "./Overlay";

export default function LoadingModal() {
  const loadingModal = useRecoilValue(loadingModalState);

  // 키보드 동작 막기
  useEffect(() => {
    const a = (e: Event) => {
      e.preventDefault();
    };
    document.body.style.overflow = "hidden"; // 모달 창 오픈 시 스크롤 불가
    window.addEventListener("keydown", a);
    return () => {
      document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
      window.removeEventListener("keydown", a);
    };
  }, []);

  return (
    <Overlay>
        <OnlyLoadingContainer>
          {/* {loadingModal.type === "default" && <Loading />}
          {loadingModal.type === "block" && <BlockChain />} */}
          <LoadingBox>
        <RingLoader color={theme.colors.gray3} size={80} />
        </LoadingBox>
        <TextBox>
          {loadingModal.subText && (
            <div className={`subText ${loadingModal.type === "default" ? "default" : ""}`}>
              {loadingModal.subText}
            </div>
          )}
          {!loadingModal.subText && (
            <p className={`rhombus ${loadingModal.type === "default" ? "default" : ""}`}>
              잠시만 기다려 주세요.
            </p>
          )}
        </TextBox>
          {/* {loadingModal.isButton && (
            <div className="buttonContainer">
              <BigButton text="취소" onClick={resetModal} white />
              <BigButton
                text={loadingModal.buttonText || "확인"}
                onClick={loadingModal.onClick || resetModal}
              />
            </div>
          )} */}
        </OnlyLoadingContainer>
      )
    </Overlay>
  );
}

const OnlyLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: -200px; */
  & .subText {
    color: white;
    text-align: center;
    white-space: pre-wrap;
    & > h1 {
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }
  .buttonContainer {
    margin: 0 auto;
    margin-top: 60px;
    display: flex;
    gap: 16px;
    > Button {
      width: 169px;
      height: 54px;
      color: white;
      border: 1px solid rgba(218, 221, 229, 0.2);
      box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
      border-radius: 61px;
      &:first-child {
        background: #35629d;
      }
      &:last-child {
        background: #5fa4f2;
      }
    }
  }
  & > .rhombus {
    text-align: center;
    color: #fff;
  }
  & > .default {
    margin-top: -60px;
  }
`;

const LoadingBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 130px;
`;

const TextBox = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;


const LoadingContainer = styled.div`
  width: 496px;
  height: 524px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 00;
  border-radius: 10px;
  .svg {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    width: 220px;
    height: 220px;
  }
  .subText {
    margin-top: 13px;
    height: 46px;
    display: block;
    text-align: center;
    white-space: pre-wrap;
    line-height: 23px;
    font-size: 16px;
  }
  .buttonContainer {
    margin-top: 114px;
    display: flex;
    gap: 16px;
    > Button {
      width: 169px;
      height: 54px;
      color: white;
      border: 1px solid rgba(218, 221, 229, 0.2);
      box-shadow: 0px 4px 4px -2px rgba(0, 0, 0, 0.1);
      border-radius: 61px;
      &:first-child {
        background: #35629d;
      }
      &:last-child {
        background: #5fa4f2;
      }
    }
  }
`;

