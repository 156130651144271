import { UseFormGetValues, UseFormSetValue, UseFormRegister } from "react-hook-form";
import { NFTItemProps } from "../../../interface/components/interface";
import { adminRegisterNFTInput, categoryList } from "../../../view/Admin/Register/RegisterSearchListPage";
import styled from "styled-components";
import theme from "../../../theme";

export const AdminRegisterModal = ({ fix, nft, setValue, getValues, register }: 
  { fix?: boolean, nft: NFTItemProps, setValue: UseFormSetValue<adminRegisterNFTInput>, getValues: UseFormGetValues<adminRegisterNFTInput>, register: UseFormRegister<adminRegisterNFTInput> }) => {
  return (
    <FormContainer>
      <Form>
        <FormRow>
          <Label>카테고리</Label>
          <Select {...register("category")}>
            {categoryList.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </Select>
        </FormRow>
        <FormRow>
          <Label>
            제목
            {!fix && <OriginalButton
              onClick={(e) => {
                e.preventDefault();
                setValue("title", nft.name);
              }}
            >
              원본
            </OriginalButton>}
          </Label>
          <Input
            type="text"
            {...register("title")}
            defaultValue={getValues('title')}
          />
        </FormRow>
        <FormRow>
          <Label>
            설명
            {!fix && <OriginalButton
              onClick={(e) => {
                e.preventDefault();
                setValue("description", nft.description);
              }}
            >
              원본
            </OriginalButton>}
          </Label>
          <Textarea
            rows={5}
            {...register("description")}
            defaultValue={getValues('description')}
          />
        </FormRow>
        {/* <FormRow>
          <Label>
            시리즈 여부
          <Checkbox
            type="checkbox"
            {...register("series")}
            defaultValue={getValues('description')}
          />
          </Label>
        </FormRow> */}
      </Form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 20px;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  flex: 1;
  text-align: start;
  width: 150px;
  display: flex;
  gap: 10px;
  align-items: center;
  color: #fff;
  /* justify-content: space-between; */
`;

const Input = styled.input`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Checkbox = styled.input`
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Textarea = styled.textarea`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: none;
`;

const Select = styled.select`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const OriginalButton = styled.button`
  padding: 5px;
  background-color: ${theme.colors.secondary};
  color: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    background-color: ${theme.colors.side};
  }
`;
