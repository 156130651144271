import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { NFTInfoItemProps } from "../../interface/components/interface";
import { NFTInfo } from "../NFTItem/Info/NFTInfo";
import { PriceChild } from "./Children.tsx/PriceChild";
import { StatusChild } from "./Children.tsx/StatusChild";

interface NFTFilterProps {
  buyNow: boolean;
  setBuyNow: Dispatch<SetStateAction<boolean>>;
  waitApprove: boolean;
  setWaitApprove: Dispatch<SetStateAction<boolean>>;
  minPrice: string;
  setMinPrice: Dispatch<SetStateAction<string>>;
  maxPrice: string;
  setMaxPrice: Dispatch<SetStateAction<string>>;
}

export const NFTFilter = () => {

  const items: NFTInfoItemProps[] = [
    {
      title: "Status",
      child: <StatusChild />,
      radius: "10px 10px 0 0",
      topRadius: "10px 10px 0 0",
      bottomRadius: "0",
      openState: true,
    },
    {
      title: "Price",
      child: <PriceChild />,
      // radius: "0",
      radius: "0 0 10px 10px",
      topRadius: "0",
      // bottomRadius: "0",
      bottomRadius: "0 0 10px 10px",
      openState: false,
    },
    // {
    //   title: "Currency",
    //   child: <CurrencyChild />,
    //   radius: "0",
    //   topRadius: "0",
    //   bottomRadius: "0",
    //   openState: false,
    // },
    // {
    //   title: "Traits",
    //   child: <TraitsChild />,
    //   radius: "0 0 10px 10px",
    //   topRadius: "0",
    //   bottomRadius: "0 0 10px 10px",
    //   openState: false,
    // },
  ];

  return (
    <FilterContainer>
      {/* <FilterHeader>Filter</FilterHeader> */}
      <NFTInfo items={items} />
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px;
  color: white;
`;
