import React from 'react'
import { useList } from '../../../contexts/ListContext';
import { useSetRecoilState } from 'recoil';
import { alertModalState } from '../../../modules/Modal';
import { removeComma } from '../../../utils/Comma';
import { useNavigate } from 'react-router-dom';
import theme from '../../../theme';
import styled from 'styled-components';

export const StatusChild = () => {
    const alertModal = useSetRecoilState(alertModalState);
    const navigate = useNavigate();
    const {
        buyNow,
        setBuyNow,
        waitApprove,
        setWaitApprove,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
      } = useList();

      const onChecked = (type: string) => {
        const params = new URLSearchParams(location.search);
    
        switch (type) {
          case "buyNow":
            if (!buyNow) {
              params.set("buyNow", "true");
            } else {
              params.delete("buyNow");
            }
            setBuyNow(!buyNow);
            break;
          case "waitApprove":
            if (!waitApprove) {
              params.set("waitApprove", "true");
            } else {
              params.delete("waitApprove");
            }
            setWaitApprove(!waitApprove);
            break;
          default:
            break;
        }
        navigate({
          search: params.toString(),
        });
      };
    
    
    
    const onApply = () => {
        if (minPrice.trim() !== "" && maxPrice.trim() !== "") {
          if (Number(minPrice) > Number(maxPrice)) {
            alertModal({
              isModal: true,
              content: "Please set the price again.",
              type: "check",
              onClick() {
                setMaxPrice("");
                setMinPrice("");
              },
            });
            return;
          }
        }
        const params = new URLSearchParams(location.search);
        if (minPrice.trim()) {
          params.set("startPrice", removeComma(minPrice.trim()));
        } else {
          params.delete("startPrice");
        }
        if (maxPrice.trim()) {
          params.set("endPrice", removeComma(maxPrice.trim()));
        } else {
          params.delete("endPrice");
        }
        navigate({
          search: params.toString(),
        });
      };

      
  
      return (
        <FilterSection>
          <SectionContent>
            <CheckboxLabel>
              <HiddenCheckbox
                type="checkbox"
                checked={buyNow}
                onChange={() => onChecked("buyNow")}
              />
              <StyledCheckbox $checked={buyNow} />
              Buy Now
            </CheckboxLabel>
  
            <CheckboxLabel>
              <HiddenCheckbox
                type="checkbox"
                checked={waitApprove}
                onChange={() => onChecked("waitApprove")}
              />
              <StyledCheckbox $checked={waitApprove} />
              Wait Approve
            </CheckboxLabel>
  
            {/* <CheckboxLabel>
              <Checkbox type="checkbox" />
              경매 중
            </CheckboxLabel> */}
            {/* <CheckboxLabel>
              <Checkbox type="checkbox" />
              제안 있음
            </CheckboxLabel> */}
            {/* <CheckboxLabel>
              <Checkbox type="checkbox" />
              카드로 구매
            </CheckboxLabel> */}
          </SectionContent>
        </FilterSection>
      );
}



const FilterSection = styled.div`
  margin-bottom: 10px;
`;

const SectionContent = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  margin-right: 10px;

  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<{ $checked: boolean }>`
  margin-right: 10px;

  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) =>
    props.$checked ? theme.colors.amount : theme.colors.blueDeep};
  border-radius: 3px;
  transition: all 150ms;

  ${CheckboxLabel}:hover & {
    /* background: ${(props) => (props.$checked ? "darkred" : "peachpuff")}; */
    background: ${theme.colors.gray4};
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${HiddenCheckbox}:checked + & {
    background: ${theme.colors.amount};
  }

  ${HiddenCheckbox}:checked + &::before {
    content: "✔";
    display: block;
    text-align: center;
    color: ${theme.colors.primary};
    line-height: 15px;
  }
`;