import { FaHeart, FaRegHeart } from "react-icons/fa";
import styled from "styled-components";
import { useImageView } from "../../../contexts/ImageView/ImageViewContext";
import { NFTItemProps } from "../../../interface/components/interface";

import React, { forwardRef } from "react";
import theme from "../../../theme";

import { useSetRecoilState } from "recoil";
import { useAccount } from "wagmi";
import userApi from "../../../api/userApi";
import { useWallet } from "../../../contexts/WalletContext";
import { alertModalState } from "../../../modules/Modal";
import RetryingImg from "../RetryingImg";
import "./NFTDetailImage.css";

export const NFTDetailImage = forwardRef<HTMLImageElement, NFTItemProps>(
  (
    nft,
    ref
  ) => {
// export const NFTDetailImage = (nft: NFTItemProps) => {
  const { open, setOpen, setSrc } = useImageView();
  const { openModal } = useWallet();
  const { address } = useAccount();

  const alertModal = useSetRecoilState(alertModalState);

  const onClick = () => {
    if (!open && ref && "current" in ref && ref.current && window.innerWidth > 530) {
      setSrc(ref.current.src);
      setOpen(true);
    }
  };

  const heartClick = async () => {
    if (address === undefined) {
      alertModal({
        isModal: true,
        type: "check",
        content: "Please connect wallet first.",
        onClick() {
          openModal();
        },
      });
      return;
    }

    await userApi.nft.setLike(nft.pk, address!);

    nft.set!((prevNft) => {
      if (!prevNft) return prevNft;
      return { ...prevNft, like: !prevNft.like };
    });
  };

  return (
    <Item>
      <div style={{ position: "relative" }}>
        <RetryingImg
          ref={ref}
          src={`${nft.image}`}
          onContextMenu={(e) => e.preventDefault()}
          onClick={onClick}
        />
        {nft.like ? (
          <FaHeart onClick={heartClick} className="heartIcon" color="#fff" />
        ) : (
          <FaRegHeart onClick={heartClick} className="heartIcon" color="#fff" />
        )}
      </div>
      <Descript>
        <Collection>
          {/* #{nft.tokenId} {nft.collection ? `- ${nft.collection}` : ""} */}
          {nft.tokenName} #{nft.tokenId}
        </Collection>
        <CTWrap>
          <TitleAndPrice type={"title"}>{nft.name}</TitleAndPrice>
        </CTWrap>
      </Descript>
    </Item>
  );
});

const Item = styled.div<{ radius?: string }>`
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.side};
  border-radius: 10px;
  box-shadow: ${theme.commonShadow.shadow1};
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
`;

const Descript = styled.div`
  flex: auto;
  padding: ${theme.commonMargin.NFTItempadding};
  gap: ${theme.commonMargin.heigthGap1};
  display: flex;
  flex-direction: column;
  @media (min-width: 1920px) {
    padding: 30px;
  }
  @media (max-width: 680px) {
    padding: 15px;
  }
`;

const CTWrap = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  /* overflow:  */
  /* @media (max-width: 480px) {

    } */
`;

const Collection = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.titleAndPrice.bold};
  /* font-size: ${theme.fontType.titleAndPrice.fontSize}; */
  /* line-height: ${theme.fontType.titleAndPrice.lineHeight}; */
  font-size: 1.3vw;
  line-height: 1.4vw;

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media (max-width: 680px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const TitleAndPrice = styled.div<{ type: string }>`
  color: ${(props) =>
    props.type === "title" ? theme.colors.white : theme.colors.amount};
  text-align: ${(props) => (props.type === "title" ? "start" : "end")};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.titleAndPrice.bold};
  /* font-size: ${theme.fontType.titleAndPrice.fontSize}; */
  /* line-height: ${theme.fontType.titleAndPrice.lineHeight}; */
  font-size: 1.1vw;
  line-height: 1.2vw;

  flex: ${(props) => (props.type === "title" ? 3 : 4)};
  overflow: ${(props) => (props.type !== "unit" ? "hidden" : "")};
  white-space: ${(props) => (props.type !== "unit" ? "nowrap" : "")};
  text-overflow: ${(props) => (props.type !== "unit" ? "ellipsis" : "")};

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }
`;
