import React from "react";
import styled from "styled-components";
import { useWallet } from "../../contexts/WalletContext";

// Props 인터페이스 정의
interface BigButtonSelectChainProps {
  extraStyle?: React.CSSProperties;
  backgroundColor: string;
  icon?: string;
  name: string;
  onClick: () => Promise<void>; // 비동기 함수로 정의
}

const BigButtonSelectChain: React.FC<BigButtonSelectChainProps> = (props) => {

  const onChangeChain = async () => {
    await props.onClick();
  };

  return (
    <SButton
      onClick={onChangeChain}
      style={props.extraStyle}
      backgroundColor={props.backgroundColor}
    >
      {props.icon ? <SLogo img={props.icon} name={props.name}/> : null}
    </SButton>
  );
};

// 스타일 정의
const SLogo = styled.div<{img: string, name: string}>`
  width: 100%;
  height: 100%;
  background: url(${(props) => props.img});
  background-size: ${props => props.name === 'ethereum' ? null : props.name === 'polygon' ? null : '80% auto'};
  background-position: center;
  background-repeat: no-repeat;
`;

const SButton = styled.button<{  backgroundColor: string }>`
  padding: 10px;
  border-radius: 50%;
  width: 5vw;
  height: 5vw;
  max-width: 100px;
  max-height: 100px;
  min-width: 50px;
  min-height: 50px;
  
  background-color: ${props => props.backgroundColor};
  position: relative;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  border: 1px solid ${props => props.backgroundColor};
  -webkit-appearance: none;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default BigButtonSelectChain;
