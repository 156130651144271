import { forwardRef, useCallback, useEffect } from "react";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { useWallet } from "../../contexts/WalletContext";
import { chainList } from "../../controller/chainId";
import { NFTItemProps } from "../../interface/components/interface";
import { alertModalState } from "../../modules/Modal";
import theme from "../../theme";
import "../NFTItem/NFTDetailImage/NFTDetailImage.css";
import RetryingImg from "./RetryingImg";
import { tokenIdTrim } from "../../utils/TrimString";
import { useList } from "../../contexts/ListContext";
import { useResizingHandler } from "../../hooks/useResizingHandler";
import { debounce } from "lodash";

export const NFTItem = forwardRef<HTMLElement, NFTItemProps>((nft, ref) => {

  const {
    selectedViewOption,
  } = useList();


  const navigate = useNavigate();
  const alertModal = useSetRecoilState(alertModalState);
  const { openModal } = useWallet();
  const { address } = useAccount();

  const itemClick = () => {
    if (nft.onClick) {
      nft.onClick();
    } else {
      navigate(`/assets/nft/${nft.tokenAddress}/${nft.tokenId}/${nft.pk}`);
    }
  };

  const heartClick = async () => {
    if (address === undefined) {
      alertModal({
        isModal: true,
        type: "check",
        content: "Please connect wallet first.",
        onClick() {
          openModal();
        },
      });
      return;
    }
  };


  return (
    <Itme onClick={itemClick} ref={ref as any} height={nft.height!}>
      <ImgHover height={nft.height!}>
        <RetryingImg
          src={nft.image}
          onContextMenu={(e) => e.preventDefault()}
          height={nft.height}
          animation
        />
        {!nft.disableHeart &&
          (nft.like ? (
            <FaHeart onClick={heartClick} className="heartIcon" color="#fff" />
          ) : (
            <FaRegHeart
              onClick={heartClick}
              className="heartIcon"
              color="#fff"
            />
          ))}
      </ImgHover>
      <Descript>
        <CollectionWrap>
        <Collection>
          {nft.tokenName}
        </Collection>
        <Collection style={{textAlign: 'end'}}>
          #{tokenIdTrim(nft.tokenId, 10)}
        </Collection>
        </CollectionWrap>
        <CTWrap>
          {nft.type === "purchased" ? (
            <>
            <TitleAndPrice type={"title"} $over={false}>{nft.name}</TitleAndPrice>
            <TitleAndPrice type={"prcie"} $over={false}>{`${nft.transaction_price} ${
              chainList[nft.transaction_chainId ?? nft.chainId].symbol
            }`}</TitleAndPrice>
            </>
          ) : nft.type === "sold" ? (
            <>
            <TitleAndPrice type={"title"} $over={false}>{nft.name}</TitleAndPrice>
            <TitleAndPrice type={"prcie"} $over={false}>{`${nft.transaction_price} ${
              chainList[nft.chainId].symbol
            }`}</TitleAndPrice>
            </>
          ) : (
            <>
            <TitleAndPrice type={"title"} $over={nft.price.toString() === "0"}>{nft.name}</TitleAndPrice>
            {nft.price.toString() !== "0" && (
              <TitleAndPrice type={"prcie"} $over={false}>{`${nft.price} ${
                chainList[nft.chainId].symbol
              }`}</TitleAndPrice>
            )}
            </>
          )}
        </CTWrap>
        {/* <CTWrap>
          <TitleAndPrice type={"title"}>{nft.title}</TitleAndPrice>
          <div style={{display: 'flex'}}>
          <TitleAndPrice type={"prcie"}>
            {nft.amount}&nbsp;
          </TitleAndPrice>
          <TitleAndPrice type={"unit"}>
            {nft.unit}
          </TitleAndPrice>
          </div>
        </CTWrap> */}
      </Descript>
    </Itme>
  );
});

const Itme = styled.div<{ height: string }>`
  /* max-width: 250px; */
  width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : `100%`)};
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.side};
  border-radius: 16px;
  box-shadow: ${theme.commonShadow.shadow1};
  cursor: pointer;
  @media (max-width: 1560px) {
    gap: 10px !important;
  }
  @media (max-width: 680px) {
    gap: 8px !important;
  }
  @media (max-width: 500px) {
    gap: 3px !important;
  }
`;

const ImgHover = styled.div<{ height?: string }>`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 16px;
  position: relative;
`;

const Img = styled.img<{ height?: string }>`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: transparent;
  border-radius: 16px 16px 0 0;
  transition: transform 1.5s;

  &:hover {
    transform: scale(1.5);
  }

  /* @media (min-width: 1920px) {
    height: 400px;
  }

  @media (max-width: 680px) {
    height: 300px;
  } */
`;

const Descript = styled.div`
  padding: 15px;
  gap: ${theme.commonMargin.heigthGap1};
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1560px) {
    padding: 15px;
    gap: 15px !important;
  }
  @media (max-width: 680px) {
    padding: 10px;
    gap: 10px;
  }
  @media (max-width: 500px) {
    padding: 8px;
    padding-bottom: 14px;
    gap: 3px;
  }
`;

const CTWrap = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
  /* margin: 0 auto; */
  /* overflow:  */
  /* @media (max-width: 480px) {

    } */
`;

const CollectionWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
`;
const Collection = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.titleAndPrice.bold};
  width: 100%;
  /* font-size: ${theme.fontType.titleAndPrice.fontSize}; */
  /* line-height: ${theme.fontType.titleAndPrice.lineHeight}; */
  font-size: 1.3vw;
  line-height: 1.4vw;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 680px) {
    font-size: 16px;
    line-height: 17px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 11px;
  }
`;

const TitleAndPrice = styled.div<{ type: string, $over: boolean}>`
  color: ${(props) =>
    props.type === "title" ? theme.colors.white : theme.colors.amount};
  text-align: ${(props) => (props.type === "title" ? "start" : "end")};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${theme.fontType.titleAndPrice.bold};
  font-size: 1.2vw;
  line-height: 1.3vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  width: ${props => props.$over ? '100%' : props.type === 'title' ? '65%' : '30%'};
  text-align: ${props => props.type === 'title' ? 'start' : 'end'};

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 20px;
    max-width: 300px;
  }
  @media (max-width: 1920px) {
    max-width: 15vw;
  }
  @media (max-width: 1600px) {
    max-width: 13vw;
  }
  @media (max-width: 1430px) {
    max-width: 11vw;
  }

  /* @media (max-width: 1280px) {
    max-width: 9vw;
  } */

  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 15px;
  }
  @media (max-width: 500px) {
    font-size: 8px;
    line-height: 9px;
  }
`;
