import { createWeb3Modal } from "@web3modal/wagmi/react";
import { createConfig, CreateConnectorFn, http } from "wagmi";
import {
  bsc,
  bscTestnet,
  Chain,
  klaytn,
  klaytnBaobab,
  mainnet,
  polygon,
  polygonAmoy,
  sepolia,
} from "wagmi/chains";
import { coinbaseWallet, injected, walletConnect } from 'wagmi/connectors';
// import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

const projectId = process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? '';

const metadata = {
  name: "Blings",
  description: "Blings web3",
  url: "https://blings.art",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains: [Chain, ...Chain[]] = process.env.REACT_APP_TYPE === "DEV" ? [
  sepolia,
  polygonAmoy,
  bscTestnet,
  klaytnBaobab,
] : [
  mainnet,
  polygon,
  bsc,
  klaytn,
]

// const config = defaultWagmiConfig({
//   chains,
//   projectId,
//   metadata,  
//   coinbasePreference: 'smartWalletOnly'
// });

const connectors: CreateConnectorFn[] = []
connectors.push(walletConnect({ projectId, metadata, showQrModal: false }));
connectors.push(injected({ shimDisconnect: true }));
connectors.push(coinbaseWallet({
  appName: metadata.name,
  appLogoUrl: metadata.icons[0],
  version: "4",
  preference: "smartWalletOnly"
}));
// connectors.push(KaikasConnector)


const transports: Record<number, any> = process.env.REACT_APP_TYPE === "DEV" ? {
  [sepolia.id]: http(),
  [polygonAmoy.id]: http(),
  [bscTestnet.id]: http(),
  [klaytnBaobab.id]: http(),
} : {
  [mainnet.id]: http(),
  [polygon.id]: http(),
  [bsc.id]: http(),
  [klaytn.id]: http(),
}
export const config = createConfig({
  chains: chains,
  connectors: connectors,
  transports: transports,
})

export const web3modal = createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableSwaps: false, // 스왑
  enableOnramp: true, //코인구매
  featuredWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // 메타마스크
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', // 월렛컨넥트
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // 코인베이스
    // 'c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2', // 카이카스
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // 트러스트
  ],
  includeWalletIds: [
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // 메타마스크
    'e7c4d26541a7fd84dbdfa9922d3ad21e936e13a7a0e44385d44f006139e44d3b', // 월렛컨넥트
    'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa', // 코인베이스
    // 'c9f7ff50ade14bbdeb13249b17b3ecf3e36ba12def7ac4fdbf00352c4e4894a2', // 카이카스
    '4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0', // 트러스트
  ],
  chainImages: {
    8_217: 'https://cryptologos.cc/logos/klaytn-klay-logo.png?v=032'
  },
});