import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AdminLoginPageProps } from "../../../interface/view/interface";
import { login } from "../../../redux/slice/UserSlice";
import theme from "../../../theme";
import adminApi from "../../../api/adminApi";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../../modules/Modal";
import { RootReducerState } from "../../../redux/RootReducer";
import { ADMIN_PATH } from "../../../routers/GlobalRoute";

const AdminLoginPage = (props: AdminLoginPageProps) => {
  const user = useSelector((state: RootReducerState) => state.user);

  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const alertModal = useSetRecoilState(alertModalState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if(user.email !== ''){
      return navigate(`${ADMIN_PATH}/setting`)
    }
  },[])

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();
    
    const res = await adminApi.login(email, password);
    if(res.status !== 200){
      alertModal({
        type: 'error',
        isModal: true,
        title: "로그인 실패",
        buttonText: "확인",
        content: res.message,
      });
      return
    }

    dispatch(login({
      email: email,
      isAdmin: true,
      token: res.data
    }))
    return navigate(`${ADMIN_PATH}/setting`)
  };

  return (
    <Container>
      <LoginBox>
        <Title>Login</Title>
        <Form onSubmit={handleLogin}>
          <Input
            type="text"
            placeholder="ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <Button type="submit">Login</Button>
        </Form>
      </LoginBox>
    </Container>
  );
};

export default AdminLoginPage;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: ${theme.colors.primary};
`;

const LoginBox = styled.div`
  background: ${theme.colors.blueGray1};
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
`;

const Title = styled.h1`
  margin-bottom: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 12px;
  background-color: ${theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: ${theme.colors.side};
  }
`;
