import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider, { Settings } from 'react-slick';
import styled from 'styled-components';
import theme from '../../../theme';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { debounce, throttle } from 'lodash';
import { useResizingHandler } from '../../../hooks/useResizingHandler';

interface TopArrowSliderProps {
  text: string;
  children: React.ReactNode;
  slidesToShow: number;
  slidesPerRow?: number;
  dot?: boolean;
  loop?: boolean;
  speed?: number;
  autoplay?: boolean | number; // 자동재생 (속도 설정시 number 타입으로)
  padding?: string;
  size?: number;
  maxHeight: number;
  setMaxHeight: Dispatch<SetStateAction<number>>;
}

const TopArrowSlider = ({
  text,
  children,
  slidesToShow,  // 1슬라이드당 갯수
  slidesPerRow = 1, // 몇개의 행
  dot = false,
  loop = false,
  speed = 500,
  autoplay = false,
  padding,
  size,
  maxHeight,
  setMaxHeight,
}: TopArrowSliderProps) => {
  const internalSliderRef = useRef<Slider>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const slideRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [currentSettings, setCurrentSettings] = useState({ slidesToShow: slidesToShow, slidesPerRow: slidesPerRow });

  const calculateMaxHeight = useCallback(() => {
    const widths = slideRefs.current.map(slide => slide?.offsetWidth || 0);
    const heights = widths.map(width => (width / 9) * 16);
    const newMaxHeight = Math.max(...heights);
    setMaxHeight(newMaxHeight);
  }, [setMaxHeight]);
  
  useEffect(() => {
    calculateMaxHeight();
  }, [children, calculateMaxHeight]);

  const throttleClick = useCallback(throttle((action: () => void) => {
    action();
  }, 300), []);

  const debouncedCalculateMaxHeight = useCallback(debounce(calculateMaxHeight, 500), [calculateMaxHeight]);
  useResizingHandler(debouncedCalculateMaxHeight);

  const Previous = useCallback((props: any) => {
    const [blockButton, setBlockButton] = useState(false);
    const disabled = size === undefined ? false : currentPage === 0;

    const handleClick = () => {
      if (blockButton) return;
      internalSliderRef.current?.slickPrev();
      setCurrentPage((e) => e - 1);
      setBlockButton(true);
      setTimeout(() => setBlockButton(false), 300); // 비활성화 상태를 일정 시간 후에 해제
    };

    return (
      <ArrowButton className='top-arrow-slick-prev' onClick={() => throttleClick(handleClick)} disabled={disabled}>
        <IoIosArrowBack size="28" className='react-icons' />
      </ArrowButton>
    );
  }, [currentPage, throttleClick, size]);

  const Next = useCallback((props: any) => {
    const [blockButton, setBlockButton] = useState(false);
    
    let disabled = false;
    if (size) {
      const onePage = currentSettings.slidesToShow * currentSettings.slidesPerRow;

      // console.log('size:', size)
      // console.log('보여준 사이즈',(onePage + currentPage * currentSettings.slidesPerRow!))
      // console.log(size - (onePage + currentPage * currentSettings.slidesPerRow!))

      disabled = size - (onePage + currentPage * currentSettings.slidesPerRow) < 1;
    }
  
    const handleClick = () => {
      if (blockButton) return;
      internalSliderRef.current?.slickNext();
      setCurrentPage((e) => e + 1);
      setBlockButton(true);
      setTimeout(() => setBlockButton(false), 300); // 비활성화 상태를 일정 시간 후에 해제
    };

    return (
      <ArrowButton className='top-arrow-slick-next' onClick={() => throttleClick(handleClick)} disabled={disabled}>
        <IoIosArrowForward size="28" className='react-icons' />
      </ArrowButton>
    );
  }, [currentPage, throttleClick, size, currentSettings]);

  useEffect(() => {
    if (internalSliderRef.current) {
      setTimeout(() => {
        internalSliderRef.current?.slickGoTo(0);
      }, 1000);
    }
  }, [internalSliderRef]);

  const settings = useMemo<Settings>(
    () => ({
      dots: dot,
      infinite: loop,
      speed: speed,
      slidesToShow: slidesToShow,
      slidesPerRow: slidesPerRow,
      autoplay: Boolean(autoplay),
      autoplaySpeed: typeof autoplay === 'boolean' ? 3000 : autoplay,
      arrows: false,
      draggable: false,
      prevArrow: <Previous />,
      nextArrow: <Next />,
      initialSlide: 1,
      beforeChange: (current, next) => setCurrentPage(next),
      responsive: [{
        breakpoint: 680,
        settings: {
          slidesToShow: 3,
          slidesPerRow: 2,
        }
      }]
    }),
    [dot, loop, speed, slidesToShow, slidesPerRow, autoplay, Previous, Next]
  );

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    let newSettings = { slidesToShow: slidesToShow, slidesPerRow: slidesPerRow };

    if (width < 680) {
      newSettings = { slidesToShow: 3, slidesPerRow: 2 };
    }
    
    setCurrentSettings(newSettings);
  }, [slidesToShow, slidesPerRow]);

  useResizingHandler(handleResize);

  return (
    <SlideWrapper padding={padding}>
      <TitleWithArrowWrap>
        <Title>{text}</Title>
        <ArrowWrap>
          <Previous />
          <Next />
        </ArrowWrap>
      </TitleWithArrowWrap>
      <SliderWrap>
        <CustomSlider {...settings} ref={internalSliderRef}>
          {React.Children.map(children, (child, index) => (
            <SlideItem
              key={index}
              ref={(el) => {
                if (el) slideRefs.current[index] = el;
              }}
              style={{ height: `${maxHeight}px` }}
            >
              {child}
            </SlideItem>
          ))}
        </CustomSlider>
      </SliderWrap>
    </SlideWrapper>
  );
};

const SlideWrapper = styled.section<{ padding?: string; }>`
  padding: ${(props) =>
    props.padding
      ? props.padding
      : theme.commonMargin.slideWrapper};
  display: flex;
  flex-direction: column;
  gap: ${theme.commonMargin.gap2};
  width: calc(100vw - 300px);

  @media (min-width: 1920px) {
    width: calc(1560px);
  }

  @media (max-width: 1000px) {
    width: calc(100vw);
  }
`;

const TitleWithArrowWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  white-space: nowrap;
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.4vw;
  @media (min-width: 1920px) {
    font-size: 28px;
    line-height: 33px;  
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;  
  }
`;

const ArrowWrap = styled.div`
  display: flex;
  padding-right: 4vw;
  gap: ${theme.commonMargin.gap1};
  justify-content: end;
`;

const ArrowButton = styled.button<{ disabled?: boolean }>`
  padding: 3px;
  border-radius: 12px;
  display: block;
  background-image: ${(props) =>
    !props.disabled
      ? `linear-gradient(
        90deg, #9846FF 0%, #3AC7FF 100%
        );`
      : `none`};
  background-color: ${(props) =>
    !props.disabled
      ? `transparent`
      : theme.colors.inactive};

  color: ${(props) =>
    !props.disabled
      ? theme.colors.white
      : theme.colors.menu};
  text-align: center;
`;

const CustomSlider = styled(Slider)`
   display: flex !important;
   flex-direction: row !important;
   align-items: center !important;
   .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    
  }
`;
const SliderWrap = styled.div`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  .slick-slider {
  width: calc(100%);  
  }

  .slick-list {	margin-right: -15px}
  .slick-slide {	padding-right: 15px}

  .slick-list {
    width: 100%;
  }
  .slick-track {
    max-width: 100%;
    min-width: 0;
    width: 100% !important;
    display: flex !important;
  }
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    div{
      width: 100% !important;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
`;
const SlideItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* 슬라이드 높이를 부모의 높이와 동일하게 설정 */
`;


export default TopArrowSlider;