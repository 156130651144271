import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import theme from "../../../theme";
import { debounce } from "lodash";
import { alertModalState } from "../../../modules/Modal";
import Overlay from "./Overlay";
import BigButton from "../../Button/BigButton";
import React from 'react';

// 전역으로 사용되는 Alert Modal
// Alert Modal Atom에 값이 들어가면 보여주고 아니면 null 반환
// 버튼은 기본적으로 Atom의 값을 비우는 onClick 함수를 가지고 있음
export default function AlertModal() {
  const alertModal = useRecoilValue(alertModalState);
  const resetAlertModal = useResetRecoilState(alertModalState);

  // 키보드 동작 막기
  useEffect(() => {
    const a = (e: Event) => {
      e.preventDefault();
    };
    document.body.style.overflow = "hidden"; // 모달 창 오픈 시 스크롤 불가
    window.addEventListener("keydown", a);
    return () => {
      window.removeEventListener("keydown", a);
      document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
    };
  }, []);

  const onCheckFn = () => {
    if (alertModal.onClick) alertModal.onClick();
    resetAlertModal();
  };

  return (
    <>
      {alertModal.isModal ? (
        <Overlay>
          <AlertModalContainer>
            <div className="title">
              {/* {(alertModal.type === "error" || alertModal.type === "nextFlow") && (
                <img src={AlertIcon} alt="" />
              )} */}
              <h1>{alertModal.title || "Announcement"}</h1>
            </div>
            {
              typeof alertModal.content === 'string'
              ? <p>{alertModal.content}</p>
              : <>
              <p>{alertModal.subTitle}</p>
              <div className="content">{alertModal.content}</div>
              </>
            }
            

            {alertModal.type === "check" && alertModal.onClick && (
              <BigButton
                onClick={debounce(() => {
                  onCheckFn();
                }, 500)}
                text={alertModal.buttonText ?? "Ok"}
              />
            )}

            {alertModal.onClick && !alertModal.type && (
              <div className="buttonContainer">
                <BigButton text="Cancel" onClick={resetAlertModal} white />
                <BigButton
                  onClick={debounce(() => {
                    if (alertModal.onClick) alertModal.onClick();
                  }, 500)}
                  text={alertModal.buttonText ?? "Ok"}
                />
              </div>
            )}

            {(alertModal.type === "error" || alertModal.type === "nextFlow") && (
              <>
                {alertModal.type === "error" && (
                  <>
                    {alertModal.onClick && (
                      <div className="buttonContainer">
                        <BigButton text="Cancel" onClick={resetAlertModal} white />
                        <BigButton
                          onClick={debounce(() => {
                            if (alertModal.onClick) alertModal.onClick();
                          }, 500)}
                          text={alertModal.buttonText ?? "Ok"}
                        />
                      </div>
                    )}
                  </>
                )}
                {alertModal.type === "nextFlow" && (
                  <>
                    <BigButton
                      onClick={debounce(() => {
                        onCheckFn();
                      }, 500)}
                      // bgColor={theme.systemColors.closeTimer}
                      text={alertModal.buttonText ? alertModal.buttonText : "다음"}
                    />
                  </>
                )}
              </>
            )}

            {!alertModal.onClick && (
              <BigButton
                onClick={debounce(() => {
                  resetAlertModal();
                }, 500)}
                // bgColor={alertModal.type === "error" ? theme.systemColors.closeTimer : ""}
                text={alertModal.buttonText ?? "Ok"}
              />
            )}
          </AlertModalContainer>
        </Overlay>
      ) : null}
    </>
  );
}
const AlertModalContainer = styled.div`
  width: 610px;
  height: 361px;
  background-color: #1B1C29;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  .title {
    display: flex;
    justify-content: center;
    color: #fff;
    & > img {
      margin-right: 10px;
    }
  }
  h1 {
    font-size: 20px;
    font-weight: 600;
    /* color: ${theme.colors.body2}; */
    color: #fff;
  }
  p {
    white-space: pre-wrap;
    text-align: center;
    font-size: 16px;
    /* color: ${theme.colors.body}; */
    color: #fff;
  }
  .buttonContainer {
    display: flex;
    gap: 24px;
  }

  @media (max-width: 610px) {
    width: 80vw;
    height: auto;
    padding: 20px 0px;
    display: flex;
    gap: 10px;
  }
`;
