import { Routes, Route } from "react-router-dom";
import UserRoute from "./Users/UserRoute";
import AdminRoute from "./Admin/AdminRoute";
import NotFoundPage from "../view/NotFound/NotFoundPage";
import React from 'react';
import { HomePage } from "../view/Users/Home/HomePage";
import { useDispatch } from "react-redux";
import { setFee } from "../redux/slice/SettingSlice";
import userApi from "../api/userApi";
import useInterval from "../hooks/useInterval";

export const ADMIN_PATH = '/blings-adm'

const GlobalRoute = () => {

  const dispatch = useDispatch();

  React.useEffect(() => {
    getFeeAPI();
  },[]);

  const getFeeAPI = async() => {
    const res = await userApi.setting.getFee();
    const fee = res.fee * 100;
    dispatch(setFee(fee.toString()))
  }

  useInterval(getFeeAPI, 5 * 60 * 1000)

  return (
    <Routes>
      <Route path="/" element={<HomePage topMenu="Category"/>} />
      <Route
        path="/*"
        element={
          <>
            <UserRoute />
          </>
        }
      />
      <Route
        path = {`${ADMIN_PATH}/*`}
        element={
          // 어드민 체크
          <>
            <AdminRoute />
          </>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default GlobalRoute;
