import { useWriteContract } from 'wagmi';
import { ERC721_abi } from '../../json/ERC721';
import { ERC1155_abi } from '../../json/ERC1155';

interface ApprovePrams {
  standard: string;
  abi?: any,
  contractAddress: string;
  tokenId: number;
}

export const useApprove = () => {
  const {
    data: approveHash,
    error: approveError,
    isPending: approvePending,
    writeContract
  } = useWriteContract()

  const approve = async ({ standard, abi, contractAddress, tokenId }: ApprovePrams) => {

    const adminAddress = process.env.REACT_APP_ADMIN_WALLET_ADDRESS as `0x{string}`;
    if (!adminAddress) return;

    const type = standard.split('-')[1];

    let _function;
    let _arg: readonly any[] = [];
    // let _abi = abi;
    let _abi;

    switch (type) {
      case '721':
        if (_abi == undefined) _abi = ERC721_abi;
        _function = "approve";
        _arg = [adminAddress, BigInt(tokenId)] as const;
        break;

      case '1155':
        if (_abi == undefined) _abi = ERC1155_abi;
        _function = "setApprovalForAll";
        _arg = [adminAddress, true] as const;
        break;

      case '17':
        if (_abi == undefined) _abi = ERC721_abi;
        _function = "approve";
        _arg = [adminAddress, BigInt(tokenId)] as const;
        break;

      case '37':
        if (_abi == undefined) _abi = ERC1155_abi;
        _function = "setApprovalForAll";
        _arg = [adminAddress, true] as const;
        break;
    }

    if(_abi === undefined) return;
    if(_function === undefined) return;
    
    writeContract({
      address: contractAddress as `0x${string}`,
      abi: _abi,
      functionName: _function,
      args: _arg,
      // abi: ERC721_abi,
      // functionName: 'approve',
      // args: [adminAddress, BigInt(tokenId)],
    })
  }

  return {
    approve,
    approveHash, approveError, approvePending
  };
};

export default useApprove;
