import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import adminApi from "../../../api/adminApi";
import userApi from "../../../api/userApi";
import ListStyleButton from "../../../components/Button/ListStyleButton";
import { MobileFilter } from "../../../components/Button/Mobile/MobileFilter";
import { NFTFilter } from "../../../components/Filter/NFTFilter";
import SearchInput from "../../../components/Input/SearchInput";
import { SelectBox } from "../../../components/Input/SelectBox";
import { Layout } from "../../../components/Layout/Layout";
import { OrderFilterModal } from "../../../components/Modal/Mobile/OrderFilterModal";
import { FilterModal } from "../../../components/Modal/Mobile/OrderModal";
import { AdminRegisterModal } from "../../../components/Modal/Register/AdminRegisterModal";
import { NFTItem } from "../../../components/NFTItem/NFTItem";
import { NFTTableBase } from "../../../components/NFTItem/Table/NFT/NFTTable";
import { NFTTable } from "../../../components/Table/NFTTable";
import { selectBoxItem, useList } from "../../../contexts/ListContext";
import { Iquery, NFTItemProps } from "../../../interface/components/interface";
import { ManagementPageProps } from "../../../interface/view/interface";
import { alertModalState, contentModalState, loadingModalState } from "../../../modules/Modal";
import { RootReducerState } from "../../../redux/RootReducer";
import { addComma } from "../../../utils/Comma";
import { adminRegisterNFTInput } from "../Register/RegisterSearchListPage";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { useResizingHandler } from "../../../hooks/useResizingHandler";

export const ManagementPage: React.FC<ManagementPageProps> = (props) => {
  const location = useLocation();
  const {COLUMNS} = NFTTableBase();
  const user = useSelector((state: RootReducerState) => state.user);

  const alertModal = useSetRecoilState(alertModalState);

  const contentModal = useSetRecoilState(contentModalState);
  const resetContentModal = useResetRecoilState(contentModalState);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);
  const { address } = useAccount();
  const {
    setSelectedViewOption,
    selectedViewOption,
    setSearch,
    setSelectedOption,
    setBuyNow,
    setWaitApprove,
    setMinPrice,
    setMaxPrice,
  } = useList();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [size, setSize] = useState<number>(12);
  const [query, setQuery] = useState<Iquery>({});
  const [data, setData] = useState<NFTItemProps[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);

  const fetchMoreListItems = useCallback(async () => {
    await getList(query, currentPage);
  }, [query, currentPage]);

  const {lastItemRef, isFetching, setIsFetching, Loading} = useInfiniteScroll(fetchMoreListItems);

  const getList = async (query?: Iquery, page = 1) => {
    if (isFetching) return;
    if (!hasMore) return;
    try {
      const res = await userApi.nft.getList({
        page,
        size,
        address,
        query,
      });
      if (res.status !== 200) {
        alertModal({
          isModal: true,
          content: "An error occurred while fetching data.",
        });
        return;
      }

      setTotal(res.data.total);
      setData((prevData) => [...prevData, ...res.data.list]);
      if (res.data.list.length < size) {
        setHasMore(false);
      }
      setCurrentPage((prevPage) => prevPage + 1); // 페이지 번호 증가
      setIsFetching(false);
    } catch (error) {
      alertModal({
        isModal: true,
        content: "An error occurred while fetching data.",
      });
      setIsFetching(false);
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    const orderQuery = params.get("order");
    const buyNowQuery = params.get("buyNow");
    const waitApproveQuery = params.get("waitApprove");
    const startPriceQuery = params.get("startPrice");
    const endPriceQuery = params.get("endPrice");

    const queryData: Iquery = {};
    if (searchQuery) {
      setSearch(searchQuery);
      queryData.search = searchQuery;
    }
    if (orderQuery) {
      const order = selectBoxItem.find((item) => item.value === orderQuery);
      if (order) {
        setSelectedOption(order);
        queryData.order = orderQuery;
      } else {
        setSelectedOption(selectBoxItem[0]);
        queryData.order = selectBoxItem[0].value;
      }
    }
    if (buyNowQuery) {
      setBuyNow(true);
      queryData.buyNow = "true";
    }
    if (waitApproveQuery) {
      setWaitApprove(true);
      queryData.waitApprove = "true";
    }
    if (startPriceQuery) {
      setMinPrice(addComma(startPriceQuery));
      queryData.startPrice = startPriceQuery;
    }

    if (endPriceQuery) {
      setMaxPrice(addComma(endPriceQuery));
      queryData.endPrice = endPriceQuery;
    }

    setQuery(queryData);
    setCurrentPage(1);
    setData([]);
    setHasMore(true);
    setIsFetching(false);
  }, [location.search]);

  React.useEffect(() => {
    setIsFetching(true);
    getList(query, currentPage);
  },[query])

  const filterRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setSticky(false);
          } else {
            setSticky(true);
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  const { setValue, getValues, reset, register } = useForm<adminRegisterNFTInput>();

  const onClick = (nft: NFTItemProps) => {
    setValue("category", nft.category);
    setValue("title", nft.name);
    setValue("description", nft.description);
    setValue("series", nft.series !== '0');

    contentModal({
      content: <AdminRegisterModal fix={true} nft={nft} setValue={setValue} getValues={getValues} register={register}/>,
      isModal: true,
      subText: "",
      title: "NFT 수정",
      buttonText: "수정",
      cancelText: "취소",
      onClose () {
        reset();
      },
      onClick: async function () {
        loadingModal({
          isModal: true,
          isButton: true,
          type: "default",
        });

        const res = await adminApi.updateNFT(
          user.token,
          nft.pk,
          getValues("category"),
          getValues("description"),
          getValues("title"),
          getValues("series") ? 1 : 0
        );

        resetLoadingModal();

        if (res.status !== 200) {
          alertModal({
            title: "안내",
            buttonText: "확인",
            isModal: true,
            content: "서버 오류.",
            onClick() {
              reset();
              resetContentModal();
            },
            type: "check",
          });
          return;
        }

        alertModal({
          title: "안내",
          buttonText: "확인",
          isModal: true,
          content: "수정이 완료되었습니다.",
          onClick() {
            resetContentModal();
            setData(list => list.map(data => 
              data.pk === nft.pk ? {
                ...data,
                category: getValues('category'),
                name: getValues('title'),
                series: getValues('series') ? '1' : '0',
                description: getValues('description')
              } : data
            ));
            reset();
          },
          type: "check",
        });
      },
    });
  };


  const resizeHandler = useCallback(() => {
    const items = document.querySelectorAll('.nft-item-wrapper');
    items.forEach((item) => {
      const element = item as HTMLElement;
      const width = element.clientWidth;
      const height = `${(width * 4) / 3}px`;
      element.style.height = height;
    });
  }, []);
  
  React.useEffect(() => {
    resizeHandler();
  },[])
  
  useResizingHandler(debounce(resizeHandler, 500));

  return (
    <AdminLayout {...props}> 
          <Container>
            <SpaceRow ref={searchBarRef}>
              <SearchInput />
              <Row>
                <SelectBox />
                <ListStyleButton />
                <MobileFilter />
                <OrderFilterModal />
                <FilterModal />
              </Row>
            </SpaceRow>
          </Container>
      <RowNFT>
        <FilterReplaceContainer sticky={sticky} />
        <FilterContainer ref={filterRef} sticky={sticky}>
          <NFTFilter />
        </FilterContainer>
        <Flexible>
          {/* <NFTWrap
            $grid={selectedViewOption.grid}
            row={selectedViewOption.row}
          >
            {data.map((nft, index) => (
              <NFTItem
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                {...nft}
                width={"100%"}
                height={"auto"}
                disableHeart={true}
                ref={index === data.length - 1 ? lastItemRef : null}
                onClick={() => onClick(nft)}
              />
            ))}
          </NFTWrap> */}

<NFTWrap
            $grid={selectedViewOption.grid}
            row={selectedViewOption.row}
          >
            {data.map((nft, index) => (
              <NFTItemWrapper
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                ref={index === data.length - 1 ? lastItemRef : null}
                className="nft-item-wrapper"
              >
                <NFTItem
                  {...nft}
                  width={"100%"}
                  disableHeart={true}
                  onClick={() => onClick(nft)}
                />
              </NFTItemWrapper>
            ))}
          </NFTWrap>
          <NFTTableWrap
            $grid={selectedViewOption.grid}
          >
            <NFTTable
              COLUMNS={COLUMNS}
              DATA={data}
              total={total}
            />
          </NFTTableWrap>
          {hasMore && <Loading />}
        </Flexible>
      </RowNFT>
    </AdminLayout>
  );
};


const ContainerWrap = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 180px;
  @media (max-width: 1000px) {
    margin-bottom: 120px;
  }
  @media (max-width: 700px) {
    margin-bottom: 15vw;
  }
`;

const Img = styled.img`
  width: 100%;
  height: fit-content;
`;

const Wrap = styled.div`
  position: absolute;
  top: 87%;
  width: 100%;
  height: 100%;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1vw;
`;

const SpaceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  @media (min-width: 1920px) {
    gap: 10px;
  }
`;
const RowNFT = styled.div`
  display: flex;
  gap: 1vw;
  padding: 1vw;
  z-index: 8888;
  @media (min-width: 1920px) {
    gap: 10px;
  }
  @media (max-width: 680px) {
    padding: 30px;
  }
`;

const Flexible = styled.div`
  flex: 1;
`;

const FilterReplaceContainer = styled.div<{ sticky: boolean }>`
  display: ${({ sticky }) => (sticky ? "block" : "none")};
  width: 250px;
  @media (max-width: 680px) {
    display: none;
  }
`;

const FilterContainer = styled.div<{ sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? "fixed" : "relative")};
  top: ${({ sticky }) => (sticky ? "10px" : "auto")};
  height: fit-content;
  width: 250px;
  @media (max-width: 1250px) {
    width: 20vw;
    min-width: 170px;
  }
  @media (max-width: 680px) {
    display: none;
  }
`;

const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "grid" : "none")};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;


const NFTTableWrap = styled.section<{ $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "none" : "block")};
  gap: 1vw;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;
