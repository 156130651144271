import React, { useState, useRef, useCallback, ReactNode } from 'react';
import { PropagateLoader } from 'react-spinners';
import theme from '../theme';

const useInfiniteScroll = (callback: () => void) => {
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const tableObserver = useRef<IntersectionObserver | null>(null);

  const lastItemRef = useCallback((node: HTMLElement | null) => {
    if (observer.current && !isFetching) {
      observer.current.disconnect(); // 이전 옵저버를 해제
    }

    observer.current = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting && !isFetching) {
        setIsFetching(true);
        callback();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [callback, isFetching]);

  const tableLastItemRef = useCallback((node: HTMLElement | null) => {
    if (tableObserver.current && !isFetching) {
      tableObserver.current.disconnect(); // 이전 옵저버를 해제
    }

    tableObserver.current = new IntersectionObserver(entries => {
      const entry = entries[0];
      if (entry.isIntersecting && !isFetching) {
        setIsFetching(true);
        callback();
      }
    });

    if (node) {
      tableObserver.current.observe(node);
    }
  }, [callback, isFetching]);

  const Loading = () => {
    if(isFetching){
      return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <PropagateLoader style={{overflow: 'visible'}} color={theme.colors.blueGrayDeeper2} />
        </div>
      );
    }
    return null;
  };

  return { lastItemRef, tableLastItemRef, isFetching, setIsFetching, Loading };
};

export default useInfiniteScroll;

