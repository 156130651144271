import React from "react";
import ImageLayout from "../../../components/Layout/ImageLayout/ImageLayout";
import { Layout } from "../../../components/Layout/Layout";
import TopArrowSlider from "../../../components/Slider/TopArrowSlider/TopArrowSlider";
import { CategoryProps } from "../../../interface/view/interface";

import { useSetRecoilState } from "recoil";
import userApi from "../../../api/userApi";
import { NFTItem } from "../../../components/NFTItem/NFTItem";
import { useWallet } from "../../../contexts/WalletContext";
import { homeImageData } from "../../../dump";
import { NFTItemProps } from "../../../interface/components/interface";
import { alertModalState } from "../../../modules/Modal";

import Footer from "../../../components/Footer/Footer";


import { useResizingHandler } from "../../../hooks/useResizingHandler";
import "../../../components/NFTItem/NFTDetailImage/NFTDetailImage.css";
import { useAccount } from "wagmi";
import styled from "styled-components";
import theme from "../../../theme";

export const HomePage = (props: CategoryProps) => {
  const [slidesToShow, setSlidesToShow] = React.useState<number>(4);
  const [slidesPerRow, setSlidesPerRow] = React.useState<number>(1);

  const alertModal = useSetRecoilState(alertModalState);

  const [data, setData] = React.useState<NFTItemProps[]>([]);
  const [total, setTotal] = React.useState<number>(12);
  const [maxHeight, setMaxHeight] = React.useState<number>(0);

  const { address } = useAccount();

  React.useEffect(() => {
    getList();
  }, [address]);

  const getList = async () => {
    const res = await userApi.nft.getList({
      page: 1,
      size: 12,
      address: address,
    });
    if (res.status !== 200) {
      alertModal({
        isModal: true,
        content: "Please retry...",
      });
      return;
    }
    setData(res.data.list);
    setTotal(res.data.list.length);
  };

  return (
    <Layout {...props}>
      <ImageLayout itemList={homeImageData} />
      {data.length === 0 ? (
        <></>
      ) : data.length < 5 ? (
        <Wrapper>
          <TitleWithArrowWrap>
            <Title>New NFT</Title>
          </TitleWithArrowWrap>

          <NFTWrap $grid={true} row={4}>
            {data.map((nft, index) => (
              <NFTItemWrapper
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                className="nft-item-wrapper"
              >
                <NFTItem {...nft} width={"100%"} />
              </NFTItemWrapper>
            ))}
          </NFTWrap>
        </Wrapper>
      ) : (
        <TopArrowSlider
          text={"New NFT"}
          slidesToShow={slidesToShow}
          slidesPerRow={slidesPerRow}
          size={total}
          maxHeight={maxHeight}
          setMaxHeight={setMaxHeight}
        >
          {data.map((nft) => (
            <NFTItem
              key={`${nft.tokenAddress}#${nft.tokenId}`}
              {...nft}
              width={"100%"}
              height={maxHeight.toString()}
              disableHeart={true}
            />
          ))}
        </TopArrowSlider>
      )}
      <Footer/>
    </Layout>
  );
};

const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "grid" : "none")};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const TitleWithArrowWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  white-space: nowrap;
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.4vw;
  @media (min-width: 1920px) {
    font-size: 28px;
    line-height: 33px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Wrapper = styled.section<{ padding?: string }>`
  padding: ${(props) =>
    props.padding ? props.padding : theme.commonMargin.slideWrapper};
  display: flex;
  flex-direction: column;
  gap: ${theme.commonMargin.gap2};
  width: calc(100vw - 300px);

  @media (min-width: 1920px) {
    width: calc(1560px);
  }

  @media (max-width: 1000px) {
    width: calc(100vw);
  }
`;
