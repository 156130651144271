import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import adminApi from "../../../api/adminApi";
import BigButton from "../../../components/Button/BigButton";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import { NFTItem } from "../../../components/NFTItem/NFTItem";
import { NFTItemProps } from "../../../interface/components/interface";
import { RegisterSearchListPageProps } from "../../../interface/view/interface";
import {
  alertModalState,
  contentModalState,
  loadingModalState,
} from "../../../modules/Modal";
import { RootReducerState } from "../../../redux/RootReducer";
import theme from "../../../theme";
import { AdminRegisterModal } from "../../../components/Modal/Register/AdminRegisterModal";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { useResizingHandler } from "../../../hooks/useResizingHandler";
import { debounce } from "lodash";
import { ADMIN_PATH } from "../../../routers/GlobalRoute";

export interface ICategory {
  label: string;
  value: string;
}

export interface adminRegisterNFTInput {
  title: string;
  description: string;
  category: string;
  series: boolean;
}

export const categoryList: ICategory[] = [
  { label: "Digital Art", value: "digital-art" },
  { label: "Sports", value: "sports" },
  { label: "Collectable", value: "collectable" },
  { label: "Game", value: "game" },
];

export const RegisterSearchListPage = (props: RegisterSearchListPageProps) => {
  const user = useSelector((state: RootReducerState) => state.user);
  const [listData, setListData] = React.useState<NFTItemProps[]>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const [searchParams] = useSearchParams();
  const network = searchParams.get("network"); // string
  const contract = searchParams.get("contract"); // string
  const tokenId = searchParams.get("tokenId"); // string | null

  const alertModal = useSetRecoilState(alertModalState);

  const contentModal = useSetRecoilState(contentModalState);
  const resetContentModal = useResetRecoilState(contentModalState);

  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  const navigate = useNavigate();

  const [hasMore, setHasMore] = React.useState<boolean>(true);


  const fetchMoreListItems = useCallback(async () => {
    await getList(currentPage);
  }, [currentPage]);

  const {lastItemRef, isFetching, setIsFetching, Loading} = useInfiniteScroll(fetchMoreListItems);


  const { setValue, getValues, reset, register } = useForm<adminRegisterNFTInput>();

  const getList = async (page: number) => {
    if (isFetching) return;
    if (!hasMore) return;
    // loadingModal({
    //   isModal: true,
    //   isButton: true,
    //   type: "default",
    //   subText:
    //     tokenId === null
    //       ? "NFT를 가져오는 중입니다."
    //       : "NFT 목록을 가져오는 중입니다.",
    // });

    let res;
    if (tokenId) {
      res = await adminApi.getNFTList(
        user.token,
        network!,
        contract!,
        1,
        page,
        tokenId
      );
    } else {
      res = await adminApi.getNFTList(
        user.token,
        network!,
        contract!,
        12,
        page
      );
    }
    // setTimeout(() => {
    //   resetLoadingModal();
    // }, 1000);

    if (res.status !== 200) {
      alertModal({
        title: "안내",
        buttonText: "확인",
        isModal: true,
        content: res.message,
        type: "check",
      });
      setIsFetching(false);
      return;
    }

    setListData((prevData) => [...prevData, ...res.data.list]);
    if (res.data.list.length < 12) {
      setHasMore(false);
    }
    setCurrentPage((prevPage) => prevPage + 1);
    setIsFetching(false);
  };

  React.useEffect(() => {
    setIsFetching(true);
    getList(1);
  }, []);

  // const eventScrollFetch = async () => {
  //   loadingModal({
  //     isModal: true,
  //     isButton: true,
  //     type: "default",
  //     subText: "NFT 목록을 가져오는 중입니다.",
  //   });

  //   const res = await adminApi.getNFTList(
  //     user.token,
  //     network!,
  //     contract!,
  //     12,
  //     currentPage
  //   );

  //   setTimeout(() => {
  //     resetLoadingModal();
  //   }, 1000);
  //   if (res.status !== 200) {
  //     alertModal({
  //       title: "안내",
  //       buttonText: "확인",
  //       isModal: true,
  //       content: res.message,
  //       type: "check",
  //     });
  //     return;
  //   }
  //   setCurrentPage((page) => page + 1);
  //   setListData((prev) => [...prev, ...res.data.list]);
  // };

  const onClick = (nft: NFTItemProps) => {
    contentModal({
      content: <AdminRegisterModal nft={nft} setValue={setValue} getValues={getValues} register={register}/>,
      isModal: true,
      subText: "",
      title: "NFT 등록",
      buttonText: "등록",
      cancelText: "취소",
      onClose () {
        reset();
      },
      onClick: async function () {
        loadingModal({
          isModal: true,
          isButton: true,
          type: "default",
        });

        const res = await adminApi.registerNFT(
          user.token,
          nft.pk,
          getValues("category") ?? categoryList[0].value,
          getValues("description"),
          getValues("title"),
          getValues("series") ? 1 : 0
        );

        resetLoadingModal();

        if (res.status !== 200) {
          alertModal({
            title: "안내",
            buttonText: "확인",
            isModal: true,
            content: "서버 오류.",
            onClick() {
              reset();
              resetContentModal();
            },
            type: "check",
          });
          return;
        }

        alertModal({
          title: "안내",
          buttonText: "확인",
          isModal: true,
          content: "등록이 완료되었습니다.",
          onClick() {
            reset();
            resetContentModal();
            setListData((prevListData) =>
              prevListData.filter((item) => item.pk !== nft.pk)
            );
          },
          type: "check",
        });
      },
    });
  };

  const resizeHandler = useCallback(() => {
    const items = document.querySelectorAll('.nft-item-wrapper');
    items.forEach((item) => {
      const element = item as HTMLElement;
      const width = element.clientWidth;
      const height = `${(width * 4) / 3}px`;
      element.style.height = height;
    });
  }, []);
  
  React.useEffect(() => {
    resizeHandler();
  },[])
  
  useResizingHandler(debounce(resizeHandler, 500));

  return (
    <AdminLayout {...props}>
      {listData.length === 0 ? (
        <NotFoundBlock>
          <p>검색 결과가 없습니다.</p>
          <BigButton
            text="다시 검색하기"
            onClick={() => navigate(`${ADMIN_PATH}/register/search`)}
          />
        </NotFoundBlock>
      ) : (
      //   <NFTWrap
      //   $grid={true}
      //   row={3}
      // >
      //   {listData.map((nft, index) => (
      //     <NFTItem
      //       key={`${nft.tokenAddress}#${nft.tokenId}`}
      //       {...nft}
      //       width={"100%"}
      //       height={"auto"}
      //       disableHeart={true}
      //       onClick={() => onClick(nft)}
      //       ref={index === listData.length - 1 ? lastItemRef : null}
      //     />
      //   ))}
      // </NFTWrap>
      <NFTWrap
            $grid={true}
            row={3}
          >
            {listData.map((nft, index) => (
              <NFTItemWrapper
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                ref={index === listData.length - 1 ? lastItemRef : null}
                className="nft-item-wrapper"
              >
                <NFTItem
                  {...nft}
                  width={"100%"}
                  disableHeart={true}
            onClick={() => onClick(nft)}
                />
              </NFTItemWrapper>
            ))}
          </NFTWrap>
      )}
                {hasMore && <Loading />}
    </AdminLayout>
  );
};


const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "grid" : "none")};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
  padding: 30px 50px;
  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;


const NotFoundBlock = styled.div`
  background-color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: ${theme.fontType.headline.fontSize};
    font-weight: ${theme.fontType.headline.bold};
    margin-bottom: ${theme.commonMargin.gap4};
    color: ${theme.colors.peacok};
  }
`;
