import { createGlobalStyle } from "styled-components";
import theme from ".";


const GlobalStyle = createGlobalStyle`

  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	/* font: inherit; */
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	background-color: ${theme.colors.primary};
	height: 100%;
	width: 100vw;
  	margin: 0 auto;
  	position: relative;
  	z-index: 0;

@media (min-width: 1920px) {
		width: 1920px;
}
 } 

 span {
	overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
 }
 
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
button{
	all:unset;
	cursor:pointer;
	box-sizing:border-box;
}
a{
	text-decoration: none;
	color:${theme.colors.body};
}
*{
	box-sizing:border-box;
}
body{
	width:100%;
	font-family: 'Roboto', 'Pretendard', sans-serif;
	color:${theme.colors.body};
}
textarea{
	font-size:14px;
	font-weight: 600;
	font-family: 'Roboto', 'Pretendard', sans-serif;
}
input::placeholder{
	color:${theme.colors.blueGrayDeeper2};
}
`;

export default GlobalStyle;
