import React from 'react';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import styled from 'styled-components';
import theme from '../../theme';

interface MoreInfoProps {
  title: string;
  children: React.ReactNode;
  radius?: string;
  topRadius?: string;
  bottomRadius?: string;
  openState: boolean;
}

export const MoreInfo = ({
  title,
  children,
  radius,
  topRadius,
  bottomRadius,
  openState
}:MoreInfoProps) => {
    const [open, setOpen] = React.useState<boolean>(openState);

    const openEvent = () => {
      setOpen(e => !e);
    }
    
  return (
    <Container radius={radius}>
        <TitleWithArrow onClick={openEvent} radius={topRadius}>
          <Title>{title}</Title>
          {open ?
            <IoMdArrowDropup color='#fff' />
            : <IoMdArrowDropdown color='#fff' />
          }
        </TitleWithArrow>
        {open && <ChildrenBox radius={bottomRadius}>
          {children}
        </ChildrenBox>}
    </Container>
  )
};


const Container = styled.article<{radius?: string;}>`
    width: 100%;
    background-color: ${theme.colors.secondary};
    display: flex;
    flex-direction: column;
    border-radius: ${props => props.radius ? props.radius : '10px'};
    border: 1px solid ${theme.colors.side};
    box-shadow: ${theme.commonShadow.shadow1};
`;

const TitleWithArrow = styled.section<{radius?: string;}>`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.commonMargin.widthGap1};
    border-radius: ${props => props.radius ? props.radius : '10px 10px 0 0'};
    @media (min-width: 1920px) {
    padding: 15px;
    }
    @media (max-width: 680px) {
    padding: 8px 13px;
    }
`;

const Title = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: ${theme.fontType.titleAndPrice.bold};
  font-size: 1.5vw;
  line-height: 1.6vw;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 680px) {
    font-size: 12px;
    line-height: 14px;
  }
`;


const ChildrenBox = styled.section<{radius?: string;}>`
    padding: ${theme.commonMargin.widthGap1};
    width: 100%;
    background-color: ${theme.colors.side};
    display: flex;
    flex-direction: column;
    border-radius: ${props => props.radius ? props.radius : '0 0 10px 10px'};
    
    @media (min-width: 1920px) {
    padding: 15px;
    }
    @media (max-width: 680px) {
    padding: 8px 13px;
    }
`;