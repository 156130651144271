import { AnyAction, configureStore, Dispatch, Middleware } from "@reduxjs/toolkit";
import logger from 'redux-logger';
import RootReducer from "./RootReducer";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
    key: 'root',
    storage,
  };
  
  const persistedReducer = persistReducer(persistConfig, RootReducer);
  
/**
 * 애플리케이션의 '상태'를 관리하기 위한 Stroe 구성
 */
export const store = configureStore({
    // combined된 여러개의 리듀서를 store에 저장합니다.
    reducer: persistedReducer,

    // 미들 웨어로 logger를 사용합니다.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(logger as Middleware<{}, any, Dispatch<AnyAction>>),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch