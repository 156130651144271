import React, {
  createContext,
  ReactNode,
  useContext
} from "react";

import userApi from "../api/userApi";
import useInterval from "../hooks/useInterval";

declare global {
  interface Window {
    klaytn: any;
  }
}

interface exchangeDataProps {
  pk: number;
  network: string;
  value: string;
}

export interface exchangedDataProps {
  network: string;
  balance: string;
}

interface ExchangeContextType {
  exchangeData: exchangeDataProps[];
}


// 컨텍스트 생성
const ExchangeContext = createContext<ExchangeContextType>({
  exchangeData: []
});

// 컨텍스트 제공자 컴포넌트
export const ExchangeProvider = ({ children }: { children: ReactNode }) => {
  const [exchangeData, setExchangeData] = React.useState<exchangeDataProps[]>(
    []
  );
  
  React.useEffect(() => {
    getExchangeData();
  }, []);

  // 코인 -> 달러 로 환전된 목록을 가져온다
  const getExchangeData = async () => {
    const res = await userApi.nft.exchangeToDollar();
    setExchangeData(res.data);
  };
  useInterval(getExchangeData, 30 * 1000);


  return (
    <ExchangeContext.Provider
      value={{
        exchangeData
      }}
    >
      {/* <button onClick={() => {nativeTransfer({toAddress: '0xE59834E2386aC8aB638FC7db565C4621A3c1db9a', amount: '0.001'})}}>aaa</button> */}
      {children}
    </ExchangeContext.Provider>
  );
};

// 컨텍스트 훅
export const useExchange = () => useContext(ExchangeContext);
