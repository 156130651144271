import React from "react";
import { useForm } from "react-hook-form";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import "../../animation.css";
import userApi from "../../api/userApi";
import {
  exchangedDataProps,
  useExchange,
} from "../../contexts/ExchangeContext";
import { useWallet } from "../../contexts/WalletContext";
import { chainList } from "../../controller/chainId";
import useApprove from "../../hooks/web3/useApprove";
import useDisapprove from "../../hooks/web3/useDisapprove";
import { useNativeTransfer } from "../../hooks/web3/useNativeTransfer";
import { useOwner } from "../../hooks/web3/useOwner";
import { useSetChains } from "../../hooks/web3/useSetChains";
import { NFTItemProps } from "../../interface/components/interface";
import {
  alertModalState,
  contentModalState,
  loadingModalState,
} from "../../modules/Modal";
import theme from "../../theme";
import { removeComma, setComma } from "../../utils/Comma";
import { shortenAddress, shortenBalance } from "../../utils/web3";
import { RegisterModal } from "../Modal/Register/RegisterModal";
import SelectChainModal from "../Modal/SelectChainModal";
import SelectChainOfferModal from "../Modal/SelectChainOfferModal";
import { useStandard } from "../../contexts/StandardContext";
import { useSymbol } from "../../hooks/web3/useSymbol";
import { assets } from "../../theme/assets";

export interface userRegisterNFTInput {
  price: string;
}

export interface userBuyOrOfferInput {
  price: string;
  offer?: string;
}

type checkOwnerType =
  | "APPROVE"
  | "DISAPPROVE"
  | "PURCHASE"
  | "DOWNLOAD"
  | "OFFER";

export const NFTPrice = (nft: NFTItemProps) => {
  const [dollar, setDollar] = React.useState<string>("-");
  const [saleEnd, setSaleEnd] = React.useState<string>("");
  const { standard } = useStandard();

  const alertModal = useSetRecoilState(alertModalState);
  const resetAlertModal = useResetRecoilState(alertModalState);

  const [animating, setAnimating] = React.useState(false);
  const [symbol, setSymbol] = React.useState("");
  const contentModal = useSetRecoilState(contentModalState);
  const resetContentModal = useResetRecoilState(contentModalState);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  const { setValue, getValues, reset } = useForm<userRegisterNFTInput>();
  const {
    setValue: setBuyOrOfferValue,
    getValues: getBuyOrOfferValues,
    watch,
  } = useForm<userBuyOrOfferInput>();

  const { openModal } = useWallet();
  const { address, chainId, isConnected } = useAccount();
  const { exchangeData } = useExchange();
  const [exchangedData, setExchangedData] = React.useState<
    exchangedDataProps[]
  >([]);

  const { setChain } = useSetChains();
  const { ownerOfOtherChain } = useOwner();
  const { symbolOfNFT } = useSymbol();
  // const {owner} = useOwnerOfOnChainParams({
  //   abi: nft.abi,
  //   contractAddress: nft.tokenAddress,
  //   tokenId: nft.tokenId,
  // });
  const { approveHash, approveError, approvePending, approve } = useApprove();
  const { disapproveHash, disapproveError, disapprovePending, disapprove } =
    useDisapprove();
  const {
    sendTransactionHash,
    sendTransactionPending,
    sendTransactionError,
    nativeTransfer,
  } = useNativeTransfer();

  const {
    sendTransactionHash: offerHash,
    sendTransactionPending: offerPending,
    sendTransactionError: offerError,
    nativeTransfer: offer,
  } = useNativeTransfer();

  /** ------------ 여기서 useEffect, interval --------------- */

  // 애니메이션
  React.useEffect(() => {
    if (dollar !== "-") setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 1000);
    return () => clearTimeout(timer);
  }, [dollar]);

  // Approve 관리
  React.useEffect(() => {
    const afterApproved = async () => {
      if (getValues("price") === undefined) return;
      if (approveHash === "0x" || approveHash === undefined) return;
      const res = await userApi.nft.register(
        nft.pk.toString(),
        removeComma(getValues("price")),
        address!
      );

      if (res.status !== 200) {
        resetLoadingModal();
        alertModal({
          isModal: true,
          title: "Error",
          content: "Please connect admin.",
        });
        return;
      }
      nft.set!((prevNft) => {
        if (!prevNft) return prevNft;
        return { ...prevNft, price: getValues("price"), owner: address! };
      });
      resetLoadingModal();
      alertModal({
        isModal: true,
        title: "Succeeded",
        subTitle: "The approve process is complete.",
        content: (
          <Label>
            Tx:&nbsp;
            <Label
              style={{ cursor: "pointer", color: theme.colors.amount }}
              onClick={() => {
                window.open(
                  `${chainList[nft.chainId].host}/tx/${approveHash}`,
                  "_blank"
                );
              }}
            >
              {shortenAddress(approveHash)}
            </Label>
          </Label>
        ),
        type: "check",
        onClick() {
          reset();
        },
      });
    };
    if (approvePending) {
      loadingModal({
        isModal: true,
        isButton: false,
        type: "default",
        subText: `Your NFT approve is being processed.\nPlease sign the transaction.\nOne moment while we process your request.`,
      });
    } else if (!approvePending && approveHash) {
      afterApproved();
    }

    if (approveError) {
      resetLoadingModal();
      // alertModal({
      //   isModal: true,
      //   title: "Error",
      //   content: approveError.toString(),
      // });
    }
  }, [approvePending]);

  // Disapprove 관리
  React.useEffect(() => {
    const afterDisApproved = async () => {
      if (disapproveHash === "0x" || disapproveHash === undefined) return;

      await userApi.nft.reset(nft.pk);
      nft.set!((prevNft) => {
        if (!prevNft) return prevNft;
        return { ...prevNft, price: "0", owner: "" };
      });
      resetLoadingModal();
      alertModal({
        isModal: true,
        title: "Succeeded",
        subTitle: "The cancel approve process is complete.",
        content: (
          <Label>
            Tx:&nbsp;
            <Label
              style={{ cursor: "pointer", color: theme.colors.amount }}
              onClick={() => {
                window.open(
                  `${chainList[nft.chainId].host}/tx/${disapproveHash}`,
                  "_blank"
                );
              }}
            >
              {shortenAddress(disapproveHash)}
            </Label>
          </Label>
        ),
        type: "check",
        onClick() {
          reset();
        },
      });
    };
    if (disapprovePending) {
      loadingModal({
        isModal: true,
        isButton: false,
        type: "default",
        subText: `Your NFT cancel approve is being processed.\nPlease sign the transaction.\nOne moment while we process your request.`,
      });
    } else if (!disapprovePending && disapproveHash) {
      afterDisApproved();
    }

    if (disapproveError) {
      resetLoadingModal();
      // alertModal({
      //   isModal: true,
      //   title: "Error",
      //   content: disapproveError.toString(),
      // });
    }
  }, [disapprovePending]);

  // 구매 관리
  React.useEffect(() => {
    const afterTransfer = async () => {
      if (chainId === undefined) return;
      const res = await userApi.nft.trade(
        chainList[nft.chainId].network,
        chainId,
        nft.tokenAddress,
        nft.tokenId.toString(),
        nft.owner,
        address!,
        getBuyOrOfferValues("price")
      );
      if (res.status !== 200) {
        resetLoadingModal();
        alertModal({
          isModal: true,
          title: "Error",
          content: "Please connect admin.",
        });
        return;
      }
      resetLoadingModal();
      alertModal({
        isModal: true,
        title: "Succeeded",
        subTitle: "Purchase has been completed.",
        content: (
          <Label>
            Tx:&nbsp;
            <Label
              style={{ cursor: "pointer", color: theme.colors.amount }}
              onClick={() => {
                window.open(
                  `${chainList[chainId!].host}/tx/${sendTransactionHash}`,
                  "_blank"
                );
              }}
            >
              {shortenAddress(sendTransactionHash as `0x${string}`)}
            </Label>
          </Label>
        ),
        type: "check",
        onClick() {
          setBuyOrOfferValue("offer", undefined);
        },
      });
      nft.set!((prevNft) => {
        if (!prevNft) return prevNft;
        return { ...prevNft, price: "0", owner: "" };
      });
    };
    if (sendTransactionPending) {
      loadingModal({
        isModal: true,
        isButton: false,
        type: "default",
        subText: `Your NFT purchase is being processed.\nPlease sign the transaction.\nOne moment while we process your request.`,
      });
    } else if (!sendTransactionPending && sendTransactionHash) {
      afterTransfer();
    }

    if (sendTransactionError) {
      resetLoadingModal();
      // alertModal({
      //   isModal: true,
      //   title: "Error",
      //   content: sendTransactionError.toString(),
      // });
    }
  }, [sendTransactionPending]);

  // 오퍼 관리
  React.useEffect(() => {
    const afterOffer = async () => {
      if (chainId === undefined) return;
      if (address === undefined) return;
      if (getBuyOrOfferValues("offer") === undefined) return;

      const res = await userApi.nft.offer({
        pk: nft.pk,
        network: chainList[chainId].network,
        amount: getBuyOrOfferValues("offer")!,
        ownerWallet: address,
      });
      if (res.status !== 200) {
        resetLoadingModal();
        alertModal({
          isModal: true,
          title: "Error",
          content: "Please connect admin.",
        });
        return;
      }
      if (nft.offer_FETCH) {
        await nft.offer_FETCH();
      }
      resetLoadingModal();
      alertModal({
        isModal: true,
        title: "Succeeded",
        subTitle: "Your offer has been completed.",
        content: (
          <Label>
            Tx:&nbsp;
            <Label
              style={{ cursor: "pointer", color: theme.colors.amount }}
              onClick={() => {
                window.open(
                  `${chainList[chainId!].host}/tx/${offerHash}`,
                  "_blank"
                );
              }}
            >
              {shortenAddress(offerHash as `0x${string}`)}
            </Label>
          </Label>
        ),
        type: "check",
        onClick() {
          setBuyOrOfferValue("offer", undefined);
        },
      });
    };
    if (offerPending) {
      loadingModal({
        isModal: true,
        isButton: false,
        type: "default",
        subText: `Your NFT offer is being processed.\nPlease sign the transaction.\nOne moment while we process your request.`,
      });
    } else if (!offerPending && offerHash) {
      afterOffer();
    }

    if (offerError) {
      resetLoadingModal();
      // alertModal({
      //   isModal: true,
      //   title: "Error",
      //   content: sendTransactionError.toString(),
      // });
    }
  }, [offerPending]);

  // nft네트워크 코인을 달러로 환산 후, 다른체인의 코인의 갯수 구하기
  React.useEffect(() => {
    setCoinPrice();
  }, [chainId, dollar, exchangedData]);

  React.useEffect(() => {
    convertDate();
    doSetDollar(nft.network);
    exchangeToDolar(nft.network, nft.price);
  }, [nft, exchangeData]);

  React.useEffect(() => {
    getNFTSymbol()
  },[nft])

  /** ------------ 여기서 함수 --------------- */

  // 코인 -> 달러 로 환전된 목록으로 각 코인의 balance를 정함
  const exchangeToDolar = (network: string, balance: string) => {
    if (exchangeData.length === 0) return;

    const baseNetwork = exchangeData.find(
      (item) => item.network.toUpperCase() === network.toUpperCase()
    );
    if (!baseNetwork) {
      return;
    }

    const baseBalance = parseFloat(balance);
    const baseValue = parseFloat(baseNetwork.value);
    const baseRate = baseBalance * baseValue;

    const data = exchangeData.map((item) => {
      const calculatedBalance = baseRate / parseFloat(item.value);
      return {
        network: item.network,
        balance:
          item.network === network
            ? balance
            : calculatedBalance.toFixed(6).toString(),
      };
    });
    setExchangedData(data);
  };

  // 코인 -> 달러 로 환전된 목록으로 현 체인의 코인을 달러로 환산
  const doSetDollar = (network: string) => {
    const baseNetwork = exchangeData.find(
      (item) => item.network.toUpperCase() === network.toUpperCase()
    );
    if (!baseNetwork) {
      setDollar("-");
      return;
    }
    const dollar = (
      parseFloat(baseNetwork.value) * parseFloat(nft.price)
    ).toFixed(6);
    setDollar(dollar);
  };

  // 각 코인의 환율에 맞게 코인 비율 환산
  const setCoinPrice = () => {
    if (chainId === undefined) return;
    const selected = exchangedData.find(
      (item) => item.network === chainList[chainId!].network
    );
    if (selected === undefined) return;
    setBuyOrOfferValue("price", selected.balance.toString());
  };

  // 마지막 거래일 환산
  const convertDate = () => {
    if (nft === null) return;

    const date = new Date(nft.lastDate);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone: "UTC",
    };

    const formatter = new Intl.DateTimeFormat(navigator.language, options);
    const formattedDate = formatter.format(date);
    return setSaleEnd(formattedDate);
  };

  // 지갑 연결되었는지 확인
  const checkConnect = () => {
    if (address === undefined) {
      alertModal({
        isModal: true,
        type: "check",
        content: "Please connect wallet first.",
        onClick() {
          openModal();
        },
      });
      return false;
    }
    return true;
  };

  // 거래하는 체인이랑 일치한지 확인 및 불일치시, 체인 변경
  const checkChain = () => {
    if (chainId?.toString() !== nft.chainId.toString()) {
      alertModal({
        isModal: true,
        content: `NFT chain is ${
          chainList[nft.chainId].chainName
        }\nDo you want to change the chain to ${
          chainList[nft.chainId].chainName
        }`,
        onClick() {
          setChain({ chainId: nft.chainId });
          resetAlertModal();
        },
      });
      return false;
    }
    return true;
  };

  // owner 체크
  const chekOwner = async (type: checkOwnerType) => {
    let _address, _owner, _content;

    switch (type) {
      case "PURCHASE":
        _address = nft.owner;
        break;
      case "OFFER":
        _address = nft.owner;
        break;
      case "DOWNLOAD":
        _address = address;
        break;
      case "APPROVE":
        _address = address;
        break;
      case "DISAPPROVE":
        _address = address;
        break;
      default:
        return;
    }

    loadingModal({
      isModal: true,
      isButton: false,
      type: "default",
      subText: `Checking NFT owner.\nPlease one moment.`,
    });

    _owner = await ownerOfOtherChain({
      checkAddress: _address!,
      standard: standard,
      chainId: nft.chainId,
      contractAddress: nft.tokenAddress,
      tokenId: nft.tokenId,
    });

    resetLoadingModal();

    const checksumAddress = (_address ?? "").toLowerCase();
    const checksumOnwer = (_owner ?? "").toLowerCase();

    if (checksumAddress !== checksumOnwer) {
      switch (type) {
        case "PURCHASE":
          _content = `The owner of NFT has been Changed.\nA new owner of the NFT must re-approve.`;
          break;
        case "OFFER":
          _content = `The owner of NFT has been Changed.\nA new owner of the NFT must re-approve.`;
          break;
        case "DOWNLOAD":
          _content = `You are not the owner of the NFT!`;
          break;
        case "APPROVE":
          _content = `You are not the owner of the NFT!`;
          break;
        case "DISAPPROVE":
          _content = `You are not the owner of the NFT!`;
          break;
        default:
          return;
      }

      alertModal({
        isModal: true,
        title: "Error",
        content: _content,
        type: "check",
        onClick: async function () {
          if (type === "PURCHASE" || type === "OFFER") {
            // 등록된 nft 초기화 로직
            await userApi.nft.reset(nft.pk);
            nft.set!((prevNft) => {
              if (!prevNft) return prevNft;
              return { ...prevNft, price: "0", owner: "" };
            });
          }
        },
      });
    }
    return checksumAddress === checksumOnwer;
  };

  const getNFTSymbol = async() => {
    const res = await symbolOfNFT({
      chainId: nft.chainId,
      contractAddress: nft.tokenAddress,
    });
    setSymbol(res);
  };
  /** ------------ 여기서 부터 버튼 함수 --------------- */

  // owner 판매 등록 (다른 체인에서 불가능)
  const onClickApprove = async () => {
    if (!checkConnect()) return;
    if (!checkChain()) return;
    if (!(await chekOwner("APPROVE"))) return;

    contentModal({
      content: (
        <RegisterModal
          getValues={getValues}
          setValue={setValue}
          id={nft.chainId}
        />
      ),
      isModal: true,
      subText: "",
      title: "Approve to sell NFT",
      buttonText: "Approve",
      onClose() {
        reset();
      },
      onClick: async function () {
        resetContentModal();
        await approve({
          standard: standard,
          abi: nft.abi,
          contractAddress: nft.tokenAddress,
          tokenId: nft.tokenId,
        });
      },
    });
  };

  // 권한 제거 (다른 체인에서 불가능)
  const onClickDisapprove = async () => {
    if (!checkConnect()) return;
    if (!checkChain()) return;
    if (!(await chekOwner("DISAPPROVE"))) return;

    await disapprove({
      standard: standard,
      abi: nft.abi,
      contractAddress: nft.tokenAddress,
      tokenId: nft.tokenId,
    });
  };

  // 구매 (다른 체인에서 가능)
  const onClickPurchase = async () => {
    if (!checkConnect()) return;
    if (!(await chekOwner("PURCHASE"))) return;
    if (nft.owner === address!) {
      alertModal({
        isModal: true,
        title: "Error",
        content: `The NFT you registered is not available for purchase.`,
      });
      return;
    }

    contentModal({
      content: (
        <SelectChainModal getValues={getBuyOrOfferValues} watch={watch} />
      ),
      isModal: true,
      subText: "",
      title: "Select chain to buy NFT",
      buttonText: "Buy",
      onClose() {
        setBuyOrOfferValue("offer", undefined);
      },
      onClick: async function () {
        resetContentModal();
        if (process.env.REACT_APP_ADMIN_WALLET_ADDRESS === undefined) return;
        nativeTransfer({
          toAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
          amount: getBuyOrOfferValues("price"),
        });
      },
    });
    return;
  };

  // 가격 흥정 (다른 체인에서 가능)
  const onClickOffer = async () => {
    if (!checkConnect()) return;
    if (nft.price !== "0") {
      if (!(await chekOwner("OFFER"))) {
        return;
      }
    }
    console.log("hi")
    if (nft.owner === address!) {
      alertModal({
        isModal: true,
        title: "Error",
        content: `The NFT you registered is not available for purchase.`,
      });
      return;
    }

    contentModal({
      content: (
        <SelectChainOfferModal
          getValues={getBuyOrOfferValues}
          setValue={setBuyOrOfferValue}
          watch={watch}
        />
      ),
      isModal: true,
      subText: "",
      title: "Select chain to offer NFT",
      buttonText: "Offer",
      onClose() {
        setBuyOrOfferValue("offer", undefined);
      },
      onClick: async function () {
        resetContentModal();
        if (process.env.REACT_APP_ADMIN_WALLET_ADDRESS === undefined) return;
        offer({
          toAddress: process.env.REACT_APP_ADMIN_WALLET_ADDRESS,
          amount: getBuyOrOfferValues("offer"),
        });
      },
    });
    return;
  };

  // 원본 이미지 다운 (다른 체인에서 가능)
  const onClickDownload = async () => {
    if (!checkConnect()) return;
    if (!(await chekOwner("DOWNLOAD"))) return;

    const link =  nft.imgRef && "current" in nft.imgRef && nft.imgRef.current?.src;
    // console.log(link)
    // console.log(`${location.origin}${assets.logo}`);
    if(!link){
      alertModal({
        isModal: true,
        title: "Warning",
        content: `If you see the NFTs image, please try again.`,
      });
      return;
    };
    if(`${location.origin}${assets.logo}` === link){
      alertModal({
        isModal: true,
        title: "Warning",
        content: `If you see the NFTs image, please try again.`,
      });
      return;
    }

    await userApi.nft.download(
      nft.tokenAddress,
      nft.tokenId.toString(),
      link.toString()
    );
  };

  return (
    <Item>
      <Common>Sale End: {saleEnd}</Common>
      <Wrap>
        {/* <WrapLine /> */}
        <InfoBox>
          <InfoPrice>
            {`${shortenBalance(nft.totalVolume!.toString())} ${
              symbol
            }`}
          </InfoPrice>
          <InfoType>Total Volume</InfoType>
        </InfoBox>
        <Line/>
        <InfoBox>
          <InfoPrice>
            {nft.floorPrice === 9999999
              ? "-"
              : `${nft.floorPrice} ${
                nft.floorPrice && chainList[nft.chainId].symbol
              }`}
          </InfoPrice>
          <InfoType>Floor Price</InfoType>
        </InfoBox>
        <Line/>
        <InfoBox>
          <InfoPrice>
            {nft.bestOffer ?? "-"}{" "}
            {nft.bestOffer && chainList[nft.chainId].symbol}
          </InfoPrice>
          <InfoType>Best Offer</InfoType>
        </InfoBox>
      </Wrap>
      {nft.price.toString() !== "0" && (
        <>
          <Common>Current price</Common>
          <Row>
            <CurrentPrice>
              {setComma(Number(nft.price))} {chainList[nft.chainId].symbol}
            </CurrentPrice>
            <Common>
              $&nbsp;
              <div
                className={`price-container ${animating ? "slot-machine" : ""}`}
              >
                <div className="number">{setComma(Number(dollar))}</div>
              </div>
            </Common>
          </Row>
        </>
      )}

      <Row>
        {nft.price.toString() !== "0" ? (
          <>
            {nft.owner === address ? (
              <>
                <Button left={true} onClick={onClickDownload}>
                  Dowload
                </Button>
                <Button left={false} onClick={onClickDisapprove}>
                  Cancel Approve
                </Button>
              </>
              ) : (
              <>
                <Button left={true} onClick={onClickPurchase}>
                  Buy
                </Button>
                <Button left={false} onClick={onClickOffer}>
                  Offer
                </Button>
              </>
            )}
          </>
        ) : (
          <>
            <Button left={true} onClick={onClickApprove}>
              Approve
            </Button>
            <Button left={false} onClick={onClickOffer}>
              Offer
            </Button>
            {/* {(standard.split("-")[1] === "1155" ||
              standard.split("-")[1] === "37") && (
              <Button left={false} onClick={onClickApprove}>
                Approve
              </Button>
            )} */}
          </>
        )}
      </Row>

    </Item>
  );
};

const Item = styled.div<{ radius?: string }>`
  /* width: 240px; */
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.side};
  border-radius: 10px;
  padding: 2vw;
  gap: 1.5vw;
  box-shadow: ${theme.commonShadow.shadow1};
  @media (min-width: 1920px) {
    padding: 30px;
    gap: 35px;
  }
  @media (max-width: 680px) {
    padding: 10px;
    gap: 20px;
  }
`;

const Common = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1.15vw;
  line-height: 1.7vw;
  display: flex;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Wrap = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2vw;
  gap: 1.5vw;
  background-color: #191f29;
  /* position: relative; */
  border-radius: 14px;
  @media (min-width: 1920px) {
    padding: 30px;
    gap: 15px;
  }
  @media (max-width: 680px) {
    padding: 10px;
    gap: 10px;
  }
`;

const Line = styled.div`
  display: flex;
  background-color: transparent;
  width: 1px;
  height: 100%;
  border-color: ${theme.colors.side};
  border-style: solid;
  border-width: 0 2px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1vw;
  align-items: center;
  justify-content: center;
`;

const InfoPrice = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 1.5vw;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const InfoType = styled.span`
  color: #777d88;
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.12vw;

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 20px;
  }
  @media (max-width: 680px) {
    font-size: 12px;
    line-height: 14px;
  }
`;

const CurrentPrice = styled.span`
  color: ${theme.colors.amount};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 700;

  font-size: 2vw;
  line-height: 2.1vw;
  @media (min-width: 1920px) {
    font-size: 40px;
    line-height: 45px;
  }
  @media (max-width: 680px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

const Row = styled.div`
  display: flex;
  gap: 1vw;
  align-items: end;
  @media (max-width: 680px) {
    gap: 15px;
  }
`;

const Button = styled.button<{ left?: boolean }>`
  flex: 1;
  background-color: ${(props) =>
    props.left ? theme.colors.white : "transparent"};
  border-radius: 10px;
  border: ${(props) => (props.left ? "1px solid #fff" : "1px solid #777D88")};
  font-family: ${theme.fontFamily.pretendard};
  font-size: 1.2vw;
  font-weight: 900;
  color: ${(props) => (props.left ? "#000" : "#777D88")};
  text-align: center;
  padding: 1.2vw 3vw;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
    padding: 20px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  flex: 1;
  text-align: start;
  width: 150px;
  color: #fff;
`;
