import React from "react";

export const useResizingHandler = (handler: () => void) => {

  React.useEffect(() => {
    handler();
    window.addEventListener('resize', handler);
    return () => {
      // 메모리 누수를 줄이기 위한 removeEvent
      window.removeEventListener('resize', handler);
    };
  }, [handler]);
};
