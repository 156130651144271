import React, { useEffect } from 'react';
import { useResizingHandler } from '../../hooks/useResizingHandler';
import { useSidebar } from '../../contexts/SidebarContext';

interface HambergerMenuProps {
  openValue: boolean;
  setOpenValue: (value: boolean) => void;
}

export const HambergerMenu: React.FC<HambergerMenuProps> = ({ openValue, setOpenValue }) => {
  const { open, setOpen } = useSidebar();

  const onClick = () => {
    if (open) {
      setTimeout(() => {
        const sidebar = document.getElementById('main-sidebar');
        if (sidebar) {
          sidebar.style.display = 'none';
        }
      }, 400);
    } else {
      const sidebar = document.getElementById('main-sidebar');
      if (sidebar) {
        sidebar.style.display = 'block';
      }
    }
    setOpenValue(!openValue);
    setOpen(!open);
  };

  useEffect(() => {
    const one1 = document.getElementById('one1');
    const three1 = document.getElementById('three1');
    const two = document.getElementById('two');
    const one = document.getElementById('one');
    const three = document.getElementById('three');

    if (openValue) {
      if (two) two.style.display = 'none';
      if (one) one.style.transform = 'translateY(5px)';
      if (one1) {
        one1.style.transform = 'rotate(45deg)';
        one1.style.transition = 'all 0.2s cubic-bezier(0, 0, 0, 1) 0.2s';
        one1.style.height = '2px';
      }
      if (three) three.style.transform = 'translateY(-5px)';
      if (three1) {
        three1.style.transform = 'rotate(-45deg)';
        three1.style.transition = 'all 0.2s cubic-bezier(0, 0, 0, 1) 0.2s';
        three1.style.height = '2px';
      }
    } else {
      if (two) two.style.display = 'block';
      if (one) one.style.transform = 'none';
      if (one1) {
        one1.style.transform = 'none';
        one1.style.transition = 'all 0.2s cubic-bezier(0, 0, 0, 1) 0.05s';
        one1.style.height = '1px';
      }
      if (three) three.style.transform = 'none';
      if (three1) {
        three1.style.transform = 'none';
        three1.style.transition = 'all 0.2s cubic-bezier(0, 0, 0, 1) 0.05s';
        three1.style.height = '1px';
      }
    }
  }, [openValue]);

  const showMenu = () => {
    const sidebar = document.getElementById('main-sidebar');
    const hamMenu = document.getElementById('ham-man-menu');

    if (window.innerWidth <= 1000) {
      if (!open && sidebar) {
        sidebar.style.display = 'none';
      }
      if (hamMenu) {
        hamMenu.style.display = 'block';
      }
    } else {
      if (open) {
        setOpen(false);
        setOpenValue(false);
      }
      if (sidebar) {
        sidebar.style.display = 'block';
      }
      if (hamMenu) {
        hamMenu.style.display = 'none';
      }
    }
  };

  useResizingHandler(showMenu);

  return (
    <div
      id='ham-man-menu'
      onClick={onClick}
      style={{ width: '16px', height: '20px', cursor: 'pointer', marginRight: '10px', display: 'block' }}
    >
      <div style={{ position: 'relative', transition: 'all 0.4s cubic-bezier(0, 0, 0, 1) 0s', userSelect: 'none' }}>
        <div id='one' style={{ transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s', opacity: 1 }}>
          <div id='one1' style={{ background: '#fff', height: '1px', left: 0, position: 'absolute', width: '100%', top: '3px', transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s' }} />
        </div>
        <div id='two' style={{ transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s', opacity: 1 }}>
          <div id='two1' style={{ background: '#fff', height: '1px', left: 0, position: 'absolute', width: '100%', top: '8px', transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s', transform: 'none' }} />
        </div>
        <div id='three' style={{ transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s', opacity: 1 }}>
          <div id='three1' style={{ background: '#fff', height: '1px', left: 0, position: 'absolute', width: '100%', top: '13px', transition: 'all 0.2s cubic-bezier(0, 0, 0, 1) 0s', transform: 'none' }} />
        </div>
      </div>
    </div>
  );
};
