import React from "react";
import { FaTh } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { Layout } from "../../../components/Layout/Layout";
import { selectBoxItem, useList } from "../../../contexts/ListContext";
import { useWallet } from "../../../contexts/WalletContext";
import { ProfilePageProps } from "../../../interface/view/interface";
import { alertModalState } from "../../../modules/Modal";
import { ProfileLiked } from "./Children/ProfileLiked";
import { ProfilePurchased } from "./Children/ProfilePurchased";
import { ProfileSold } from "./Children/ProfileSold";

export const ProfilePage = (props: ProfilePageProps) => {
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const {address} = useAccount();
  const {openModal} = useWallet();
  const navigate = useNavigate();
  const alertModal = useSetRecoilState(alertModalState);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    switch(type){
      case 'purchased':
        setActiveTab(0);
        break;
        case 'sold':
        setActiveTab(1);
        break;
        case 'liked':
        setActiveTab(2);
        break;
        default:
          break;        
    }
  }, []);


  const handleTabClick = (tab: number) => {
    setActiveTab(tab);
  };

  const {setSelectedOption} = useList();

  React.useEffect(() => {
    if(address === undefined){
      alertModal({
        isModal: true,
        type: 'check',
        content: "Please connect wallet first.",
        onClick() {
          navigate('/')
          openModal();
        },
      });
      return;
    }
  },[address])

  const tabData = [
    {
      label: "Purchased",
      active: 0,
      icon: <FaTh />,
      onClick: () => {
        const params = new URLSearchParams(location.search);
        params.set("type", "purchased");
        params.delete("order");
        setSelectedOption(selectBoxItem[0])
        handleTabClick(0)
        navigate({
          search: params.toString(),
        });
      },
    },
    {
      label: "Sold",
      active: 1,
      icon: <FaTh />,
      onClick: () => {
        const params = new URLSearchParams(location.search);
        params.set("type", "sold");
        params.delete("order");
        setSelectedOption(selectBoxItem[0])
        handleTabClick(1)
        navigate({
          search: params.toString(),
        });
      },    },
    {
      label: "Liked",
      active: 2,
      icon: <FaTh />,
      onClick: () => {
        const params = new URLSearchParams(location.search);
        params.set("type", "liked");
        params.delete("order");
        setSelectedOption(selectBoxItem[0])
        handleTabClick(2)
        navigate({
          search: params.toString(),
        });
      },    },
  ];

  return (
    <Layout {...props}>
      <TabsContainer>
        {tabData.map((tab) => {
          return (
            <Tab isActive={activeTab === tab.active} onClick={tab.onClick}>
              {tab.icon}
              <TabLabel>{tab.label}</TabLabel>
              {activeTab === tab.active && <ActiveBar />}
            </Tab>
          );
        })}
      </TabsContainer>
      {activeTab === 0 ? (
        <ProfilePurchased />
      ) : activeTab === 1 ? (
        <ProfileSold />
      ) : activeTab === 2 ? (
        <ProfileLiked />
      ) : (
        <></>
      )}
    </Layout>
  );
};

const TabsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1c1f24;
  padding: 10px 0;
  border-bottom: 1px solid #333;
`;

const Tab = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? "#fff" : "#777")};

  &:hover {
    color: #fff;
  }
`;

const TabLabel = styled.span`
  margin-top: 5px;
  font-size: 14px;
`;

const ActiveBar = styled.div`
  width: 100%;
  height: 2px;
  background-color: #a050e0;
  margin-top: 5px;
`;
