export const calculateDateDifference = (startDate: number): string => {
    const start = new Date(startDate);
    const now = new Date();

    if (isNaN(start.getTime())) {
        throw new Error('Invalid start date');
    }

    const millisecondsInAMinute = 60 * 1000;
    const millisecondsInAnHour = 60 * millisecondsInAMinute;
    const millisecondsInADay = 24 * millisecondsInAnHour;
    const millisecondsInAWeek = millisecondsInADay * 7;
    const millisecondsInAMonth = millisecondsInADay * 30; // 대략적인 월 계산 (30일로 간주)
    const millisecondsInAYear = millisecondsInADay * 365.25; // 윤년 고려

    const diffInMilliseconds = now.getTime() - start.getTime();

    const minutes = Math.floor(diffInMilliseconds / millisecondsInAMinute);
    const hours = Math.floor(diffInMilliseconds / millisecondsInAnHour);
    const days = Math.floor(diffInMilliseconds / millisecondsInADay);
    const weeks = Math.floor(diffInMilliseconds / millisecondsInAWeek);
    const months = Math.floor(diffInMilliseconds / millisecondsInAMonth);
    const years = Math.floor(diffInMilliseconds / millisecondsInAYear);

    if (minutes < 60) {
        if (minutes === 1) {
            return `${minutes} minute ago`;
        }
        return `${minutes} minutes ago`;

    } else if (hours < 24) {
        if (hours === 1) {
            return `${hours} hour ago`;
        }
        return `${hours} hours ago`;

    } else if (days < 7) {
        if (days === 1) {
            return `${days} day ago`;
        }
        return `${days} days ago`;

    } else if (weeks < 4) {
        if (weeks === 1) {
            return `${weeks} week ago`;
        }
        return `${weeks} weeks ago`;

    } else if (months < 12) {
        if (months === 1) {
            return `${months} month ago`;
        }
        return `${months} months ago`;

    } else {
        if (years === 1) {
            return `${years} year ago`;
        }
        return `${years} years ago`;
    }
}

export const calculateTimeRemaining = (endDate: number): string => {
    const end = new Date(endDate);
    const now = new Date();

    if (isNaN(end.getTime())) {
        throw new Error('Invalid end date');
    }

    const millisecondsInAMinute = 60 * 1000;
    const millisecondsInAnHour = 60 * millisecondsInAMinute;
    const millisecondsInADay = 24 * millisecondsInAnHour;
    const millisecondsInAWeek = millisecondsInADay * 7;
    const millisecondsInAMonth = millisecondsInADay * 30; // 대략적인 월 계산 (30일로 간주)
    const millisecondsInAYear = millisecondsInADay * 365.25; // 윤년 고려

    const diffInMilliseconds = end.getTime() - now.getTime();

    if (diffInMilliseconds <= 0) {
        return 'Time has passed';
    }

    const minutes = Math.floor(diffInMilliseconds / millisecondsInAMinute);
    const hours = Math.floor(diffInMilliseconds / millisecondsInAnHour);
    const days = Math.floor(diffInMilliseconds / millisecondsInADay);
    const weeks = Math.floor(diffInMilliseconds / millisecondsInAWeek);
    const months = Math.floor(diffInMilliseconds / millisecondsInAMonth);
    const years = Math.floor(diffInMilliseconds / millisecondsInAYear);

    if (minutes < 60) {
        if (minutes === 1) {
            return `in 1 minute`;
        }
        return `in ${minutes} minutes`;

    } else if (hours < 24) {
        if (hours === 1) {
            return `in 1 hour `;
        }
        return `in ${hours} hours`;

    } else if (days < 7) {
        if (days === 1) {
            return `in 1 day`;
        }
        return `in ${days} days`;

    } else if (weeks < 4) {
        if (weeks === 1) {
            return `in 1 week`;
        }
        return `in ${weeks} weeks`;

    } else if (months < 12) {
        if (months === 1) {
            return `in 1 month`;
        }
        return `in ${months} months`;

    } else {
        if (years === 1) {
            return `in 1 year`;
        }
        return `in ${years} years`;
    }
}
