import React from 'react'
import { useSwitchChain } from 'wagmi'

interface setChainsProps {
    chainId: number
}

export const useSetChains = () => {

    const { chains, switchChain } = useSwitchChain()

    const setChain = ({chainId}: setChainsProps) => {
        try{
            switchChain({chainId});
        }catch(err){
            console.log(err)
        }
    }

    return {
        setChain
    }
}
