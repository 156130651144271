import "styled-components";
import { DefaultTheme } from "styled-components";

const colors = {
  dark: "#0D0D0D",
  side: "#222A37",
  menu: "#191F2A",
  primary: "#181F29",
  secondary: "#313B4A",
  inactive: "#2D3647",
  amount: "#19D6FF",
  tableHeader: "#898E96",
  gray: "#8F8F8F",
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#828282",
  gray4: "#BDBDBD",
  gray5: "#E0E0E0",
  gray6: "#F2F2F2",
  blueGray1: "#DADDE5",
  blueGray2: "#EDEFF4",
  blueGrayDeeper2: "#AAB1C1",
  black30: "rgba(0, 0, 0, 0.3)",
  white: "#ffffff",
  green: "#6FCF97",
  red: "#AF0808",
  blue: "#2F80ED",
  body2: "#2A3748",
  body: "#000",
  blueDeep: "#CBD3E5",
  peacok: "#0A4763",
};

const fontFamily = {
  pretendard: 'Pretendard',
  roboto: 'Roboto',
}

const fontType = {
  header: { fontSize: "16px", bold: 400, lineHeight: "18.75px"},
  activeMenu: { fontSize: "16px", bold: 700, lineHeight: "19px"},
  inactiveMenu: { fontSize: "16px", bold: 500, lineHeight: "19px"},
  describe: { fontSize: "20px", bold: 500, lineHeight: "23px"},
  collection: { fontSize: "14px", bold: 500, lineHeight: "17px"},
  titleAndPrice: { fontSize: "16px", bold: 700, lineHeight: "19px"},
  headline: { fontSize: "28px", bold: 800 },
  subTitle1: { fontSize: "24px", bold: 800 },
  subTitle2: { fontSize: "15px", bold: 800 },
  subTitle3: { fontSize: "15px", bold: 500 },
  subTitle3LineHeight: { fontSize: "15px", bold: 500, lineHeight: "165%" },
  body: { fontSize: "14px", bold: 400 },
  bodyLineHeight: { fontSize: "13px", bold: 300, lineHeight: "165%" },
  button1: { fontSize: "15px", bold: 800 },
  button2: { fontSize: "13px", bold: 700 },
  pagenationBold: { fontSize: "14px", bold: 700 },
  pagenation: { fontSize: "14px", bold: 500 },
  time: { fontSize: "50px", bold: 600 },
  medium32: { fontSize: "32px", bold: 500 },
  medium30: { fontSize: "30px", bold: 500 },
  medium24: { fontSize: "24px", bold: 500 },
  medium16: { fontSize: "16px", bold: 500 },
  medium13: { fontSize: "13px", bold: 500 },
  medium12: { fontSize: "12px", bold: 500 },
  light20: { fontSize: "20px", bold: 300 },
  light18: { fontSize: "18px", bold: 300 },
  regular14: { fontSize: "14px", bold: 400 },
  regular13: { fontSize: "13px", bold: 400 },
  regular12: { fontSize: "12px", bold: 400 },
  bold30: { fontSize: "30px", bold: 700 },
  bold16: { fontSize: "16px", bold: 700 },
  bold13: { fontSize: "13px", bold: 700 },

};

const commonWidth = {
  wrapWidth: "1920px",
  landingWidth: "1560px",
  maxWidth: "1560px",
};
const commonHeight = {
  headerHeight: "48px",
};

const commonInput = {
  height: "40px",
  placeholderColor: colors.gray4,
  radius: "5px",
  border: `1px solid ${colors.gray5}`,
  paddingLeft: "14px",
  paddingHorizontal: "14px",
};

const commonMargin = {
  gap1: "10px",
  gap2: "20px",
  gap3: "40px",
  gap4: "70px",
  padding: "3vh 2vw",
  NFTItempadding: "2vw 1vw",
  NFTDetailadding: "1vh 2vw",
  heigthGap1: '1vh',
  widthGap1: '1vw',
  slideWrapper: "3vh 0 3vh 2vw",
  paddingSide: "4vw",
  paddingHorizontal: "0 4vw",
  paddingVertical: "3vw 0",
};

const commonRadius = {
  radius1: "8px",
};

const commonShadow = {
  shadow1 : "4px 4px 4px #000"
}

const theme: DefaultTheme = {
  colors,
  fontFamily,
  fontType,
  commonWidth,
  commonInput,
  commonMargin,
  commonRadius,
  commonHeight,
  commonShadow
};

export default theme;
