export const addressTrim = (address: string, substring: number, slice: number) => {
    const first = address.substring(0,substring);
    const end = address.slice(-slice);

    return `${first}...${end}`
}

export const capitalizeFirstLetter = (str: string) => {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  
  export const tokenIdTrim = (id: number, substring: number) => {
    const temp = id.toString();
    if(temp.length > substring){
      const first = id.toString().substring(0,substring);
      return `${first}...`;
    }
    return temp;
}