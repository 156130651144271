import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import userApi from "../../../../api/userApi";
import { Layout } from "../../../../components/Layout/Layout";
import { NFTChart } from "../../../../components/NFTItem/Chart/NFTChart";
import { Description } from "../../../../components/NFTItem/Info/Items/Description";
import { Detail } from "../../../../components/NFTItem/Info/Items/Detail";
import { Traits } from "../../../../components/NFTItem/Info/Items/Traits";
import { NFTInfo } from "../../../../components/NFTItem/Info/NFTInfo";
import { MoreInfo } from "../../../../components/NFTItem/MoreInfo";
import { NFTDetailImage } from "../../../../components/NFTItem/NFTDetailImage/NFTDetailImage";
import { NFTItem } from "../../../../components/NFTItem/NFTItem";
import { NFTPrice } from "../../../../components/NFTItem/NFTPrice";
import { OffersInfo } from "../../../../components/NFTItem/Table/offers/OffersInfo";
import TopArrowSlider from "../../../../components/Slider/TopArrowSlider/TopArrowSlider";
import { Table } from "../../../../components/Table/Table";
import { chainList } from "../../../../controller/chainId";
import {
  ITx,
  NFTInfoItemProps,
  NFTItemProps,
  TraitItemProps,
} from "../../../../interface/components/interface";
import { NFTDetailPageProps } from "../../../../interface/view/interface";
import { alertModalState, loadingModalState } from "../../../../modules/Modal";
import theme from "../../../../theme";
import { debounce } from "lodash";
import { useResizingHandler } from "../../../../hooks/useResizingHandler";
import Footer from "../../../../components/Footer/Footer"



export const NFTDetailPage = (props: NFTDetailPageProps) => {

  const imgRef = React.useRef<HTMLImageElement>(null);

  const [slidesToShow, setSlidesToShow] = React.useState<number>(4);
  const [slidesPerRow, setSlidesPerRow] = React.useState<number>(1);

  const navigate = useNavigate();
  const alertModal = useSetRecoilState(alertModalState);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  const { address } = useAccount();
  const [nft, setNft] = React.useState<NFTItemProps | null>(null);
  const [traits, setTraits] = React.useState<TraitItemProps[]>([]);
  const [tx, setTx] = React.useState<ITx[]>([]);
  const [maxHeight, setMaxHeight] = React.useState<number>(0);

  const { contract, tokenId, pk } = useParams();

  const [items, setItems] = React.useState<NFTInfoItemProps[]>([]);

  React.useEffect(() => {
    if (nft === null) {
      loadingModal({
        isModal: true,
        isButton: false,
        type: "default",
        subText: `Loading...`,
      });
    } else {
      resetLoadingModal();
      setItems([
        {
          title: "Description",
          child: <Description description={nft.description} />,
          radius: "10px 10px 0 0",
          topRadius: "10px 10px 0 0",
          bottomRadius: "0",
          openState: true,
        },
        {
          title: "Traits",
          child: <Traits traits={traits} />,
          radius: "0",
          topRadius: "0",
          bottomRadius: "0",
          openState: true,
        },
        {
          title: "Details",
          child: <Detail {...nft} set={setNft} />,
          radius: "0 0 10px 10px",
          topRadius: "0",
          bottomRadius: "0 0 10px 10px",
          openState: true,
        }
      ]);
    }
  }, [nft]);

  React.useEffect(() => {
    if(traits.length === 0){
      setItems(prevItems => prevItems.filter(item => item.title !== "Traits"));
    }
  },[traits])

  const getNFTData = async () => {
    const res = await userApi.nft.getInfo(pk!, address);

    if (res.status !== 200) {
      alertModal({
        isModal: true,
        content: res.message,
        type: "check",
        onClick() {
          navigate(-1);
        },
      });
      return;
    }
    setNft(res.data);

    if (res.data.attributes === null) return;
    let traitsArray;
    if(res.data.attributes === ''){
      setTraits([]);
    }else{
      traitsArray = JSON.parse(res.data.attributes);
      const transformedArray: TraitItemProps[] = traitsArray.map(
        (trait: { trait_type: string; value: string }) => ({
          label: trait.trait_type,
          title: trait.value,
        })
      );
      setTraits(transformedArray);
    }
  };

  const getTx = async () => {
    const res = await userApi.nft.getTx(contract!, tokenId!);

    if (res.status !== 200) {
      alertModal({
        isModal: true,
        content: res.message,
      });
      return;
    }
    setTx(res.data.data);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      await getNFTData();
      await getTx();
    };

    fetchData();
  }, [pk, address]);

  const { offer_COLUMNS, offer_DATA, offer_FETCH } = OffersInfo({
    nft,
    setNft,
  });

  const resizeHandler = useCallback(() => {
    if(nft && nft.anotherList && nft.anotherList?.length > 4) return;
    const items = document.querySelectorAll('.nft-item-wrapper');
    items.forEach((item) => {
      const element = item as HTMLElement;
      const width = element.clientWidth;
      const height = `${(width * 16) / 9}px`;
      element.style.height = height;
    });
  }, []);
  
  React.useEffect(() => {
    resizeHandler(); // 초기 호출
  },[])

  useResizingHandler(debounce(resizeHandler, 500));


  if (nft === null) {
    return <></>;
  }


  return (
    <Layout {...props}>
      <WebWrap className="nft-detail">
        <Row>
          <Container1>
            <NFTDetailImage ref={imgRef} {...nft} set={setNft} />
            <NFTInfo items={items} />
          </Container1>
          <Container2>
            <NFTPrice
              {...nft}
              set={setNft}
              offer_FETCH={offer_FETCH}
              imgRef={imgRef}
            />
            <MoreInfo title={"Price History"} openState={true}>
              <NFTChart unit={chainList[nft.chainId].symbol} data={tx} />
            </MoreInfo>
            <MoreInfo title={"Offers"} openState={true}>
              <Table
                COLUMNS={offer_COLUMNS}
                DATA={offer_DATA}
                fetch={offer_FETCH}
              />
            </MoreInfo>
          </Container2>
        </Row>
      </WebWrap>
      <MobileWrap className="nft-detail">
        <NFTDetailImage ref={imgRef} {...nft} set={setNft} />
        <NFTPrice
          {...nft}
          set={setNft}
          offer_FETCH={offer_FETCH}
          imgRef={imgRef}
        />
        <NFTInfo items={items} />
        <MoreInfo title={"Price History"} openState={true}>
          <NFTChart unit={chainList[nft.chainId].symbol} data={tx} />
        </MoreInfo>
        <MoreInfo title={"Offers"} openState={true}>
          <Table
            COLUMNS={offer_COLUMNS}
            DATA={offer_DATA}
            fetch={offer_FETCH}
          />
        </MoreInfo>
      </MobileWrap>
      <Rigth>
        {
          nft.anotherList &&
          nft.anotherList.length === 0
          ? <></>
          :
          nft.anotherList &&
          nft.anotherList.length < 5
          ?
          <Wrapper>
          <TitleWithArrowWrap>
        <Title>More From This Collection</Title>
      </TitleWithArrowWrap>

          <NFTWrap
            $grid={true}
            row={4}
            >
            {nft.anotherList.map((nft, index) => (
              <NFTItemWrapper
              key={`${nft.tokenAddress}#${nft.tokenId}`}
              className="nft-item-wrapper"
              >
                <NFTItem
                  {...nft}
                  width={"100%"}
                  />
              </NFTItemWrapper>
            ))}
          </NFTWrap>
            </Wrapper>
          :
          <TopArrowSlider
          text={"More From This Collection"}
          padding={theme.commonMargin.paddingVertical}
          slidesToShow={slidesToShow}
          slidesPerRow={slidesPerRow}
          size={nft.anotherList?.length}
          maxHeight={maxHeight}
          setMaxHeight={setMaxHeight}
        >
          {nft.anotherList?.map((other) => (
            <NFTItem
              key={`${other.tokenAddress}#${other.tokenId}`}
              {...other}
              chainId={nft.chainId}
              width={"100%"}
              height={maxHeight.toString()}
            />
          ))}
        </TopArrowSlider>
        }
        
      </Rigth>
      <Footer/>
    </Layout>
  );
};

const Rigth = styled.div`
  display: flex;
  width: 100%;
  padding-left: 2vw;
  @media (min-width: 1920px) {
    padding-left: 30px;
  }
`;

const WebWrap = styled.article`
  display: flex;
  flex-direction: column;
  gap: 2vw;
  padding: ${theme.commonMargin.padding};
  @media (min-width: 1920px) {
    padding: 30px;
    gap: 30px;
  }
  @media (max-width: 530px) {
    display: none;
  }
`;
const MobileWrap = styled.article`
  display: none;
  @media (max-width: 530px) {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 20px;
  }
`;
const Row = styled.section`
  display: flex;
  flex-direction: row;
  gap: 2vw;
  @media (min-width: 1920px) {
    gap: 30px;
  }
`;

const Container1 = styled.div`
  /* flex: 5; */
  width: 48%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  @media (min-width: 1920px) {
    gap: 30px;
  }
`;
const Container2 = styled.div`
  /* flex: 6; */
  width: 52%;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  @media (min-width: 1920px) {
    gap: 30px;
  }
`;


const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "grid" : "none")};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;


const TitleWithArrowWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.span`
  white-space: nowrap;
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.4vw;
  @media (min-width: 1920px) {
    font-size: 28px;
    line-height: 33px;  
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;  
  }
`;

const Wrapper = styled.section<{ padding?: string; }>`
  padding: ${(props) =>
    props.padding
      ? props.padding
      : theme.commonMargin.slideWrapper};
  display: flex;
  flex-direction: column;
  gap: ${theme.commonMargin.gap2};
  width: calc(100vw - 300px);

  @media (min-width: 1920px) {
    width: calc(1560px);
  }

  @media (max-width: 1000px) {
    width: calc(100vw);
  }
`;
