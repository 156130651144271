import { useWriteContract } from 'wagmi';
import { ERC1155_abi } from '../../json/ERC1155';
import { ERC721_abi } from '../../json/ERC721';

interface DisapprovePrams {
  standard: string;
  abi?: any,
  contractAddress: string;
  tokenId: number;
}

export const useDisapprove = () => {
  const {
    data: disapproveHash,
    error: disapproveError,
    isPending: disapprovePending,
    writeContract
  } = useWriteContract();

  const disapprove = async ({ standard, abi, contractAddress, tokenId }: DisapprovePrams) => {

    const adminAddress = process.env.REACT_APP_ADMIN_WALLET_ADDRESS as `0x{string}`;
    const burnAddress = process.env.REACT_APP_BURN_ADDRESS as `0x{string}`;
    if (!burnAddress) return;

    const type = standard.split('-')[1];

    let _function;
    let _arg: readonly any[] = [];
    // let _abi = abi;
    let _abi;

    switch (type) {
      case '721':
        if (_abi == undefined) _abi = ERC721_abi;
        _function = "approve";
        _arg = [burnAddress, BigInt(tokenId)] as const;
        break;

      case '1155':
        if (_abi == undefined) _abi = ERC1155_abi;
        _function = "setApprovalForAll";
        _arg = [adminAddress, false] as const;
        break;

      case '17':
        if (_abi == undefined) _abi = ERC721_abi;
        _function = "approve";
        _arg = [burnAddress, BigInt(tokenId)] as const;
        break;

      case '37':
        if (_abi == undefined) _abi = ERC1155_abi;
        _function = "setApprovalForAll";
        _arg = [adminAddress, false]  as const;
        break;
    }


    if(_abi === undefined) return;
    if(_function === undefined) return;

    writeContract({
      address: contractAddress as `0x${string}`,
      abi: _abi,
      functionName: _function,
      args: _arg,
      // abi: ERC721_abi,
      // functionName: 'approve',
      // args: [burnAddress, BigInt(tokenId)],
    })
  }

  return {
    disapprove,
    disapproveHash, disapproveError, disapprovePending
  };
};


export default useDisapprove;
