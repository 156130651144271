import { Route, useNavigate, useParams } from 'react-router-dom';
import CategoryListPage from './CategoryListPage';

export const CategoryListPageWrapper = () => {
  const { category } = useParams();
    const navigate = useNavigate();

  const categoryName =
    category === "digital-art"
      ? "Digital Art"
      : category === "sports"
      ? "Sports"
      : category === "collectable"
      ? "Collectable"
      : category === "game"
      ? "Game"
      : '';

  return <CategoryListPage topMenu={"Category"} category={categoryName} />;
};
