import { FaCheck, FaSlidersH, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { selectBoxItem, useList } from '../../../contexts/ListContext';
import { SelectBoxItemProps } from '../../../interface/components/interface';

import { isDesktop } from "react-device-detect";



interface OptionProps {
    selected: boolean;
    onClick: () => void;
  }

  
export const OrderFilterModal = () => {
    const {selectedOption, setSelectedOption, mOrderFilterOpen, setMOrderFilterOpen,} = useList();
    const navigate = useNavigate();

    const handleOptionClick = (item: SelectBoxItemProps) => {
        setSelectedOption(item);
        setMOrderFilterOpen(false);
        
        const params = new URLSearchParams(location.search);
        params.set('order', item.value);
    
        navigate({
          search: params.toString(),
        });
        setMOrderFilterOpen(false);
      };

  if (!mOrderFilterOpen) {
    return null;
  }

  return (
    <Overlay>
      <Popup>
        <Header>
          <FaSlidersH />
          <Title>Order</Title>
          <CloseButton onClick={() => setMOrderFilterOpen(false)}>
            <FaTimes />
          </CloseButton>
        </Header>
        <OptionsList>
          {selectBoxItem.map(option => (
            <Option
              key={option.label}
              onClick={() => handleOptionClick(option)}
              selected={option === selectedOption}
            >
              {option.label}
              {option === selectedOption && <FaCheck />}
            </Option>
          ))}
        </OptionsList>
      </Popup>
    </Overlay>
  );
};


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
`;

const Popup = styled.div`
  position: ${isDesktop ? 'relative' : 'fixed'};
  bottom: 0;
  background: #1c1f24;
  border-radius: ${isDesktop ? '15px' : '15px 15px 0 0'};
  width: ${isDesktop ? '50vw' : '100%'};
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #333;
`;

const Title = styled.h2`
  font-size: 18px;
  margin: 0;
  color: #fff;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
`;

const OptionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Option = styled.li<OptionProps>`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: ${({ selected }) => (selected ? '#2b2f35' : 'none')};
  color: ${({ selected }) => (selected ? '#4caf50' : '#fff')};

  &:hover {
    background: #2b2f35;
  }

  svg {
    color: #4caf50;
  }
`;
