import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ITx } from "../../../interface/components/interface";
import { XAxisTick, YAxisTick } from "./AxisTick";
import "./NFTChart.css";
import CustomTooltip from "./CustomToolTip";

const labelStyle = {
  textAnchor: "middle",
  color: "#555D69",
  fontFamily: "Roboto",
  fontSize: "1.2vw",
};

interface NFTChartProp {
  unit: string;
  data: ITx[];
}


interface GroupedData {
  date: string;
  volume: number;
  totalPrice: number;
  averagePrice?: number;
}


export const NFTChart = ({
  unit, data
}:NFTChartProp) => {

  const groupByDate = (data: ITx[]): { [key: string]: GroupedData } => {
    return data.reduce((acc: { [key: string]: GroupedData }, item: ITx) => {

      const dateObj = new Date(item.tx_date);
      // 날짜만 추출 (시간 부분 제거) - "YYYY-MM-DD" 형식으로 변환
      const date = dateObj.toISOString().split('T')[0];
        
      if (!acc[date]) {
        acc[date] = { date: date, totalPrice: 0, volume: 0 };
      }
      
      acc[date].totalPrice += item.nft_price;
      acc[date].volume += 1;
      
      return acc;
    }, {});
  };
  
  const calculateAverages = (groupedData: { [key: string]: GroupedData }): GroupedData[] => {
    const sortedData = Object.values(groupedData).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    const totalData = sortedData.map(item => ({
      date: item.date,
      totalPrice: item.totalPrice,
      volume: item.volume,
      averagePrice: item.totalPrice / item.volume
    }));

    return totalData.slice(-4);
  };
  
  // const data1 = [
  //   { date: '2024-07-19', totalPrice: 0.1, volume: 1, averagePrice: 0.1 },
  //   { date: '2024-07-21', totalPrice: 0.3, volume: 1, averagePrice: 0.3 },
  // ];

  return (
    <ResponsiveContainer width={"100%"} aspect={3.0 / 1.0} style={{paddingTop: '4vh'}}>
      <ComposedChart
        data={calculateAverages(groupByDate(data))}
        // data={data1}
        margin={{ top: 20, right: 20, left: 15, bottom: 5 }}
      >
        <defs>
          <linearGradient id="colorGradient" x1="0" y1="0" x2="1" y2="0">
            <stop offset="0%" stopColor="#9846FF" />
            <stop offset="50%" stopColor="#6EFFB1" />
            <stop offset="100%" stopColor="#3AC7FF" />
          </linearGradient>
        </defs>
        <CartesianGrid horizontal={true} vertical={false} stroke="#313B4A" />

        <XAxis
          dataKey="date"
          tick={(props) => <XAxisTick {...props} />}
          tickLine={false}
        />
        <YAxis
          axisType="yAxis"
          yAxisId="left"
          width={50}
          domain={[0, (dataMax: number) => (dataMax + dataMax * 0.3).toFixed(3)]}
          stroke="transparent"
          tick={(props) => <YAxisTick {...props} />}
          label={{
            value: `Volume(${unit})`,
            className: 'y-axis',
            angle: -90,
            position: "left",
            style: labelStyle,
          }}
        />
        <YAxis
          axisType="yAxis"
          yAxisId="right"
          width={50}
          orientation="right"
          domain={[0, (dataMax: number) => (dataMax + dataMax * 0.3).toFixed(3)]}
          stroke="transparent"
          tick={(props) => <YAxisTick {...props} />}
          label={{
            value: `Average price(${unit})`,
            className: 'y-axis',
            angle: 90,
            position: "right",
            style: labelStyle,
          }}
        />
        <Tooltip content={<CustomTooltip />}/>
        <Bar
          yAxisId="left"
          type="monotone"
          dataKey="volume"
          stroke="#313B4A"
          fill="#313B4A"
          barSize={"10%"}
        />
        <Line
          yAxisId="right"
          type="monotone"
          dot={false}
          dataKey="averagePrice"
          stroke="url(#colorGradient)"
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
