import React from 'react';
import styled from 'styled-components';
import { FaFilter, FaSlidersH } from 'react-icons/fa'; // FontAwesome 아이콘 사용
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { contentModalState } from '../../../modules/Modal';
import { useList } from '../../../contexts/ListContext';

const ButtonContainer = styled.div`
    display: none;
  @media (max-width: 800px) {
    display: flex;
  gap: 10px;

  }
`;

const IconButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #2c2f33;
  border: none;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #3b3f45;
  }

  svg {
    color: #fff;
    width: 20px;
    height: 20px;
  }
`;

export const MobileFilter = () => {
    const {mOrderFilterOpen, setMOrderFilterOpen,
        mFilterOpen, setMFilterOpen} = useList();

    const contentModal = useSetRecoilState(contentModalState);
    const resetContentModal = useResetRecoilState(contentModalState);
  

  return (
    <ButtonContainer>
      <IconButton  onClick={() => setMFilterOpen(true)}>
        <FaFilter
        />
      </IconButton>
      <IconButton onClick={() => setMOrderFilterOpen(true)}>
        <FaSlidersH />
      </IconButton>
    </ButtonContainer>
  );
};
