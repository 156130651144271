import React, {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useState,
} from "react";
  
  interface SidebarContextType {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
  }
  
  // 컨텍스트의 기본값 정의
  const defaultValue: boolean = false;
  
  // 컨텍스트 생성
  const SidebarContext = createContext<SidebarContextType>({
    open: false,
    setOpen: () => {},
  });
  
  // 컨텍스트 제공자 컴포넌트
  export const SidebarProvider = ({ children }: { children: ReactNode }) => {
    const [open, setOpen] = useState<boolean>(false);
  
    React.useEffect(() => {
      if (open) {
        document.body.style.overflow = "hidden"; // 모달 창 오픈 시 스크롤 불가
      } else {
        document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
      }
    }, [open]);
  
    return (
      <SidebarContext.Provider value={{ open, setOpen }}>
        {open && (
          <div className="load-modal-container" style={{zIndex: 9999}}>
            <div className="modal-container">
              <div className="modal-flex">
              </div>
            </div>
          </div>
        )}
        {children}
      </SidebarContext.Provider>
    );
  };
  
  // 컨텍스트 훅
  export const useSidebar = () => useContext(SidebarContext);
  