import React from 'react'
import { useReadContract, type UseReadContractParameters } from 'wagmi';
import { ERC721_abi } from '../../json/ERC721';
import { ethers } from 'ethers';
import { chainList } from '../../controller/chainId';

interface useOwnerOfOtherChainParams {
  checkAddress: string;
  standard: string;
  chainId: number;
  contractAddress: string;
  tokenId: number;
}

interface useOwnerOfOnChainParams {
  abi?: any;
  contractAddress: string;
  tokenId: number;
}

export const useOwner = () => {
  const ownerOfOtherChain = async ({ checkAddress, standard, chainId, contractAddress, tokenId }: useOwnerOfOtherChainParams) => {
    try {

      const rpcUrl = chainList[chainId]?.rpcUrls[0];
      if (!rpcUrl) {
        throw new Error(`No RPC URL found for chain ID ${chainId}`);
      }
      
      const type = standard.split('-')[1];
  
      const otherProvider = new ethers.providers.JsonRpcProvider(rpcUrl);
      otherProvider.on("network", (newNetwork, oldNetwork) => {
        console.log("Network changed from", oldNetwork, "to", newNetwork);
      });
  
      otherProvider.on("error", (error) => {
        console.error("Provider error:", error);
      });

      let network = await otherProvider.getNetwork();
      while (network.chainId === null) {
        console.log("Waiting for network to be set...");
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1초 대기
        network = await otherProvider.getNetwork();
      }
  
      if(type === '721' || type === '17'){
        console.log('0')
        const contract = new ethers.Contract(contractAddress, [
          'function ownerOf(uint256 tokenId) external view returns (address)'
        ], otherProvider);
        console.log('1')
        const owner = await contract.ownerOf(tokenId);
        console.log(owner)
        const checksumOwner = ethers.utils.getAddress(owner);
        return checksumOwner;
      }
    
      if(type === '1155' || type === '37'){
        const contract = new ethers.Contract(contractAddress, [
          'function balanceOf(address account, uint256 id) view returns (uint256)'
        ], otherProvider);
        const balance = await contract.balanceOf(checkAddress, tokenId);
        if(balance > 0){
          return checkAddress;
        }
        return '';
      }
      
    } catch (err) {
      console.error('Error fetching owner of token:', {
        checkAddress,
        standard,
        chainId,
        contractAddress,
        tokenId,
        fullError: err
      });
      return null;
    }
  }


  return {
    ownerOfOtherChain,
  }
}

