import React from 'react';
import styled from 'styled-components';
import logoImage from '../../assets/logo/logo.png'
import { FaXTwitter, FaInstagram, FaTelegram, FaEnvelope } from 'react-icons/fa6';

const FooterWrapper = styled.footer`
    background-color: #181F29;
    color: #fff;
    padding: 20px;
    text-align: center;
`;

const SocialIcons = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;
`;

const IconLink = styled.a`
    color: #fff;
    font-size: 24px;
    &:hover {
        color: #ccc;
    }
`;

const Description = styled.p`
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 5px;
`;

const Logo = styled.h1`
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 8px;
`;

const Head = styled.h1`
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 15px;
`;

const LogoLink = styled.a`
    display: inline-block;
`;

const LogoImage = styled.img`
    margin-bottom: 10px;
    width: 150px;
    height: auto;
`;

const EmailInfo = styled.div`
    font-size: 16px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const EmailText = styled.span`
  font-weight: bold;
`;

const Footer = () => {
	return (
		<FooterWrapper>
			{/*<Head>ContactUs</Head>*/}
			<SocialIcons>
				<IconLink href="https://x.com/blings254642159/"><FaXTwitter/></IconLink>
				<IconLink href="https://www.instagram.com/blings710815/"><FaInstagram/></IconLink>
				<IconLink href="https://t.me/BlingsTelegram/"><FaTelegram/></IconLink>
				<IconLink href="mailto:info@blings.art"><FaEnvelope /></IconLink>
			</SocialIcons>
			<Description>
				Blings is a multi-chain marketplace where NFTs from various blockchains can be traded using different coins. It supports Ethereum, Polygon, Binance Smart Chain, and Klaytn, enhancing NFT market accessibility through cross-chain transactions. Even non-technical users can easily download original NFT files. Blings focuses on expanding supported blockchains and improving user experience.
			</Description>
			<Logo>Blings</Logo>
			<EmailInfo>
				<EmailText>Email:</EmailText> info@blings.art
			</EmailInfo>
			{/*<LogoLink href={"https://blings.art/"}>*/}
			{/*	<LogoImage src={logoImage} alt="Blings Logo" />*/}
			{/*</LogoLink>*/}
		</FooterWrapper>
	);
};

export default Footer;
