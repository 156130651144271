import React, { ReactNode } from 'react'
import { MoreInfo } from '../MoreInfo'
import { NFTInfoItemProps } from '../../../interface/components/interface';
import { addComma, removeComma } from '../../../utils/Comma';
import { useList } from '../../../contexts/ListContext';


interface NFTInfoProps {
    items: NFTInfoItemProps[];
}

export const NFTInfo = ({
    items
}: NFTInfoProps) => {

  return (
    <div>
    {items.map((item, index) => {
        return <MoreInfo key={`FILLTER-${index}`} title={item.title} radius={item.radius} topRadius={item.topRadius} bottomRadius={item.bottomRadius} openState={item.openState}>{item.child}</MoreInfo>
    })}
    
    </div>
  )
}
