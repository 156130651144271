import { useRecoilValue, useResetRecoilState } from "recoil";
import styled from "styled-components";
import theme from "../../../theme";
import { contentModalState } from "../../../modules/Modal";
import Overlay from "./Overlay";
import BigButton from "../../Button/BigButton";
import React, { useEffect } from 'react';


export default function ContentModal() {
  const contentModal = useRecoilValue(contentModalState);
  const resetModal = useResetRecoilState(contentModalState);

    // 키보드 동작 막기
    useEffect(() => {
      const a = (e: Event) => {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden"; // 모달 창 오픈 시 스크롤 불가
      return () => {
        document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
      };
    }, []);

    
  const onReset = () => {
    if (contentModal.onClose) contentModal.onClose();
    resetModal();
  };


  return (
    <Overlay>
      <ContentModalContainer>
        <h1>{contentModal?.title}</h1>
        <div className="subText">{contentModal.subText}</div>
        <div className="content">{contentModal.content}</div>
        {contentModal.onClick && !contentModal.type && (
          <div className="buttonContainer">
            <BigButton text={contentModal.cancelText ?? "Cancel"} onClick={onReset} white />
            <BigButton text={contentModal.buttonText || ""} onClick={contentModal.onClick} />
          </div>
        )}
        {contentModal.type !== "cancel" && contentModal.type && (
          <div className="buttonContainer">
            <BigButton text="Ok" onClick={onReset} />
          </div>
        )}
        {contentModal.type === "cancel" && (
          <div className="buttonContainer">
            <BigButton text={contentModal.cancelText ?? "Cancel"} onClick={onReset} />
          </div>
        )}
      </ContentModalContainer>
    </Overlay>
  );
}

const ContentModalContainer = styled.div`
  background-color: #1B1C29;
  padding: 40px;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 00;
  border-radius: 22px;
  h1 {
    font-size: 20px;
    font-weight: 600;
    /* color: ${theme.colors.body2}; */
    color: #fff;
  }
  
  .subText {
    font-weight: 500;
    font-size: 14px;
    margin-top: 16px;
    white-space: pre-wrap;
    color: ${theme.colors.body};
  }
  .content {
    align-self: center;
  }
  .buttonContainer {
    display: flex;
    gap: 24px;
    margin-top: 1.5vw;
  }
  @media (max-width: 610px) {
    width: 80%;
    height: auto;
    padding: 20px 0px;
    display: flex;
    gap: 10px;
  }
`;
