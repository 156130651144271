import styled from "styled-components";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import { chainListArray } from "../../../controller/chainId";
import { RegisterSearchPageProps } from "../../../interface/view/interface";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import React from "react";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../../modules/Modal";
import { ADMIN_PATH } from "../../../routers/GlobalRoute";

export const RegisterSearchPage = (props: RegisterSearchPageProps) => {

  const navigate = useNavigate();
  const alertModal = useSetRecoilState(alertModalState);

  const [network, setNetWork] = React.useState<string>(chainListArray[0].value);
  const [contract, setContract] = React.useState<string>('');
  const [tokenId, setTokenId] = React.useState<string>('');
  
  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if(contract === ''){
      alertModal({
        isModal: true,
        type: 'error',
        content: "컨트렉트를 입력해주세요.",
      });
      return;
    }

    navigate(`${ADMIN_PATH}/register/search/list?network=${network}&contract=${contract}${tokenId === '' ? '' : `&tokenId=${tokenId}`}`)
  }

  return (
    <AdminLayout {...props}>
    <FormContainer>
      <Form onSubmit={onSubmit}>
        <FormRow>
          <Label>네트워크</Label>
          <Select  onChange={(e) => setNetWork(e.target.value)}>
            {chainListArray.map((chain) => {
              return(
                <option value={chain.value}>{chain.ko}</option>    
              )
            })}
          </Select>
        </FormRow>
        <FormRow>
          <Label>스마트 컨트랙트</Label>
          <Input type="text" onChange={e => setContract(e.target.value)}/>
        </FormRow>
        <FormRow>
          <Label>토큰 ID</Label>
          <Input type="number" onChange={e => setTokenId(e.target.value)}/>
        </FormRow>
        <ButtonRow>
          <SubmitButton type="submit">검색하기</SubmitButton>
        </ButtonRow>
      </Form>
    </FormContainer>
    </AdminLayout>
  );
};

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Form = styled.form`
  background: ${theme.colors.blueGray1};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  flex: 1;
  text-align: start;
  width: 150px;
`;

const Input = styled.input`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const Select = styled.select`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonRow = styled(FormRow)`
  justify-content: flex-end;
`;


const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: ${theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  

  &:hover {
    background-color: ${theme.colors.side};
  }
`;
