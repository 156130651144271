import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext
} from "react";

import userApi from "../api/userApi";
import useInterval from "../hooks/useInterval";

declare global {
  interface Window {
    klaytn: any;
  }
}
interface StandardContextType {
  standard: string;
  setStandard: Dispatch<SetStateAction<string>>;
}


// 컨텍스트 생성
const StandardContext = createContext<StandardContextType>({
  standard: '',
  setStandard: () => {},
});

// 컨텍스트 제공자 컴포넌트
export const StandardProvider = ({ children }: { children: ReactNode }) => {
  const [standard, setStandard] = React.useState<string>('');
  return (
    <StandardContext.Provider
      value={{
        standard,
        setStandard
      }}
    >
      {children}
    </StandardContext.Provider>
  );
};

// 컨텍스트 훅
export const useStandard = () => useContext(StandardContext);
