import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext
} from "react";
import { useLocation } from "react-router-dom";
import {
  selectedViewOptionType,
  viewType1,
  viewType2,
} from "../components/Button/ListStyleButton";
import { useResizingHandler } from "../hooks/useResizingHandler";
import {
  Iquery,
  NFTItemProps,
  SelectBoxItemProps,
} from "../interface/components/interface";

interface ListContextType {
  selectedViewOption: selectedViewOptionType;
  setSelectedViewOption: Dispatch<SetStateAction<selectedViewOptionType>>;

  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;

  size: number;
  setSize: Dispatch<SetStateAction<number>>;

  data: NFTItemProps[];
  setData: Dispatch<SetStateAction<NFTItemProps[]>>;

  hasMore: boolean;
  setHasMore: Dispatch<SetStateAction<boolean>>;

  // category: string;
  // setCategory: Dispatch<SetStateAction<string>>;

  search: string;
  setSearch: Dispatch<SetStateAction<string>>;

  selectedOption: SelectBoxItemProps;
  setSelectedOption: Dispatch<SetStateAction<SelectBoxItemProps>>;

  buyNow: boolean;
  setBuyNow: Dispatch<SetStateAction<boolean>>;

  waitApprove: boolean;
  setWaitApprove: Dispatch<SetStateAction<boolean>>;

  minPrice: string;
  setMinPrice: Dispatch<SetStateAction<string>>;

  maxPrice: string;
  setMaxPrice: Dispatch<SetStateAction<string>>;

  query: Iquery;
  setQuery: Dispatch<SetStateAction<Iquery>>;

  total: number;
  setTotal: Dispatch<SetStateAction<number>>;

  mOrderFilterOpen: boolean;
  setMOrderFilterOpen: Dispatch<SetStateAction<boolean>>;

  mFilterOpen: boolean;
  setMFilterOpen: Dispatch<SetStateAction<boolean>>;
}

export const selectBoxItem: SelectBoxItemProps[] = [
  { value: "pkDesc", label: "Newest" },
  { value: "pkAsc", label: "Oldest" },
  { value: "priceAsc", label: "Price: Low to High" },
  { value: "priceDesc", label: "Price: High to Low" },
];

// 컨텍스트 생성
const ListContext = createContext<ListContextType>({
  selectedViewOption: { grid: true, row: 3 },
  setSelectedViewOption: () => {},
  currentPage: 1,
  setCurrentPage: () => {},
  size: 12,
  setSize: () => {},
  data: [],
  setData: () => {},
  hasMore: true,
  setHasMore: () => {},
  // category: "",
  // setCategory: () => {},
  search: "",
  setSearch: () => {},
  selectedOption: selectBoxItem[0],
  setSelectedOption: () => {},
  buyNow: false,
  setBuyNow: () => {},
  waitApprove: false,
  setWaitApprove: () => {},
  minPrice: "",
  setMinPrice: () => {},
  maxPrice: "",
  setMaxPrice: () => {},
  query: {},
  setQuery: () => {},
  total: 0,
  setTotal: () => {},
  mOrderFilterOpen: false,
   setMOrderFilterOpen: () => {},
    mFilterOpen: false, 
    setMFilterOpen: () => {}
});
// 컨텍스트 제공자 컴포넌트
export const ListProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();

  // nft 보여줄 화면 모양
  const [selectedViewOption, setSelectedViewOption] =
    React.useState<selectedViewOptionType>(viewType2);
  // 페이징
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [size, setSize] = React.useState<number>(12);
  const [data, setData] = React.useState<NFTItemProps[]>([]);
  const [hasMore, setHasMore] = React.useState<boolean>(true);
  // 카테고리
  // const [category, setCategory] = React.useState<string>("");
  // 검색
  const [search, setSearch] = React.useState<string>("");
  // order
  const [selectedOption, setSelectedOption] =
    React.useState<SelectBoxItemProps>(selectBoxItem[0]);
  // checkbox 필터
  const [buyNow, setBuyNow] = React.useState<boolean>(false);
  const [waitApprove, setWaitApprove] = React.useState<boolean>(false);
  // 가격 필터
  const [minPrice, setMinPrice] = React.useState<string>("");
  const [maxPrice, setMaxPrice] = React.useState<string>("");

  // 모바일 정렬필터
  const [mOrderFilterOpen, setMOrderFilterOpen] = React.useState<boolean>(false);
  // 모바일 필터
  const [mFilterOpen, setMFilterOpen] = React.useState<boolean>(false);

  // 검색 토탈 갯수
  const [total, setTotal] = React.useState<number>(0);

  // 최종 쿼리 조합본
  const [query, setQuery] = React.useState<Iquery>({});


  const handler = () => {
    if (window.innerWidth <= 680) {
      setSelectedViewOption(viewType1);
    }
  }
  useResizingHandler(handler);

  return (
    <ListContext.Provider
      value={{
        selectedViewOption,
        setSelectedViewOption,
        currentPage,
        setCurrentPage,
        size,
        setSize,
        data,
        setData,
        hasMore,
        setHasMore,
        // category,
        // setCategory,
        search,
        setSearch,
        selectedOption,
        setSelectedOption,
        buyNow,
        setBuyNow,
        waitApprove,
        setWaitApprove,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        query,
        setQuery,
        total, setTotal,
        mOrderFilterOpen, setMOrderFilterOpen,
        mFilterOpen, setMFilterOpen
      }}
    >
      {children}
    </ListContext.Provider>
  );
};

// 컨텍스트 훅
export const useList = () => useContext(ListContext);
