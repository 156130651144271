import { Provider } from "react-redux";
import { useRecoilValue } from "recoil";
import "./App.css";
import AlertModal from "./components/Modal/Common/AlertModal";
import ContentModal from "./components/Modal/Common/ContentModal";
import LoadingModal from "./components/Modal/Common/LoadingModal";
import { ListProvider } from "./contexts/ListContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { WalletProvider } from "./contexts/WalletContext";
import { useScrollToTop } from "./hooks/useScrollToTop";
import {
  alertModalState,
  contentModalState,
  loadingModalState,
} from "./modules/Modal";
import { store } from "./redux/Store";
import GlobalRoute from "./routers/GlobalRoute";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./wagmi.config";

const queryClient = new QueryClient();

import ReactGA from 'react-ga4'
import {useEffect} from "react";
import {useLocation} from "react-router-dom";

function App() {
  // 페이지 이동시, 맨 위페이지로 이동
  useScrollToTop();

  const alertModal = useRecoilValue(alertModalState);
  const contentModal = useRecoilValue(contentModalState);
  const loadingModal = useRecoilValue(loadingModalState);
  const location = useLocation();

  console.log("location : ", location)

  useEffect(() => {
    ReactGA.initialize(`${process.env.GA_ID}`)
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
      location: window.location.href,
    })
  }, [location]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ListProvider>
            <WalletProvider>
              {/* 전역 ALert Modal atom으로 관리됨 */}
              {contentModal.isModal && <ContentModal />}
              {alertModal.isModal && <AlertModal />}
              {loadingModal.isModal && <LoadingModal />}
              <SidebarProvider>
                <GlobalRoute />
              </SidebarProvider>
            </WalletProvider>
          </ListProvider>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
