export const addComma = (price: string | number) => {
  if(price === '') return ''

  let stringToNum = price.toString().replace(/[^0-9.]/g, '');;

  const firstDotIndex = stringToNum.indexOf('.');
  if (firstDotIndex !== -1) {
    stringToNum = stringToNum.slice(0, firstDotIndex + 1) + stringToNum.slice(firstDotIndex + 1).replace(/\./g, '');
  }


    const findDot = stringToNum?.toString().indexOf('.');
    const priceLength = stringToNum?.toString().length;

    let num, decimal;
    // let decimal = stringToNum?.toString().substring(findDot, priceLength)
    if( findDot === -1){
      num = Number(stringToNum.toString())
      decimal = ''
    }else{ 
      num = Number(stringToNum?.toString().substring(0,findDot))
      decimal = stringToNum?.toString().substring(findDot, priceLength);
    }

    const returnNum = num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const returnString = returnNum + decimal;
    return returnString;
  }

  
  export const setComma = (price: string | number) => {
    if (price.toString() === '-'){
      return price;
    }

    return addComma(price);
  };
  
  export const onChangePoints = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let str = value.replaceAll(',', '');
    return str;
  };

  export const removeComma = (temp: string | undefined) => {
    
    if(temp === undefined){
      return '';
    }

    let str = temp.replaceAll(',', '');
    return str;
  };
  
  