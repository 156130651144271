import styled from "styled-components";
import { AdminLayout } from "../../../components/Layout/AdminLayout";
import { AdminMainPageProps } from "../../../interface/view/interface";
import theme from "../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { clear } from "../../../redux/slice/UserSlice";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, loadingModalState } from "../../../modules/Modal";
import { useNavigate } from "react-router-dom";
import { RootReducerState } from "../../../redux/RootReducer";
import React from "react";
import { addComma, removeComma } from "../../../utils/Comma";
import adminApi from "../../../api/adminApi";
import { setFee } from "../../../redux/slice/SettingSlice";

export const AdminMainPage = (props: AdminMainPageProps) => {
  const user = useSelector((state: RootReducerState) => state.user);

  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);
const alertModal = useSetRecoilState(alertModalState);
const setting = useSelector((state: RootReducerState) => state.setting);

const [fee1, setFee1] = React.useState<string>(setting.fee);
const dispatch = useDispatch();

const onSubmit = async (event: React.FormEvent) => {
  event.preventDefault();
  if (fee1 === '') {
    alertModal({
      isModal: true,
      title: '오류',
      content: "수수료를 입력해주세요.",
    });
    return;
  }
    
  loadingModal({
    isModal: true,
    isButton: false,
    type: "default",
    subText: `저장중입니다.`
  });


  const res = await adminApi.setSetting(user.token, (Number(fee1) / 100).toString());

  resetLoadingModal()
  if(res.status !== 200){
    alertModal({
      isModal: true,
      title: '오류',
      content: "현 상황이 지속된다면,\n개발자에게 문의해주세요.",
    });
  }
  alertModal({
    isModal: true,
    title: '성공',
    content: "저장 완료되었습니다.",
    type: 'check',
  });
  dispatch(setFee(fee1.toString()))
  };


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = removeComma(rawValue).toString();
    setFee1(addComma(formattedValue));
  };

  return (
    <AdminLayout {...props}>
        <FormContainer>
      <Form onSubmit={onSubmit}>
        <FormRow>
          <Label>수수료</Label>
          <InputWrapper>
            <Input
              type="text"
              value={fee1}
              onChange={handleChange}
            />
            <Unit>%</Unit>
          </InputWrapper>
        </FormRow>
        <ButtonRow>
          <SubmitButton type="submit">수정하기</SubmitButton>
        </ButtonRow>
      </Form>
    </FormContainer>
    </AdminLayout>
  );
};


const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Form = styled.form`
  background: ${theme.colors.blueGray1};
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  flex: 1;
  text-align: start;
  width: 150px;
`;


const Input = styled.input`
flex: 2;
padding: 8px;
border: 1px solid #ddd;
border-radius: 4px;
`;
const InputWrapper = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  align-items: center;
`;

const Unit = styled.span`
  position: absolute;
  right: 10px; // input 요소의 오른쪽에 단위를 배치
  color: #000;
  font-weight: bold;
  font-size: 14px;
`;


const Select = styled.select`
  flex: 2;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const ButtonRow = styled(FormRow)`
  justify-content: flex-end;
`;


const SubmitButton = styled.button`
  padding: 12px 24px;
  background-color: ${theme.colors.secondary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  

  &:hover {
    background-color: ${theme.colors.side};
  }
`;
