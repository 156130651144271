import React from "react";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import { useWallet } from "../../contexts/WalletContext";
import { alertModalState } from "../../modules/Modal";
import theme from "../../theme";
import { assets } from "../../theme/assets";
import { HambergerMenu } from "../Button/HambergerMenu";
import { ADMIN_PATH } from "../../routers/GlobalRoute";

interface HeaderProps {
}
function AdminHeader(props: HeaderProps) {
  const navigate = useNavigate();
  const { openModal } = useWallet();
  const { address } = useAccount();
  const alertModal = useSetRecoilState(alertModalState);

  const [open, setOpen] = React.useState<boolean>(false);

  const personal = () => {
    if(address === undefined){
      alertModal({
        isModal: true,
        type: 'check',
        content: "Please connect wallet first.",
        onClick() {
          openModal()
        },
      });
      return 
    }
    navigate('/profile');      
  }

  return (
    <MainHeader>
      <HeaderWrap>
      <HeaderLeftNav>
      <Logo src={assets.logo} onClick={() => navigate("/")} />
        <HeaderSpan onClick={() => navigate(`${ADMIN_PATH}/setting`)}>설정</HeaderSpan>
        <HeaderSpan>|</HeaderSpan>
        <HeaderSpan onClick={() => navigate(`${ADMIN_PATH}/contract`)}>Contract 등록</HeaderSpan>
        <HeaderSpan>|</HeaderSpan>
        <HeaderSpan onClick={() => navigate(`${ADMIN_PATH}/register/search`)}>NFT 등록</HeaderSpan>
        <HeaderSpan>|</HeaderSpan>
        <HeaderSpan onClick={() => navigate(`${ADMIN_PATH}/management`)}>NFT 관리</HeaderSpan>
      </HeaderLeftNav>
      </HeaderWrap>
    </MainHeader>
  );
}


const MainHeader = styled.div`
  background: ${theme.colors.dark};
  height: fit-content;
  display: none;
  justify-content: center;
  z-index: 10002;
  @media (max-width: 1000px) {
    display: flex;
  }
`;

const HeaderWrap = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 25px;
  /* max-width: ${theme.commonWidth.maxWidth}; */
`;

const HeaderLeftNav = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  gap: ${theme.commonMargin.gap1};
  width: 100%;
  height: 100%;
`;

const HeaderSpan = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-size: ${theme.fontType.header.fontSize};
  font-weight: ${theme.fontType.header.bold};
  /* line-height: ${theme.fontType.header.lineHeight}; */
  cursor: pointer;
`;


const Logo = styled.img`
  display: block;
  height: 30px;
`;


export default AdminHeader;
