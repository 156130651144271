import React from "react";
import styled from "styled-components";
import theme from "../../../theme";
import { Text } from "recharts";

interface AxisTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

export const XAxisTick = ({
    x, y, payload
}: AxisTickProps) => {
    return (
        <Tick x={x} y={y} dy={16} textAnchor="middle" fill="#fff">
          {payload.value}
        </Tick>
      );
};

export const YAxisTick = ({
    x, y, payload
}: AxisTickProps) => {
    return (
        <Tick x={x} y={y} textAnchor="middle" fill="#fff">
          {payload.value}
        </Tick>
      );
};

const Tick = styled(Text)`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1.1vw;
  line-height: 1.2vw;

  @media (min-width: 1920px) {
    font-size: 18px;
  }

  @media (max-width: 680px) {
    font-size: 12px;
  }
`;
