import React from 'react';
import { ethers } from 'ethers';
import { chainList } from '../../controller/chainId';

interface useSymbolOfOtherChainParams {
  chainId: number;
  contractAddress: string;
}

export const useSymbol = () => {
  const symbolOfNFT = async ({ chainId, contractAddress }: useSymbolOfOtherChainParams) => {
    try {
      const rpcUrl = chainList[chainId]?.rpcUrls[0];
      if (!rpcUrl) {
        throw new Error(`No RPC URL found for chain ID ${chainId}`);
      }

      const otherProvider = new ethers.providers.JsonRpcProvider(rpcUrl);

      // otherProvider.on("network", (newNetwork, oldNetwork) => {
      //   console.log("Network changed from", oldNetwork, "to", newNetwork);
      // });

      // otherProvider.on("error", (error) => {
      //   console.error("Provider error:", error);
      // });

      let network = await otherProvider.getNetwork();
      while (network.chainId === null) {
        // console.log("Waiting for network to be set...");
        await new Promise(resolve => setTimeout(resolve, 1000)); // 1초 대기
        network = await otherProvider.getNetwork();
      }

      const contract = new ethers.Contract(contractAddress, [
        'function symbol() external view returns (string)'
      ], otherProvider);

      const symbol = await contract.symbol();
      return symbol;

    } catch (err) {
      console.error('Error fetching symbol of token:', {
        chainId,
        contractAddress,
        fullError: err
      });
      return null;
    }
  }

  return {
    symbolOfNFT,
  }
}
