import React, { forwardRef, useMemo } from "react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import './Table.styles'
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import { NFTItemProps } from "../../interface/components/interface";

interface TableProps {
  COLUMNS: Array<Column<object>>;
  DATA: NFTItemProps[];
  total: number;
}
export const NFTTable = forwardRef<HTMLTableRowElement, TableProps>(({
  COLUMNS,
  DATA,
  total,}, ref) => {

  const memoColumns = useMemo(() => COLUMNS, [COLUMNS]);
  const memoData = useMemo(() => DATA, [DATA]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups,
    page,
    state,
  } = useTable(
    {
      columns: memoColumns,
      data: memoData,
      initialState: {
        pageSize: 5,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize } = state;
  const navigate = useNavigate();
  return (
    <TableContainer className="table-container">
    <StyledTable {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <TableHeader {...column.getHeaderProps()}>{column.render('Header')}</TableHeader>
            ))}
          </TableRow>
        ))}
      </thead>
        <tbody {...getTableBodyProps()}>
      {page.length > 0 ? (
         rows.map((row, index) => {
          prepareRow(row);
          return (
            <TableRow {...row.getRowProps()} ref={index === memoData.length -1 ? ref : null}>
              {row.cells.map(cell => (
                <TableData {...cell.getCellProps()}
                // @ts-ignore
                onClick={() => navigate(`/assets/nft/${row.original.tokenAddress}/${row.original.tokenId}/${row.original.pk}`)}>
                  {cell.render('Cell')}</TableData>
              ))}
            </TableRow>
          );
        })
        ): (
          <TableRow>
             <TableData colSpan={memoColumns.length} style={{ textAlign: 'center', color: theme.colors.white }}>
                No Data
              </TableData>
          </TableRow>
        )}
       </tbody>
    </StyledTable>
    </TableContainer>
  );
});

const TotalContainer = styled.div`
  margin-bottom: 10px; 
`;

const Total = styled.span`
  color: ${theme.colors.tableHeader};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 500;
  font-size: 1vw;
  line-height: 1.2vw;
  text-align: start;
  width: 100%;
  padding: 10px;
  @media (min-width: 1920px) {
    font-size: 23px;
    line-height: 26px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  /* overflow-y: auto;
  max-height: 80vh; */
  border-radius: 16px 16px 0 0;
  border: 1px solid ${theme.colors.secondary};
`;

const StyledTable = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${theme.colors.gray2}; /* 호버 시 배경색 변경 */
  }

`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: ${theme.colors.secondary};
  position: sticky;
  top: 0;
`;

const TableData = styled.td`
  vertical-align: middle;
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid ${theme.colors.secondary};
`;
