type ChainInfo = {
  chainName: string,
  symbol: string,
  ko: string,
  chainId: number,
  network: string,
  order: number,
  value: string,
  host: string,
  rpcUrls: string[],
  nativeCurrency: { name: string, symbol: string, decimals: number };
  blockExplorerUrls: string[]
};
export const chainList: { [key: string]: ChainInfo } = process.env.REACT_APP_TYPE === "DEV" ? {

  // 테스트넷
  11155111: {
    chainName: 'Ethereum Sepolia Testnet',
    symbol: 'tETH',
    ko: '이더리움 테스테넷',
    network: 'ethereum',
    chainId: 11155111,
    order: 5,
    value: 'ethereum',
    host: 'https://sepolia.etherscan.io',
    rpcUrls: ['https://eth-sepolia.public.blastapi.io', 'https://1rpc.io/sepolia'],
    nativeCurrency: {
      name: 'Sepolia Ether',
      symbol: 'tETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://sepolia.etherscan.io']
  },
  97: {
    chainName: 'Binance Smart Chain Testnet',
    symbol: 'tBNB',
    ko: '바이낸스 테스테넷',
    network: 'binance',
    chainId: 97,
    order: 6,
    value: 'bnb',
    host: 'https://testnet.bscscan.com',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'tBNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://testnet.bscscan.com']
  },
  80002: {
    chainName: 'Polygon Amoy Testnet',
    symbol: 'tMATIC',
    ko: '폴리곤 테스테넷',
    network: 'polygon',
    chainId: 80002,
    order: 7,
    value: 'polygon',
    host: 'https://amoy.polygonscan.com',
    rpcUrls: ['https://rpc.ankr.com/polygon_amoy'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'tMATIC',
      decimals: 18
    },
    blockExplorerUrls: ['https://amoy.polygonscan.com']
  },
  1001: {
    chainName: 'Klaytn Testnet Baobab',
    symbol: 'tKLAY',
    ko: '클레이튼 테스테넷',
    chainId: 1001,
    network: 'klaytn',
    order: 8,
    value: 'klaytn',
    host: 'https://baobab.scope.klaytn.com',
    rpcUrls: ['https://api.baobab.klaytn.net:8651'],
    nativeCurrency: {
      name: 'KLAY',
      symbol: 'tKLAY',
      decimals: 18
    },
    blockExplorerUrls: ['https://baobab.scope.klaytn.com']
  },
} : {
  // 메인넷
  1: {
    chainName: 'Ethereum Mainnet',
    symbol: 'ETH',
    ko: '이더리움',
    network: 'ethereum',
    chainId: 1,
    order: 1,
    value: 'ethereum',
    host: 'https://etherscan.io',
    rpcUrls: ['https://1rpc.io/eth', 'https://eth.llamarpc.com'],
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18
    },
    blockExplorerUrls: ['https://etherscan.io']
  },
  56: {
    chainName: 'Binance Smart Chain',
    symbol: 'BNB',
    ko: '바이낸스',
    network: 'binance',
    chainId: 56,
    order: 2,
    value: 'bnb',
    host: 'https://bscscan.com',
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
    nativeCurrency: {
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18
    },
    blockExplorerUrls: ['https://bscscan.com']
  },
  137: {
    chainName: 'Polygon Mainnet',
    symbol: 'MATIC',
    ko: '폴리곤',
    network: 'polygon',
    chainId: 137,
    order: 3,
    value: 'polygon',
    host: 'https://polygonscan.com',
    rpcUrls: ['https://rpc-mainnet.matic.network', 'https://matic-mainnet.chainstacklabs.com'],
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
    },
    blockExplorerUrls: ['https://polygonscan.com']
  },
  8217: {
    chainName: 'Klaytn Mainnet',
    symbol: 'KLAY',
    ko: '클레이튼',
    chainId: 8217,
    network: 'klaytn',
    order: 4,
    value: 'klaytn',
    host: 'https://scope.klaytn.com',
    rpcUrls: ['https://public-node-api.klaytnapi.com/v1/cypress'],
    nativeCurrency: {
      name: 'KLAY',
      symbol: 'KLAY',
      decimals: 18
    },
    blockExplorerUrls: ['https://scope.klaytn.com']
  },
};


const getOrderedChainListValues = (obj: { [key: string]: ChainInfo }): ChainInfo[] => {
  return Object.values(obj).sort((a, b) => a.order - b.order);
};

export const chainListArray = getOrderedChainListValues(chainList);
