import { createSlice } from "@reduxjs/toolkit";

// 관리해야하는 Slice
const initialState = {
    fee: '',
}

/**
 * 관리할 상태를 지정합니다.
 */
export const SettingSlice = createSlice({
    name: 'Setting',
    initialState,
    reducers: {
        setFee(state, action) {
            state.fee = action.payload;
        },
    }
});

export const { setFee } = SettingSlice.actions

export default SettingSlice.reducer