import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { LayoutProps } from "../../interface/components/interface";
import { RootReducerState } from "../../redux/RootReducer";
import { SideBar } from "../SideBar/SideBar";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../modules/Modal";
import { clear } from "../../redux/slice/UserSlice";
import AdminHeader from "../Header/AdminHeader";
import { ADMIN_PATH } from "../../routers/GlobalRoute";

export const AdminLayout = (props: LayoutProps) => {
  const user = useSelector((state: RootReducerState) => state.user);
  const alertModal = useSetRecoilState(alertModalState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parseJwt = (token: string) => {
    const base64Payload = token.split('.')[1]; //value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
    const payload = base64Payload === undefined ? '' : Buffer.from(base64Payload.toString(), 'base64').toString('utf8');
    const result = base64Payload === undefined ? {} : JSON.parse(payload.toString())
    return result;
  };
  

  React.useEffect(() => {
    if(!user.isAdmin){
      alertModal({
        isModal: true,
        title: '오류',
        content: "어드민만 접속 가능합니다.",
        type: 'check',
        onClick(){
          navigate(-1);
        }
      });
      return
    }

    if(parseJwt(user.token).exp < Math.ceil(Date.now() / 1000)){
      dispatch(clear());
      
      alertModal({
        isModal: true,
        title: '오류',
        content: "다시 로그인 해주세요.",
        type: 'check',
        onClick() {
          navigate(`${ADMIN_PATH}`, {replace: true})
        }
      });
      return
    }

  },[])

  return (
    <LayoutSidebar>
      <SideBar {...props} />
      <LayoutHeaderWithBody>
        <AdminHeader />
        <ChildBox>{props.children}</ChildBox>
      </LayoutHeaderWithBody>
    </LayoutSidebar>
  );
};

const LayoutSidebar = styled.div`
  display: flex;
  margin: 0 auto;
  min-height: 100vh;
  max-width: 100vw;
  @media (max-width: 1000px) {
    /* position: fixed; */
    z-index: 99999;
  }
  @media (min-width: 1920px) {
      width: 1920px;
  }
`;

const LayoutHeaderWithBody = styled.div`
  display: flex;
  flex-direction: column;
  /* flex: auto; */
`;

const ChildBox = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 100;
  margin-bottom: 3vh;
  width: calc(100vw - 300px);
  /* height: 100%;
  min-height: 100vh; */
  @media (min-width: 1920px) {
    width: 1560px;
  }
  @media (max-width: 1000px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
