import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext
} from "react";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { useAccount, useSendTransaction } from "wagmi";

import { parseEther } from "viem";
import { alertModalState, loadingModalState } from "../modules/Modal";
import { isDAppBrowser } from "../utils/CheckDAppBrowser";
import { web3modal } from "../wagmi.config";

declare global {
  interface Window {
    klaytn: any;
  }
}
export type AddressType = `0x${string}` | undefined

interface WalletContextType {
  openModal: () => void;
  closeModal: () => void;
  // address: AddressType;
  // chainId: number | undefined;
}


// 컨텍스트 생성
const WalletContext = createContext<WalletContextType>({
  openModal: () => {},
  closeModal: () => {},
  // address: undefined,
  // chainId: undefined,
});

// 컨텍스트 제공자 컴포넌트
export const WalletProvider = ({ children }: { children: ReactNode }) => {
  const alertModal = useSetRecoilState(alertModalState);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);
  const { address, chainId } = useAccount();


  const openModal = async() => {
    if(address){
      web3modal.open({ view: 'Account' })
    }else{
      const checkBrowser = isDAppBrowser();
      if(!checkBrowser){
      alertModal({
        isModal: true,
        content: `If it doesn't connect continuously,\nplease re-try in your wallet.`,
        type: "check",
      });
      // return;
    }
      web3modal.open();
    }
  }

  const closeModal = async() => {
      web3modal.close();
  }


  return (
    <WalletContext.Provider
      value={{
        openModal,
        closeModal
        // address,
        // chainId,
      }}
    >
      {/* <button onClick={() => {nativeTransfer({toAddress: '0xE59834E2386aC8aB638FC7db565C4621A3c1db9a', amount: '0.001'})}}>aaa</button> */}
      {children}
    </WalletContext.Provider>
  );
};

// 컨텍스트 훅
export const useWallet = () => useContext(WalletContext);
