import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import "./ImageView.css";
import { IoIosClose } from "react-icons/io";

interface ImageViewContextType {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  src: string;
  setSrc: Dispatch<SetStateAction<string>>;
}

// 컨텍스트의 기본값 정의
const defaultValue: boolean = false;

// 컨텍스트 생성
const ImageViewContext = createContext<ImageViewContextType>({
  open: false,
  setOpen: () => {},
  src: "",
  setSrc: () => {},
});

// 컨텍스트 제공자 컴포넌트
export const ImageViewProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [src, setSrc] = useState<string>("");

  const onClick = () => {
    setOpen(false);
    setSrc("");
  };

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden"; // 모달 창 오픈 시 스크롤 불가
    } else {
      document.body.style.overflow = "unset"; // 모달 창 종료 시 스크롤 가능
    }
  }, [open]);

  return (
    <ImageViewContext.Provider value={{ open, setOpen, src, setSrc }}>
      {open && (
        <div className="load-modal-container">
          <div className="modal-container">
            <div className="modal-flex">
              <IoIosClose
                size={40}
                color="#fff"
                className="close"
                onClick={onClick}
              />
              <img
                src={src}
                alt=""
                onContextMenu={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          </div>
        </div>
      )}

      {children}
    </ImageViewContext.Provider>
  );
};

// 컨텍스트 훅
export const useImageView = () => useContext(ImageViewContext);
