import React from "react";
import styled from "styled-components";
import theme from "../../../../theme";
import { TraitItemProps } from "../../../../interface/components/interface";

interface TraitsProps {
  traits: TraitItemProps[];
}

export const Traits = ({ traits }: TraitsProps) => {
  return (
    <Container>
    <Wrap>
      {traits.map((item) => {
        return (
          <Container>
          <Item key={item.label}>
            <Label>{item.label.toUpperCase()}</Label>
            <Title>{item.title}</Title>
            {item.sub && <Sub>{item.sub}</Sub>}
          </Item>
          {/* <Item2 key={item.label}>
            <Label>{item.label.toUpperCase()}</Label>
            <Title>{item.title}</Title>
            {item.sub && <Sub>{item.sub}</Sub>}
          </Item2> */}
          </Container>
        );
      })}
    </Wrap>
    </Container>
  );
};

const Container = styled.article`
  display: block;
  grid-template-areas: 'overlap';
  position: relative;
  width: 100%;
  height: 100%;
`;

const Wrap = styled.section`
  grid-area: overlap;
  display: grid;
  gap: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

const Wrap2 = styled.section`
  grid-area: overlap;
  display: grid;
  gap: 10px;
  position: relative;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh 0;
  gap: 1vw;
  background-color: #191f29;
  border-radius: 10px;
  text-align: center;
  /* position: absolute; */
  transition: background-color 0.5s ease;
  cursor: pointer;
  z-index: 5;
  width: 100%;  /* 3등분으로 고정 */
  height: 110%;

  &:hover {
    /* width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    background-color: #000000;  /* 배경색 변경 */
    z-index: 14;  /* 다른 아이템 위에 표시 */
  }

  @media (min-width: 1920px) {
    gap: 10px;
    height: 100%;
    padding: 1vw 0;
  }
`;

const Item2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  gap: 1vw;
  background-color: #191f29;
  border-radius: 10px;
  text-align: center;
  /* box-sizing: border-box; */
  position: relative;
  transition: transform 0.5s ease, background-color 0.5s ease;
  cursor: pointer;
  /* transform-origin: center center; */
  width: 100%;
  z-index: 3;

  /* ${Item}:hover & {
    z-index: 8;
    background: #fff;
  } */
  
  @media (min-width: 1920px) {
    gap: 10px;
  }
`;

const Title = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 1.3vw;
  width: 90%;
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1920px) {
    font-size: 17px;
    line-height: 20px;
  }
    @media (max-width: 680px) {
      font-size: 9px;
      line-height: 11px;
    }
`;

const Label = styled.span`
  color: #8e2ff9;
  font-family: ${theme.fontFamily.roboto};
  font-weight: 600;
  font-size: 1vw;
  line-height: 1.1vw;
  width: 90%;
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
    @media (max-width: 680px) {
      font-size: 11px;
      line-height: 14px;
    }
`;

const Sub = styled.span`
  color: ${theme.colors.tableHeader};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 600;
  font-size: 0.8vw;
  line-height: 0.9vw;
  width: 90%;
  text-align: center;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 20px;
  }
`;

