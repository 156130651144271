import React from "react";
import styled from "styled-components";
import userApi from "../../api/userApi";
import theme from "../../theme";
import { setComma } from "../../utils/Comma";

export const CategoryInfo = ({ category }: { category?: string }) => {

  const getCategoryInfo = async () => {
    const res = await userApi.nft.getCategoryInfo(category ?? '');
    setTotalVolume(res.nftCount);
    setApprovedVolume(res.approvedNft);
    setTopPrice(res.topPrice);
    setLowPrice(res.lowPrice);
  };
  React.useEffect(() => {
    getCategoryInfo();
  }, [category]);

  const [totalVolume, setTotalVolume] = React.useState<string>("0");
  const [approvedVolume, setApprovedVolume] = React.useState<string>("0");
  const [topPrice, setTopPrice] = React.useState<string>("0");
  const [lowPrice, setLowPrice] = React.useState<string>("0");

  return (
    <InfoWrap>
      <ItemInfoWrap>
        <InfoTotalBox>
          <InfoBox>
            <InfoTotal>{setComma(totalVolume)}</InfoTotal>
            <InfoType>Total Volume</InfoType>
          </InfoBox>
          <WrapLine />
          <InfoBox>
            <InfoTotal>{setComma(approvedVolume)}</InfoTotal>
            <InfoType>Approved Volume</InfoType>
          </InfoBox>
          <WrapLine />
          <InfoBox>
            <InfoTotal>{setComma(topPrice)}</InfoTotal>
            <InfoType>Ceiling Price</InfoType>
          </InfoBox>
          <WrapLine />
          <InfoBox>
            <InfoTotal>{setComma(lowPrice)}</InfoTotal>
            <InfoType>Floor Price</InfoType>
          </InfoBox>
        </InfoTotalBox>
      </ItemInfoWrap>
    </InfoWrap>
  );
};

const InfoWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  /* height: 25%; */
`;

const ItemInfoWrap = styled.div`
  background-color: ${theme.colors.side};
  border-radius: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw;
  height: 100%;
  @media (max-width: 1100px) {
    padding: 10px;
  }
  @media (max-width: 680px) {
    padding: 5px;
  }
`;

const InfoTotalBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #191f29;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  padding: 1vw;
  @media (max-width: 1100px) {
    padding: 10px;
  }
  @media (max-width: 680px) {
    padding: 5px;
  }
`;

const WrapLine = styled.div`
  display: flex;
  width: 1px;
  height: 70%;
  border-color: ${theme.colors.side};
  border-style: solid;
  border-width: 1px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  height: 100%;
  @media (max-width: 680px) {
    gap: 5px;
  }
`;

const InfoTotal = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 700;
  font-size: 1.4vw;
  line-height: 1.5vw;

  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 25px;
  }
  @media (max-width: 1080px) {
    font-size: 17px;
    line-height: 19px;
  }
  @media (max-width: 680px) {
    font-size: 14px;
    line-height: 16px;
  }

`;

const InfoType = styled.span`
  color: #777d88;
  font-family: ${theme.fontFamily.roboto};
  font-weight: 400;
  font-size: 1.3vw;
  line-height: 1.4vw;

  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 30px; // 23 -> 30 Ceiling Price글자 g 밑에 짤림
  }
  @media (max-width: 1080px) {
    font-size: 15px;
    line-height: 20px; // 18 -> 20
  }
  @media (max-width: 680px) {
    font-size: 10px;
    line-height: 17px; // 14 -> 17
  }
`;
