import { Route, Routes } from "react-router-dom";
import { ImageViewProvider } from "../../contexts/ImageView/ImageViewContext";
import NotFoundPage from "../../view/NotFound/NotFoundPage";
import { NFTDetailPage } from "../../view/Users/NFT/NFTDetail/NFTDetailPage";
import { ProfilePage } from "../../view/Users/Profile/ProfilePage";
// import { CollectablePage } from "../../view/Users/SideMenu/Category/Collectable/CollectablePage";
// import { DigitalArtPage } from "../../view/Users/SideMenu/Category/DigitalArt/DigitalArtPage";
// import { GamePage } from "../../view/Users/SideMenu/Category/Game/GamePage";
// import { SportsPage } from "../../view/Users/SideMenu/Category/Sports/SportsPage";
import { ExchangeProvider } from "../../contexts/ExchangeContext";
import { StandardProvider } from "../../contexts/StandardContext";
import { CategoryListPageWrapper } from "../../view/Users/NFT/Category/CategoryListPageWrapper";

const UserRoute = () => {
  return (
    <Routes>
      <Route
        path="/category/:category"
        element={
          <CategoryListPageWrapper/>
        }
      />
      {/* <Route
        path="/category/digital-art"
        element={
          <DigitalArtPage topMenu={"Category"} category={"Digital Art"} />
        }
      />
      <Route
        path="/category/sports"
        element={<SportsPage topMenu={"Category"} category={"Sports"} />}
      />
      <Route
        path="/category/collectable"
        element={
          <CollectablePage topMenu={"Category"} category={"Collectable"} />
        }
      /> 
      <Route
        path="/category/game"
        element={<GamePage topMenu={"Category"} category={"Game"} />}
      />
      */}

      <Route path="/profile" element={<ProfilePage />} />

      <Route
        path="/assets/nft/:contract/:tokenId/:pk"
        element={
          <StandardProvider>
            <ExchangeProvider>
              <ImageViewProvider>
                <NFTDetailPage topMenu={"Category"} />
              </ImageViewProvider>
            </ExchangeProvider>
          </StandardProvider>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default UserRoute;
