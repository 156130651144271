import { useNavigate } from "react-router";
import styled from "styled-components";
// import BigButton from "../../../components/Common/Button/BigButton";
import theme from "../../theme";
import BigButton from "../../components/Button/BigButton";
import React from 'react';

interface NotFoundPageTypes {
  prevPage?: boolean;
}

function NotFoundPage(props: NotFoundPageTypes) {
  const navigate = useNavigate();

  return (
      <NotFoundPageBlock>
        <p>No Page</p>
        <BigButton text="Go Home page" onClick={() => navigate("/")} />
      </NotFoundPageBlock>
  );
}

export default NotFoundPage;

const NotFoundPageBlock = styled.div`
  background-color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    font-size: ${theme.fontType.headline.fontSize};
    font-weight: ${theme.fontType.headline.bold};
    margin-bottom: ${theme.commonMargin.gap4};
    color: ${theme.colors.peacok}
  }
`;
