import React from "react";
import styled from "styled-components";
import theme from "../../theme";
import { useLocation, useNavigate } from "react-router-dom";
import { assets } from "../../theme/assets";
import { CategoryProps } from "../../interface/view/interface";
import { useSidebar } from "../../contexts/SidebarContext";
import '../../animation.css';
import { ADMIN_PATH } from "../../routers/GlobalRoute";
import { useSetRecoilState } from "recoil";
import { alertModalState } from "../../modules/Modal";
import { useDispatch } from "react-redux";
import { clear } from "../../redux/slice/UserSlice";

interface MenuProps {
  active: boolean;
}
interface MenuItem {
  title: string;
  path?: string;
  children?: MenuItem[];
  open?: boolean;
  onClick? : () => Promise<void>;
}


export const SideBar = (props: CategoryProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const alertModal = useSetRecoilState(alertModalState);

  const { open, setOpen } = useSidebar();

  React.useEffect(() => {
    setOpen(false);
  },[location.pathname])

  const menuList: MenuItem[] = [
    {
      title: "Category",
      open: true,
      children: [
        { title: "Digital Art", path: "/category/digital-art" },
        { title: "Sports", path: "/category/sports" },
        { title: "Collectable", path: "/category/collectable" },
        { title: "Game", path: "/category/game" },
      ],
    },
    {
      title: "Doc",
      open: false,
      path: "https://blings-kr.readme.io/docs/blings-%EC%86%8C%EA%B0%9C",
    },
  ];

  const adminMenuList: MenuItem[] = [
    {
      title: "설정",
      open: true,
      path: `${ADMIN_PATH}/setting`,
    },
    {
      title: "Contract 등록",
      open: false,
      path: `${ADMIN_PATH}/contract`,
    },
    {
      title: "NFT 등록",
      open: false,
      path: `${ADMIN_PATH}/register/search`,
    },
    {
      title: "NFT 관리",
      open: false,
      path: `${ADMIN_PATH}/management`,
    },
    {
      title: '로그아웃',
      open: false,
      path: '',
    }
  ];

  const [menus, setMenus] = React.useState(location.pathname.indexOf(ADMIN_PATH) === -1 ? menuList: adminMenuList);

  const onMenuClick = (path?: string, title?: string) => {
    if (path) {
      if(path.indexOf('http') !== -1){
        window.open(path, "_blank");
      }else{
        if(location.pathname.indexOf('category') !== -1){
          const params = new URLSearchParams(location.search);
          params.delete('type');
          params.delete('search');
          navigate({pathname: path,
            search: params.toString()})
        }else if(location.pathname.indexOf(`${ADMIN_PATH}`)){
          const params = new URLSearchParams(location.search);
          for (const key of params.keys()) {
            params.delete(key);
          }
          navigate({pathname: path,
          search: params.toString()})
        }else{
          navigate(path);
        }
      }
    } else if (title === '로그아웃'){
      dispatch(clear());

      alertModal({
        isModal: true,
        content: "Logout complete.",
      });

      return navigate('/', {replace: true})
    }else {
      setMenus(
        menus.map((item) =>
          item.title === title ? { ...item, open: !item.open } : item
        )
      );
    }
  };

  return (
    <MainSideBar id="main-sidebar" open={open}>
      <Logo src={assets.logo} onClick={() => navigate("/")} />
      {menus.map((menu, index) => (
        <Wrap key={`Top-${index}`}>
          <Menu
            active={menu.title === props.topMenu}
            onClick={() => onMenuClick(menu.path, menu.title)}
          >
            <MenuTitle active={menu.title === props.topMenu}>
              {menu.title}
            </MenuTitle>
          </Menu>
          {menu.children && menu.open && (
            <MenuWrap>
              {menu.children?.map((item, idx) => (
                <MenuTitle
                  key={`Title-${idx}`}
                  active={item.title === props.category}
                  onClick={() => onMenuClick(item.path)}
                >
                  {item.title}
                </MenuTitle>
              ))}
            </MenuWrap>
          )}
        </Wrap>
      ))}
    </MainSideBar>
  );
};

const MainSideBarTop = styled.div`
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, 0.8);
z-index: 100002;

`;
const MainSideBar = styled.section<{ open: boolean }>`
  width: 300px;
  
  background: ${theme.colors.side};
  display: block;
  flex-direction: column;
  padding: 10px 25px;

  @media (min-width: 1920px) {
    width: 360px;
  }
  @media (max-width: 1550px) {
    width: 250px;
  }

  @media (max-width: 1250px) {
    width: 200px;
  }
  
  @media (max-width: 1000px) {
    display: none;
    width: 100vw;
    position: absolute;
    top: ${props => props.open ? '40px' : 'auto' };
    bottom: ${props => props.open ? '0px' : 'auto' };
    left: 0;
    height: calc(100vh - 40px);
    z-index: 100002;
    animation: ${props => props.open ? 'bottomToTop 0.5s' : 'topToBottom 0.5s' };
  }

  @media (max-width: 680px) {
    width: 60%;
    position: absolute;
    top: 50px;
    left: ${props => props.open ? 'auto' : 'auto'};
    right: ${props => props.open ? '0px' : 'auto'};
    height: calc(100vh - 50px);
    z-index: 100002;
    animation: ${props => props.open ? 'rightToLeft 0.5s' : 'leftToRigth 0.5s' };

    padding: 40px 25px;
  }
`;

const Logo = styled.img`
  cursor: pointer;
  width: 70%;
  height: fit-content;
  margin-bottom: 20px;
  @media (max-width: 1000px) {
    width: 30%;
  }
  @media (max-width: 680px) {
    display: none;
  }
`;

const Wrap = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${theme.commonMargin.gap2};
  flex-direction: column;
  gap: ${theme.commonMargin.gap1};
`;

const Menu = styled.div<MenuProps>`
  width: 100%;
  padding: 15px;
  background-color: ${(props) =>
    props.active
      ? `linear-gradient(
        90deg, #8C33FA 0%, #31D6FF 100%
    );`
      : theme.colors.menu};
  background-image: ${(props) =>
    props.active
      ? `linear-gradient(
        90deg, #8C33FA 0%, #31D6FF 100%
    );`
      : theme.colors.menu};
  display: flex;
  flex-direction: column;
  border-radius: ${theme.commonRadius.radius1};
  cursor: pointer;
`;

const MenuTitle = styled.span<MenuProps>`
  font-family: ${theme.fontFamily.pretendard};
  font-weight: ${(props) =>
    props.active
      ? props.theme.fontType.activeMenu.bold
      : props.theme.fontType.inactiveMenu.bold};
  color: ${props => props.active ? theme.colors.amount :theme.colors.white};
  font-size: ${theme.fontType.activeMenu.fontSize};
  //line-height: ${theme.fontType.activeMenu.lineHeight}; // line-height를 설정하지 않으면 글자가 짤리지 않는데 잘 모르겠음
  cursor: pointer;
`;

const MenuWrap = styled.div`
  width: 100%;
  padding: 15px;
  background-color: ${theme.colors.menu};
  display: flex;
  gap: ${theme.commonMargin.gap1};
  flex-direction: column;
  border-radius: ${theme.commonRadius.radius1};
`;
