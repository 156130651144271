import { isDesktop } from "react-device-detect";

export const isDAppBrowser = () => {
    if(isDesktop) return true;
    
    const userAgent = navigator.userAgent;
    
    if (/MetaMask/.test(userAgent)) {
      return true;
    }
    
    if (/TrustWallet/.test(userAgent)) {
      return true;
    }

    if (/Kaikas/.test(userAgent)) {
        return true;
      }

      if (/Coinbase/.test(userAgent)) {
        return true;
      }
    
    // alert(userAgent);
    return false; // 일반 브라우저
  }