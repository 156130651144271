import React from "react";
import styled from "styled-components";

const TooltipContainer = styled.div`
  background: #1B1C29;
  border-radius: 5px;
  padding: 10px;
  /* border: 1px solid #ccc; */
`;

const TooltipLabel = styled.p`
  margin: 0;
  font-weight: bold;
  color: #fff;
`;

const TooltipIntro = styled.p`
  margin: 0;
  color: #fff;
`;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipLabel>{`Date: ${label}`}</TooltipLabel>
        <TooltipIntro>{`Volume: ${payload[0].value}`}</TooltipIntro>
        <TooltipIntro>{`Average Price: ${Number(payload[1].value.toFixed(6))}`}</TooltipIntro>
      </TooltipContainer>
    );
  }

  return null;
};

export default CustomTooltip;
