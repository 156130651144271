import styled from "styled-components";
import theme from "../../../../theme";
import { setComma } from "../../../../utils/Comma";
import { calculateDateDifference } from "../../../../utils/DiffDate";
import { shortenAddress } from "../../../../utils/web3";
import RetryingImg from "../../RetryingImg";
import { tokenIdTrim } from "../../../../utils/TrimString";
import { chainList } from "../../../../controller/chainId";

interface NFTTableProps {

}

export const NFTTableBase = () => {

  const COLUMNS = [
    {
      Header: '',
      accessor: "#",
      width: "100%",
      Cell: (row: any) => {
        return (
          <RetryingImg
          src={`${row.row.original.image}`}
          height="60px"
          width="60px"
          onContextMenu={(e) => e.preventDefault()}
        />
        );
      },
    },
    {
      Header: () => <Header style={{ justifyContent: "start" }}>Item</Header>,
      accessor: "tokenId",
      width: "100%",
      Cell: (row: any) => {
        return (
          <Price style={{ justifyContent: "start" }}>
            {`${row.row.original.tokenName} #${tokenIdTrim(row.value, 10)}`}
          </Price>
        );
      },
    },
    {
        Header: () => <Header style={{ justifyContent: "start" }}>Name</Header>,
        accessor: "name",
        width: "100%",
        Cell: (row: any) => {
          return (
            <Price style={{ justifyContent: "start" }}>
              {tokenIdTrim(row.value, 30)}
            </Price>
          );
        },
      },
    {
      Header: () => <Header>Current Price</Header>,
      accessor: "price",
      width: "100%",
      Cell: (row: any) => {
        if(parseFloat(row.value).toString() === '0'){
            return <Other>-</Other>
        }
        return <Other>{setComma(row.value)} {chainList[row.row.original.chainId].symbol}</Other>;
      },
    },
    // {
    //   Header: () => <Header>Best Offer</Header>,
    //   accessor: "pk",
    //   width: "10%",
    //   Cell: (row: any) => {
    //     if(parseFloat(row.value).toString() === '0'){
    //         return <Other>-</Other>
    //     }
    //     return <Other>{setComma(row.value)}</Other>;
    //   },
    // },
    {
      Header: () => (
        <Header style={{ justifyContent: "start" }}>Last Sale</Header>
      ),
      accessor: "lastDate",
      width: "100%",
      Cell: (row: any) => {
        return <Other style={{ justifyContent: "start" }}>{calculateDateDifference(row.value)}</Other>;
      },
    },
    // {
    //   Header: () => (
    //     <Header style={{ justifyContent: "start" }}>Owner</Header>
    //   ),
    //   accessor: "owner",
    //   width: "100%",
    //   Cell: (row: any) => {
    //     if(row.value === ''){
    //         return <Other style={{ justifyContent: "start" }}>-</Other>
    //     }
    //     return <Other style={{ justifyContent: "start" }}>{shortenAddress(row.value)}</Other>;
    //   },
    // },
  ];

  return {
    COLUMNS,
  };
};

const Header = styled.span`
  color: ${theme.colors.tableHeader};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.4vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 23px;
    line-height: 26px;
  }
`;

const Price = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 700;
  font-size: 1.3vw;
  line-height: 1.5vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 22px;
    line-height: 24px;
  }
`;

const Other = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.3vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

const From = styled.span`
  color: ${theme.colors.amount};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 1.3vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
`;