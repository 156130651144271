import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import userApi from "../../../../api/userApi";
import ListStyleButton from "../../../../components/Button/ListStyleButton";
import { MobileFilter } from "../../../../components/Button/Mobile/MobileFilter";
import { NFTFilter } from "../../../../components/Filter/NFTFilter";
import SearchInput from "../../../../components/Input/SearchInput";
import { SelectBox } from "../../../../components/Input/SelectBox";
import { Layout } from "../../../../components/Layout/Layout";
import { OrderFilterModal } from "../../../../components/Modal/Mobile/OrderFilterModal";
import { FilterModal } from "../../../../components/Modal/Mobile/OrderModal";
import { CategoryInfo } from "../../../../components/NFTItem/CategoryInfo";
import { NFTTableBase } from "../../../../components/NFTItem/Table/NFT/NFTTable";
import { NFTTable } from "../../../../components/Table/NFTTable";
import { selectBoxItem, useList } from "../../../../contexts/ListContext";
import {
  Iquery,
  NFTItemProps,
} from "../../../../interface/components/interface";
import { CategoryListPageProps } from "../../../../interface/view/interface";
import { alertModalState } from "../../../../modules/Modal";
import { assets } from "../../../../theme/assets";
import { addComma } from "../../../../utils/Comma";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { NFTItem } from "../../../../components/NFTItem/NFTItem";
import { useResizingHandler } from "../../../../hooks/useResizingHandler";
import { debounce } from "lodash";
import ScrollToTopButton from "../../../../components/Button/ScrollToTopButton";

export const CategoryListPage: React.FC<CategoryListPageProps> = (props) => {
  const { category } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { COLUMNS } = NFTTableBase();
  const alertModal = useSetRecoilState(alertModalState);
  const { address } = useAccount();
  const {
    setSelectedViewOption,
    selectedViewOption,
    setSearch,
    setSelectedOption,
    setBuyNow,
    setWaitApprove,
    setMinPrice,
    setMaxPrice,
  } = useList();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [size, setSize] = useState<number>(6);
  const [query, setQuery] = useState<Iquery>({});
  const [data, setData] = useState<NFTItemProps[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    if (props.category === '') {
      return navigate("/no-page");
    }
    const params = new URLSearchParams(location.search);
    const searchQuery = params.get("search");
    const orderQuery = params.get("order");
    const buyNowQuery = params.get("buyNow");
    const waitApproveQuery = params.get("waitApprove");
    const startPriceQuery = params.get("startPrice");
    const endPriceQuery = params.get("endPrice");

    const queryData: Iquery = {};
    if (searchQuery) {
      setSearch(searchQuery);
      queryData.search = searchQuery;
    } else {
      setSearch("");
    }
    if (orderQuery) {
      const order = selectBoxItem.find((item) => item.value === orderQuery);
      if (order) {
        setSelectedOption(order);
        queryData.order = orderQuery;
      } else {
        setSelectedOption(selectBoxItem[0]);
        queryData.order = selectBoxItem[0].value;
      }
    }
    if (buyNowQuery) {
      setBuyNow(true);
      queryData.buyNow = "true";
    } else {
      setBuyNow(false);
    }
    if (waitApproveQuery) {
      setWaitApprove(true);
      queryData.waitApprove = "true";
    } else {
      setWaitApprove(false);
    }
    if (startPriceQuery) {
      setMinPrice(addComma(startPriceQuery));
      queryData.startPrice = startPriceQuery;
    } else {
      setMinPrice("");
    }

    if (endPriceQuery) {
      setMaxPrice(addComma(endPriceQuery));
      queryData.endPrice = endPriceQuery;
    } else {
      setMaxPrice("");
    }

    setQuery(queryData);
    setCurrentPage(1);
    setData([]);
    setHasMore(true);
    setIsFetching(false);

    getList(queryData, 1, true);
  }, [location.search, category]);

  const fetchMoreListItems = useCallback(async () => {
    await getList(query, currentPage);
  }, [query, currentPage]);

  const {lastItemRef, tableLastItemRef, isFetching, setIsFetching, Loading} =
    useInfiniteScroll(fetchMoreListItems);

  React.useEffect(() => {
    if (total === 0) return;
    if (total > data.length) {
      setHasMore(true);
    } else {
      setHasMore(false);
    }
  }, [total, data]);

  const getList = async (query?: Iquery, page = 1, pass = false) => {
    if (isFetching) return; // 중복 요청 방지
    if (!pass && !hasMore) return; // 더 가져올 데이터가 없으면 중지

    try {
      setIsFetching(true); // 요청 시작 전에 isFetching 설정

      const res = await userApi.nft.getList({
        page,
        size,
        category: category,
        address,
        query,
      });

      if (res.status !== 200) {
        throw new Error("An error occurred while fetching data."); // 에러 처리
      }

      setTotal(res.data.total);
      setData((prevData) => [...prevData, ...res.data.list]);
      setCurrentPage((prevPage) => prevPage + 1); // 페이지 번호 증가
    } catch (error) {
      alertModal({
        isModal: true,
        content: "An error occurred while fetching data.",
      });
    } finally {
      setIsFetching(false); // 요청 완료 후 isFetching 해제
    }
  };

  const filterRef = useRef<HTMLDivElement>(null);
  const searchBarRef = useRef<HTMLDivElement>(null);
  const [sticky, setSticky] = useState(false);

  const resizeHandler = useCallback(() => {
    const items = document.querySelectorAll(".nft-item-wrapper");

    items.forEach((item) => {
      const element = item as HTMLElement;
      const width = element.clientWidth;
      const height = `${(width * 16) / 9}px`;
      element.style.height = height;
    });
  }, [selectedViewOption.row]);

  React.useEffect(() => {
    resizeHandler(); // 초기 호출
  }, []);

  useResizingHandler(debounce(resizeHandler, 500));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting && window.scrollY > 500) {
            setSticky(true);
          } else {
            setSticky(false);
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );

    if (searchBarRef.current) {
      observer.observe(searchBarRef.current);
    }

    return () => {
      if (searchBarRef.current) {
        observer.unobserve(searchBarRef.current);
      }
    };
  }, []);

  return (
    <Layout {...props}>
      <ScrollToTopButton />
      <ContainerWrap>
        <Img src={assets.images.main} />
        <Wrap>
          <CategoryInfo category={category} />
          <Container>
            <SpaceRow ref={searchBarRef}>
              <SearchInput />
              <Row>
                <SelectBox />
                <ListStyleButton />
                <MobileFilter />
                <OrderFilterModal />
                <FilterModal />
              </Row>
            </SpaceRow>
          </Container>
        </Wrap>
      </ContainerWrap>
      <RowNFT>
        <FilterReplaceContainer sticky={sticky} />
        <FilterContainer ref={filterRef} sticky={sticky}>
          <NFTFilter />
        </FilterContainer>
        <Flexible>
          {selectedViewOption.grid ?
          <NFTWrap $grid={selectedViewOption.grid} row={selectedViewOption.row}>
            {data.map((nft, index) => (
              <NFTItemWrapper
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                ref={index === data.length - 1 ? lastItemRef : null}
                className="nft-item-wrapper"
              >
                <NFTItem {...nft} width={"100%"} height={nft.height}/>
              </NFTItemWrapper>
            ))}
          </NFTWrap>
          :
          <NFTTableWrap
            $grid={selectedViewOption.grid}
            >
            <NFTTable
              COLUMNS={COLUMNS}
              DATA={data}
              total={total}
              ref={tableLastItemRef}
            />
          </NFTTableWrap>
          }
          {hasMore && <Loading />}
        </Flexible>
      </RowNFT>
    </Layout>
  );
};

const ContainerWrap = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 180px;
  @media (max-width: 1000px) {
    margin-bottom: 120px;
  }
  @media (max-width: 700px) {
    margin-bottom: 15vw;
  }
`;

const Img = styled.img`
  width: 100%;
  height: fit-content;
`;

const Wrap = styled.div`
  position: absolute;
  top: 87%;
  width: 100%;
  height: 100%;
  padding: 0 2vw;
  display: flex;
  flex-direction: column;
  gap: 1vw;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 1vw;
`;

const SpaceRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vw;
  @media (min-width: 1920px) {
    gap: 10px;
  }
`;

const RowNFT = styled.div`
  display: flex;
  gap: 1vw;
  padding: 1vw;
  z-index: 8888;
  @media (min-width: 1920px) {
    gap: 10px;
  }
  @media (max-width: 680px) {
    padding: 30px;
  }
`;

const Flexible = styled.div`
  flex: 1;
`;

const FilterReplaceContainer = styled.div<{ sticky: boolean }>`
  display: ${({ sticky }) => (sticky ? "block" : "none")};
  /* display: block; */
  width: 250px;
  min-width: 250px;
  width: 250px;
  min-width: 250px;
  @media (max-width: 1400px) {
    width: 170px;
    min-width: 170px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

const FilterContainer = styled.div<{ sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? "fixed" : "")};
  top: ${({ sticky }) => (sticky ? "10px" : "auto")};
  height: fit-content;
  width: 250px;
  min-width: 250px;
  @media (max-width: 1400px) {
    width: 170px;
    min-width: 170px;
  }
  @media (max-width: 800px) {
    display: none;
  }
`;

const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${props => props.$grid ? 'grid' : 'none'};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTTableWrap = styled.section<{ $grid: boolean }>`
  display: ${props => props.$grid ? 'none' : 'block'};
  gap: 1vw;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default CategoryListPage;
