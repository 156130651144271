import styled from "styled-components";
import React from "react";
import { useAccount, useBalance } from "wagmi";
import { chainList } from "../../controller/chainId";
import { useSetChains } from "../../hooks/web3/useSetChains";
import theme from "../../theme";
import { shortenBalance } from "../../utils/web3";
import BigButtonSelectChain from "../Button/BigButtonSelectChain";
import { priceState } from "../../modules/Modal";
import { useRecoilValue } from "recoil";
import '../../animation.css';
import { addComma, removeComma } from "../../utils/Comma";
import { UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { userBuyOrOfferInput } from "../NFTItem/NFTPrice";

interface SelectChainOfferModalProps {
  getValues: UseFormGetValues<userBuyOrOfferInput>;
  setValue: UseFormSetValue<userBuyOrOfferInput>;
  watch: UseFormWatch<userBuyOrOfferInput>;

}

const SelectChainOfferModal = ({ getValues, setValue, watch }: SelectChainOfferModalProps) => {
  const { chainId, address, chain } = useAccount();
  const { data: balance } = useBalance({ address, chainId, unit: "ether" });
  const { setChain } = useSetChains();
  const [animating, setAnimating] = React.useState(false);
  const [offer, setOffer] = React.useState<string>('');
  const [price, setPrice] = React.useState<string>('');

  React.useEffect(() => {
    const subscription = watch((values) => {
      if(values.price){
        setPrice(values.price.toString());
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setPrice]);


 
  React.useEffect(() => {
    if (price !== '') { // 초기 값 제외
      setAnimating(true);
      const timer = setTimeout(() => setAnimating(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [price]);
1
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = removeComma(rawValue);
    setOffer(addComma(formattedValue));
    setValue('offer', formattedValue);
  };

  const listData = [
    {
      name: "ethereum",
      icon: "https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=032",
      chainId: process.env.REACT_APP_TYPE === "DEV" ? 11155111 : 1,
      action: () => {
        setSelectChainList(
          selectChainList.map((chain) =>
            chain.name === "ethereum"
              ? {
                ...chain,
                selected: true,
              }
              : { ...chain, selected: false }
          )
        );
      },
    },
    {
      name: "binance",
      icon: "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
      chainId: process.env.REACT_APP_TYPE === "DEV" ? 97 : 56,
      action: () => {
        setSelectChainList(
          selectChainList.map((chain) =>
            chain.name === "binance"
              ? {
                ...chain,
                selected: true,
              }
              : { ...chain, selected: false }
          )
        );
      },
    },
    {
      name: "polygon",
      icon: "https://cryptologos.cc/logos/polygon-matic-logo.svg?v=032",
      chainId: process.env.REACT_APP_TYPE === "DEV" ? 80002 : 137,
      action: () => {
        setSelectChainList(
          selectChainList.map((chain) =>
            chain.name === "polygon"
              ? {
                ...chain,
                selected: true,
              }
              : { ...chain, selected: false }
          )
        );
      },
    },
    {
      name: "klaytn",
      icon: "https://cryptologos.cc/logos/klaytn-klay-logo.png",
      chainId: process.env.REACT_APP_TYPE === "DEV" ? 1001 : 8217,
      action: () => {
        setSelectChainList(
          selectChainList.map((chain) =>
            chain.name === "klaytn"
              ? {
                ...chain,
                selected: true,
              }
              : { ...chain, selected: false }
          )
        );
      },
    },
  ];

  const [selectChainList, setSelectChainList] = React.useState(listData);

  return (
    <SContentWrapper>
      <SModalContent>
        <SChainWrap>
          {selectChainList.map((item) => {
            return (
              <BigButtonSelectChain
                key={item.name}
                icon={item.icon}
                name={item.name}
                extraStyle={{
                  color: "#5B606D",
                  margin: "0",
                  border: `1px solid ${theme.colors.gray3}`,
                }}
                onClick={async () => {
                  setChain({ chainId: item.chainId })
                  item.action();
                }}
                backgroundColor={item.chainId === chain?.id ? "#fff" : theme.colors.gray3}
              />
            );
          })}
        </SChainWrap>
        <SSpan
        >
          Select Chain: {chainList[chainId!].chainName}
        </SSpan>

        <SSpan
        >
          Account Balance:{" "}
          {`${
            balance === undefined
              ? ""
              : shortenBalance(balance.formatted)
          } ${chain?.nativeCurrency.symbol}`}
        </SSpan>
        <SSpan
        >
          Price:
          <div className={`price-container ${animating ? 'slot-machine' : ''}`}>
            <div className="number">&nbsp;{price}&nbsp;</div>
          </div>
          {chain?.nativeCurrency.symbol}
        </SSpan>
        <SSubSpan>* The exchange rate may not be accurate.</SSubSpan>
        <FormRow>
          <SSpan>Offer:</SSpan>
          <InputWrapper>
            <Input
              type="text"
              value={offer}
              onChange={handleChange}
            />
            <Unit>{chain?.nativeCurrency.symbol}</Unit>
          </InputWrapper>
        </FormRow>
      </SModalContent>
    </SContentWrapper>
  );
};

const SContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SModalContent = styled.div`
  width: 50%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px;
  box-sizing: border-box;
`;
const SChainWrap = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
`;

const SSpan = styled.span`
  padding: 8px 0;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  display: flex;
`;


const SSubSpan = styled.span`
  padding: 3px 0;
  padding-bottom: 15px;
  font-size: 12px;
  color: ${theme.colors.amount};
`;

const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
`;

const InputWrapper = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  align-items: center;
`;

const Input = styled.input`
  padding: 8px;
  padding-right: 30px; // 오른쪽 패딩 추가
  border: 1px solid #4B4F6B;
  background-color: #4B4F6B;
  border-radius: 4px;
  width: 100%;
  color: #fff;
`;

const Unit = styled.span`
  position: absolute;
  right: 10px; // input 요소의 오른쪽에 단위를 배치
  color: #acacac;
  font-weight: bold;
  font-size: 14px;
`;

export default SelectChainOfferModal;
