import React, { useMemo } from "react";
import {
  Column,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroll-component";
import './Table.styles'
import theme from "../../theme";

interface TableProps {
  COLUMNS: Array<Column<object>>;
  DATA: Array<object>;
  fetch: () => void;
}

export const Table = ({
  COLUMNS,
  DATA,
  fetch
}:TableProps) => {

  const columns = useMemo(() => COLUMNS, [COLUMNS]);
  const data = useMemo(() => DATA, [DATA]);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    rows,
    headerGroups,
    page,
    state,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageIndex, pageSize = 5 } = state;
  return (
  //   <InfiniteScroll
  //   dataLength={rows.length}
  //   next={fetch}
  //   hasMore={true}
  //   loader={null}
  // >
      // {/* <TableLabel>
      //   Show
      //   <S.TableSelect
      //     value={pageSize}
      //     onChange={(e) => {
      //       setPageSize(Number(e.target.value));
      //     }}
      //   >
      //     <option value="5">5</option>
      //     <option value="10">10</option>
      //   </S.TableSelect>
      //   entries
      // </TableLabel> */}
      // {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
      <>
      <TableContainer>
      <StyledTable {...getTableProps()}>
        <thead>
        {headerGroups.map(headerGroup => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeader {...column.getHeaderProps()}>{column.render('Header')}</TableHeader>
              ))}
            </TableRow>
          ))}
        </thead>
          <tbody {...getTableBodyProps()}>
        {page.length > 0 ? (
           rows.map(row => {
             prepareRow(row);
             return (
               <TableRow {...row.getRowProps()}>
                 {row.cells.map(cell => (
                   <TableData {...cell.getCellProps()}>{cell.render('Cell')}</TableData>
                 ))}
               </TableRow>
             );
            })
          ): (
            <TableRow>
               <TableData colSpan={columns.length} style={{ textAlign: 'center', color: theme.colors.white }}>
                  No Offer Data
                </TableData>
            </TableRow>
          )}
         </tbody>
      </StyledTable>
      </TableContainer>
      </>
    // </InfiniteScroll>
  );
};

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
  max-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  border-radius: 16px 16px 0 0;
  border: 1px solid ${theme.colors.secondary};

  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.4);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }

`;

const StyledTable = styled.table`
  width: 100%;
`;

const TableRow = styled.tr`
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: ${theme.colors.secondary};
  position: sticky;
  top: 0;
`;

const TableData = styled.td`
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid ${theme.colors.secondary};
`;

