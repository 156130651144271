import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { useAccount } from "wagmi";
import userApi from "../../../../api/userApi";
import { SelectBox } from "../../../../components/Input/SelectBox";
import { NFTItem } from "../../../../components/NFTItem/NFTItem";
import { selectBoxItem, useList } from "../../../../contexts/ListContext";
import useInfiniteScroll from "../../../../hooks/useInfiniteScroll";
import { useResizingHandler } from "../../../../hooks/useResizingHandler";
import { IProfileQuery, Iquery, NFTItemProps } from "../../../../interface/components/interface";
import { alertModalState } from "../../../../modules/Modal";
import theme from "../../../../theme";
import { debounce } from "lodash";

export const ProfileLiked = () => {
  const location = useLocation();

  const alertModal = useSetRecoilState(alertModalState);
  const { address } = useAccount();
  const {
    setSearch,
    setSelectedOption,
  } = useList();

  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [size, setSize] = React.useState<number>(12);
  const [query, setQuery] = React.useState<Iquery>({});
  const [data, setData] = useState<NFTItemProps[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);

  const fetchMoreListItems = useCallback(async () => {
    await getList(currentPage);
  }, [currentPage]);

  const {lastItemRef, isFetching, setIsFetching, Loading} = useInfiniteScroll(fetchMoreListItems);


  const getList = async (page = 1) => {
    if(address === undefined) return;
    if (isFetching) return;
    if (!hasMore) return;
    try {
      const res = await userApi.nft.getLike({
        page,
        size,
        query,
        address,
      });

      setTotal(res.total)
      setData((prevData) => [...prevData, ...res.list]);
      if (res.list < size) {
        setHasMore(false);
      }
      setCurrentPage(prev => prev + 1);
      setIsFetching(false);
    } catch (error) {
      alertModal({
        isModal: true,
        content: "An error occurred while fetching data.",
      });
      setIsFetching(false);
    }   
  };

  
  const [row, setRow] = React.useState<number>(4);
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const typeQuery = params.get("type");
    const orderQuery = params.get("order");
    const queryData: IProfileQuery = {};

    if (typeQuery) {
      setSearch(typeQuery);
      queryData.type = typeQuery;
    }
    if (orderQuery) {
      const order = selectBoxItem.find((item) => item.value === orderQuery);
      if (order) {
        setSelectedOption(order);
        queryData.order = orderQuery;
      } else {
        setSelectedOption(selectBoxItem[0]);
        queryData.order = selectBoxItem[0].value;
      }
    }
    
    setCurrentPage(1);
    setHasMore(true);
    setIsFetching(false);
    setData([]);
    setQuery(queryData);
    getList(1);
  }, [location.search]);


  const resizeHandler = useCallback(() => {
    const items = document.querySelectorAll('.nft-item-wrapper');
    items.forEach((item) => {
      const element = item as HTMLElement;
      const width = element.clientWidth;
      const height = `${(width * 4) / 3}px`;
      element.style.height = height;
    });
  }, []);
  
  const handler = () => {
    if (window.innerWidth <= 680) {
      setRow(3);
    } else {
      setRow(4);
    }
  };


  React.useEffect(() => {
    handler();
    resizeHandler();
  },[])
  
  
  useResizingHandler(debounce(resizeHandler, 500));
  useResizingHandler(debounce(handler, 500));

  return (
    <>
      <TotalContainer>
        <Total>Liked {total} result</Total>
        <SelectBox type={"profile"} />
      </TotalContainer>
      <RowNFT>
        <Flexible>
            <NFTWrap
            $grid={true}
            row={row}
          >
            {data.map((nft, index) => (
              <NFTItemWrapper
                key={`${nft.tokenAddress}#${nft.tokenId}`}
                ref={index === data.length - 1 ? lastItemRef : null}
                className="nft-item-wrapper"
              >
                <NFTItem
                  {...nft}
                  width={"100%"}
                />
              </NFTItemWrapper>
            ))}
          </NFTWrap>
          {hasMore && <Loading />}
        </Flexible>
      </RowNFT>
    </>
  );
};

const TotalContainer = styled.div`
  margin: 20px;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
`;

const Total = styled.span`
  color: ${theme.colors.tableHeader};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 500;
  font-size: 23px;
  line-height: 26px;
  text-align: start;
  width: 100%;
  padding: 10px;
`;

const RowNFT = styled.div`
  display: flex;
  gap: 1vw;
  padding: 1vw;
  z-index: 8888;

  @media (min-width: 1920px) {
    gap: 10px;
  }
  @media (max-width: 680px) {
    padding: 10px 30px;
  }
`;

const Flexible = styled.div`
  flex: 1;
`;

const NFTWrap = styled.section<{ row: number; $grid: boolean }>`
  display: ${({ $grid }) => ($grid ? "grid" : "none")};
  gap: 30px;
  justify-content: center;
  align-items: center;
  grid-template-columns: ${({ row }) => `repeat(${row}, 1fr)`};
  width: 100%;
  height: 100%;
  margin-bottom: 30px;

  @media (max-width: 680px) {
    gap: 20px;
  }
`;

const NFTItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
