import React, { Dispatch, SetStateAction } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import userApi from "../../../../api/userApi";
import { useExchange } from "../../../../contexts/ExchangeContext";
import { chainList } from "../../../../controller/chainId";
import { NFTItemProps } from "../../../../interface/components/interface";
import theme from "../../../../theme";
import { setComma } from "../../../../utils/Comma";
import { calculateTimeRemaining } from "../../../../utils/DiffDate";
import { shortenAddress } from "../../../../utils/web3";
import "../../../../animation.css";
import { useAccount } from "wagmi";
import { Column } from "react-table";
import { useResetRecoilState, useSetRecoilState } from "recoil";
import { alertModalState, loadingModalState } from "../../../../modules/Modal";

interface OffersInfoProps {
  nft: NFTItemProps | null;
  setNft: Dispatch<SetStateAction<NFTItemProps | null>>;
}

interface OfferList {
  amount: string;
  expiryDate: number;
  network: string;
  nftPk: number;
  offerPk: number;
  ownerWallet: string;

  usdPrice?: string; // 만들어서 추가하는 변수
  original?: string; // 만들어서 추가하는 변수
  percent?: string; // 만들어서 추가하는 변수
}

export const OffersInfo = ({ nft, setNft }: OffersInfoProps) => {
  const alertModal = useSetRecoilState(alertModalState);
  const loadingModal = useSetRecoilState(loadingModalState);
  const resetLoadingModal = useResetRecoilState(loadingModalState);

  const { pk } = useParams();
  const { address } = useAccount();
  const [data, setData] = React.useState<OfferList[]>([]);
  const [update, setUpdate] = React.useState<number>(0);
  const [animating, setAnimating] = React.useState(false);

  const offer_FETCH = async () => {
    if (pk === undefined) return;
    const res = await userApi.nft.getOfferList({ pk });
    setData(res.data);
    setUpdate((prev) => prev + 1);
  };
  const { exchangeData } = useExchange();
  // 애니메이션
  React.useEffect(() => {
    setAnimating(true);
    const timer = setTimeout(() => setAnimating(false), 1000);
    return () => clearTimeout(timer);
  }, [data]);

  React.useEffect(() => {
    if(nft?.price === '0' && nft?.owner === ''){
      setData([])
    }
  }, [nft]);

  React.useEffect(() => {
    offer_FETCH();
  }, [pk]);

  React.useEffect(() => {
    if (exchangeData.length > 0 && data.length > 0) {
      updateOfferData();
    }
  }, [exchangeData, update]);

  // 가격 변동 % 구하는 함수
  const calculateReturnPercentage = (
    purchasePrice: string,
    currentPrice: string
  ): string => {
    const purchasePriceNum = parseFloat(purchasePrice);
    const currentPriceNum = parseFloat(currentPrice);

    if (
      isNaN(purchasePriceNum) ||
      isNaN(currentPriceNum) ||
      purchasePriceNum === 0
    ) {
      return "0";
    }

    const percent =
      ((currentPriceNum - purchasePriceNum) / purchasePriceNum) * 100;
    return percent.toFixed(2);
  };

  // 코인 가격변동에 따라, 추가한 인자 값 변동
  const updateOfferData = () => {
    const updatedData = data.map((offer) => {
      const baseNetwork = exchangeData.find(
        (item) => item.network.toUpperCase() === offer.network.toUpperCase()
      );
  
      if (!baseNetwork) return offer; // 데이터가 없으면 현재 offer 반환
      let usdPrice = (
        parseFloat(baseNetwork.value) * parseFloat(offer.amount)
      ).toFixed(6);
      usdPrice = parseFloat(usdPrice).toString(); // 마지막 0 제거
  
      const originNetwork = exchangeData.find(
        (item) => item.network.toUpperCase() === nft?.network.toUpperCase()
      );
  
      if (!originNetwork) return offer; // 데이터가 없으면 현재 offer 반환
      let original = (
        parseFloat(usdPrice) / parseFloat(originNetwork.value)
      ).toFixed(10);
      original = parseFloat(original).toString();
  
      if (!nft) return offer; // 데이터가 없으면 현재 offer 반환
      let percent = calculateReturnPercentage(nft.price, original);
      return {
        ...offer,
        usdPrice,
        original,
        percent,
      };
    });
    setData(updatedData as OfferList[]);
  };

  const offer_COLUMNS: Column<object>[] = React.useMemo(
    () => [
      {
        Header: () => <Header>{chainList[nft?.chainId!].symbol}</Header>,
        accessor: "original",
        width: "100%",
        Cell: (row: any) => {
          return (
            <Other>
              {row.value !== undefined ? (
                <div
                  className={`price-container ${
                    animating ? "slot-machine" : ""
                  }`}
                >
                  <div className="number">{`${setComma(Number(Number(row.value).toFixed(6)))}`}</div>
                </div>
              ) : (
                "-"
              )}
              &nbsp;
              {chainList[nft?.chainId!].symbol}
            </Other>
          );
        },
      },
      {
        Header: () => <Header>USD Price</Header>,
        accessor: "usdPrice",
        width: "100%",
        Cell: (row: any) => {
          return (
            <Other>
              $&nbsp;
              {row.value !== undefined ? (
                <div
                  className={`price-container ${
                    animating ? "slot-machine" : ""
                  }`}
                >
                  <div className="number">{`${setComma(Number(Number(row.value).toFixed(6)))}`}</div>
                </div>
              ) : (
                "-"
              )}
            </Other>
          );
        },
      },

      {
        Header: () => (
          <Header style={{ justifyContent: "start" }}>
            {nft?.price === "0" ? "" : "Floor Difference"}
          </Header>
        ),
        accessor: "percent",
        width: "100%",

        Cell: (row: any) => {
          if (nft?.price === "0") {
            return null;
          }
          return (
            <Other>
              {row.value !== undefined ? (
                <div
                  className={`price-container ${
                    animating ? "slot-machine" : ""
                  }`}
                >
                  <div className="number">{`${setComma(Number(row.value))}`}</div>
                </div>
              ) : (
                "-"
              )}
              %
            </Other>
          );
        },
      },

      {
        Header: () => (
          <Header style={{ justifyContent: "start" }}>Expiration</Header>
        ),
        accessor: "expiryDate",
        width: "100%",
        Cell: (row: any) => {
          return (
            <Other style={{ justifyContent: "start" }}>
              {calculateTimeRemaining(row.value)}
            </Other>
          );
        },
      },
      {
        Header: () => <Header style={{ justifyContent: "start" }}>From</Header>,
        accessor: "ownerWallet",
        width: "100%",
        Cell: (row: any) => {
          return (
            <From style={{ justifyContent: "start" }}>
              {shortenAddress(row.value)}
            </From>
          );
        },
      },
      {
        Header: "", // 승인
        accessor: "#1",
        width: "100%",
        Cell: (row: any) => {
          // 승인
          const offerPk = row.row.original.offerPk;
          const nftOwner = nft?.owner;
          const confirm = async () => {
            loadingModal({
              isModal: true,
              isButton: false,
              type: "default",
              subText: `Accepting offer is being processed.\nPlease sign the transaction.\nOne moment while we process your request.`,
            });

            const res = await userApi.nft.confirmOffer({ pk: offerPk });
            resetLoadingModal();

            if (res.status !== 200) {
              alertModal({
                isModal: true,
                title: "Error",
                content: "Please connect admin.",
              });
              return;
            }
            alertModal({
              isModal: true,
              title: "Succeeded",
              content: "The acceptance of the offer has been completed",
              type: "check",
              onClick() {
                // setData((prevData) =>
                //   prevData.filter((item) => item.offerPk !== offerPk)
                // );
                setData([]);
                setNft((prevNft) => {
                  if (!prevNft) return prevNft;
                  return { ...prevNft, price: "0", owner: "" };
                });
                setUpdate((prev) => prev + 1);
              },
            });
          };

          if (address === nftOwner) {
            return (
              <Button type={"accept"} onClick={confirm}>
                Accept
              </Button>
            );
          }
          return <></>;
        },
      },
      {
        Header: "", // 거부
        accessor: "#2",
        width: "100%",
        Cell: (row: any) => {
          const offerOwner = row.row.original.ownerWallet;
          const nftOwner = nft?.owner;

          const deny = async () => {
            const offerPk = row.row.original.offerPk;

            const _subText =
              address === offerOwner
                ? "Canceling offer is being processed."
                : address === nftOwner
                ? "Rejecting offer is being processed."
                : "";
            loadingModal({
              isModal: true,
              isButton: false,
              type: "default",
              subText: `${_subText}\nPlease sign the transaction.\nOne moment while we process your request.`,
            });

            const res = await userApi.nft.denyOffer({ pk: offerPk });
            resetLoadingModal();

            if (res.status !== 200) {
              alertModal({
                isModal: true,
                title: "Error",
                content: "Please connect admin.",
              });
              return;
            }

            const _content =
              address === offerOwner
                ? "The cancellation of the offer has been completed."
                : address === nftOwner
                ? "The rejection of the offer has been completed."
                : "";

            alertModal({
              isModal: true,
              title: "Succeeded",
              content: _content,
              type: "check",
              onClick() {
                setData((prevData) =>
                  prevData.filter((item) => item.offerPk !== offerPk)
                );
                setUpdate((prev) => prev + 1);
              },
            });
          };

          if (address === offerOwner || address === nftOwner) {
            return (
              <Button type={"deny"} onClick={deny}>
                {address === offerOwner
                  ? "Cancel"
                  : address === nftOwner
                  ? "Reject"
                  : ""}
              </Button>
            );
          }
          return <></>;
        },
      },
    ],
    [address, animating, nft?.chainId, nft?.network, nft?.owner, nft?.price]
  );

  return {
    offer_FETCH,
    offer_COLUMNS,
    offer_DATA: data,
  };
};

const Header = styled.span`
  color: ${theme.colors.tableHeader};
  font-family: ${theme.fontFamily.pretendard};
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.4vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 23px;
    line-height: 26px;
  }
`;

const Other = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 500;
  font-size: 1.2vw;
  line-height: 1.3vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

const From = styled.span`
  color: ${theme.colors.amount};
  font-family: ${theme.fontFamily.roboto};
  font-weight: 600;
  font-size: 1.2vw;
  line-height: 1.3vw;
  text-align: start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1920px) {
    font-size: 20px;
    line-height: 23px;
  }
`;

const Button = styled.button<{ type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 500;
  background: ${(props) =>
    props.type === "accept" ? theme.colors.amount : "#FF1919"};
  border-radius: 10px;
  font-size: 1vw;
  line-height: 1.1vw;
  padding: 1vw;
  @media (min-width: 1920px) {
    font-size: 18px;
    line-height: 20px;
    padding: 10px;
  }
`;
