import React from 'react'
import { parseEther } from 'viem';
import { useSendTransaction } from 'wagmi';

interface nativeTransferPrams {
    toAddress: string;
    amount: string | undefined;
  }

  
export const useNativeTransfer = () => {
    const { data: sendTransactionHash, isPending: sendTransactionPending, error:sendTransactionError, sendTransaction } = useSendTransaction()

    const nativeTransfer = async({toAddress, amount}: nativeTransferPrams) =>  { 
      const to = toAddress as `0x${string}` ;
        // if(amount === undefined) return;
        const value = amount;
        sendTransaction({ to, value: parseEther(value!) }) 
      } 
      
  return {
    sendTransactionHash, sendTransactionPending, sendTransactionError,
    nativeTransfer,
  }
}