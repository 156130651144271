import { createSlice } from "@reduxjs/toolkit";

// 관리해야하는 Slice
const initialState = {
    email: '',
    isAdmin: false,
    token: ''
}

/**
 * 관리할 상태를 지정합니다.
 */
export const UserSlice = createSlice({
    name: 'User',
    initialState,
    reducers: {
        clear(state) {
            state.email = '';
            state.isAdmin = false;
            state.token = '';
        },
        login(state, action) {
            state.email = action.payload.email;
            state.isAdmin = action.payload.isAdmin;
            state.token = action.payload.token;
        },
    },
});

export const { clear, login } = UserSlice.actions

export default UserSlice.reducer