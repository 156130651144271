import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SelectBoxItemProps } from "../../interface/components/interface";
import theme from "../../theme";
import { selectBoxItem, useList } from "../../contexts/ListContext";

interface SelectBoxProps {
  items: SelectBoxItemProps[];
  selectedOption: SelectBoxItemProps;
  setSelectedOption: Dispatch<SetStateAction<SelectBoxItemProps>>;
}

export const SelectBox = ({type}: {type?: string}) => {
  const navigate = useNavigate();
  const {selectedOption, setSelectedOption} = useList();

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (item: SelectBoxItemProps) => {
    setSelectedOption(item);
    setIsOpen(false);
    
    const params = new URLSearchParams(location.search);
    params.set('order', item.value);

    navigate({
      search: params.toString(),
    });
  };

  return (
    <SelectTop type={type}>
      <SelectBoxWrapper>
        <SelectBoxHeader onClick={toggleDropdown}>
          {selectedOption.label}
          <Arrow $isOpen={isOpen} />
        </SelectBoxHeader>
        <OptionsContainer $isOpen={isOpen} type={type}>
          {selectBoxItem.map((item) => (
            <Option key={item.value} onClick={() => handleOptionClick(item)}>
              {item.label}
            </Option>
          ))}
        </OptionsContainer>
      </SelectBoxWrapper>
    </SelectTop>
  );
};

const SelectTop = styled.div<{type?: string}>`
  position: relative;
  @media (max-width: 680px) {
    display: ${props => props.type === 'profile' ? 'block' : 'none'};
  }

`;
const SelectBoxWrapper = styled.div`
  display: inline-block;
  width: 200px;
  background-color: ${theme.colors.side};
  color: ${theme.colors.white};
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
`;

const SelectBoxHeader = styled.div`
  position: relative;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

interface ArrowProps {
  $isOpen: boolean;
}

const Arrow = styled.div<ArrowProps>`
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ $isOpen }) => ($isOpen ? "none" : "6px solid white")};
  border-bottom: ${({ $isOpen }) => ($isOpen ? "6px solid white" : "none")};
`;

interface OptionsContainerProps {
  $isOpen: boolean;
  type?: string;
}

const OptionsContainer = styled.div<OptionsContainerProps>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #2c2f33;
  border: 1px solid ${theme.colors.side};
  border-top: none;
  z-index: 10000;
  @media (max-width: 680px) {
    display: ${props => props.type === 'profile' ? '' : 'none'};
  }
`;

const Option = styled.div`
  position: relative;
  padding: 10px;
  &:hover {
    background-color: #40444b;
  }
`;
