import React, { useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { assets } from "../../theme/assets";
import theme from "../../theme";
import { RingLoader, BounceLoader, PuffLoader, PropagateLoader, MoonLoader, ClipLoader } from "react-spinners";

interface RetryingImgProps {
  src: string;
  alt?: string;
  onContextMenu?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  maxRetries?: number;
  width?: string;
  height?: string;
  animation?: boolean;
  onClick?: () => void;
}

const retry = [
  "https://gateway.pinata.cloud/ipfs/",
  "https://ipfs.io/ipfs/",
  "https://ipfs.infura.io/ipfs/",
  "https://cloudflare-ipfs.com/ipfs/",
  "https://dweb.link/ipfs/",
  "https://4everland.io/ipfs/"
];

const memoryCache = new Map<string, string>();



const RetryingImg = forwardRef<HTMLImageElement, RetryingImgProps>(
  (
    { src, alt = "", onContextMenu, maxRetries = 6, height, width, animation = false, onClick },
    ref
  ) => {

    const [isLoaded, setIsLoaded] = useState(false);

    const [isCancelled, setIsCancelled] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [currentSrc, setCurrentSrc] = useState(src);


    const setCache = (key: string, value: string) => {
      if(value !== assets.logo){
        memoryCache.set(key, value);
        localStorage.setItem(key, value);
      }
    };
    
    const getCache = (key: string): string | null => {
      if (memoryCache.has(key)) {
        return memoryCache.get(key) || null;
      }
      const value = localStorage.getItem(key);
      if (value) {
        memoryCache.set(key, value);
      }
      setIsLoaded(true);

      return value;
    };
    
    useEffect(() => {
      const img = new Image();

      const cachedSrc = getCache(src);
      img.src = currentSrc;
      if (cachedSrc) {
        setCurrentSrc(cachedSrc);
        setIsLoaded(true);
        setIsCancelled(true);
        return;
      }else{
        img.onerror = handleImageError;
      }

      img.onload = () => setCache(src, currentSrc);

    
      return () => {
        setIsCancelled(true);
        img.onerror = null;
      };
    }, [src]);

    
    const handleImageError = () => {
      if (retryCount <= maxRetries && !isCancelled) {
        setTimeout(() => {
          if (!isCancelled) {
            setRetryCount(prevCount => prevCount + 1);
            setCurrentSrc(retryCount < 6 ? `${retry[retryCount]}${src}` : assets.logo);
          }
        }, 1000);
      }
    };
    
    return (

      !isLoaded ?
      <LoaderContainer>
        <PuffLoader style={{overflow: 'visible'}} color={theme.colors.gray2} />;
      </LoaderContainer>
    :
        <StyledImg
        $animation={animation}
        ref={ref}
        src={currentSrc}
        alt={alt}
        onContextMenu={onContextMenu}
        height={height}
        onLoad={() => setIsLoaded(true)}
        width={width}
        onClick={() => {
          if (onClick) {
            onClick();
          }
        }}
      />
    );
  }
);

const StyledImg = styled.img<{ height?: string; width?: string; $animation: boolean }>`
  width: ${props => props.width ?? '100%'};
  height: ${props => props.height ?? '100%'};
  min-height: ${props => props.height ?? '30vh'};
  object-fit: cover;
  background-color: transparent;
  border-radius: 16px 16px 0 0;
  cursor: pointer;
  transition: ${(props) => (props.$animation ? "transform 1.5s" : "")};

  &:hover {
    transform: ${(props) => (props.$animation ? "scale(1.2)" : "")};
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; 
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
`;


export default RetryingImg;
