import React from 'react';
import styled from 'styled-components';
import { chainList } from '../../../controller/chainId';
import { addComma, removeComma, setComma } from '../../../utils/Comma';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { userRegisterNFTInput } from '../../NFTItem/NFTPrice';
import theme from '../../../theme';
import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../redux/RootReducer';

interface RegisterModalProps {
    getValues: UseFormGetValues<userRegisterNFTInput>;
    setValue: UseFormSetValue<userRegisterNFTInput>;
    id: number;
  }

export const RegisterModal = ({
    getValues,
    setValue,
    id
}: RegisterModalProps) => {

  const [price, setPrice] = React.useState('');

  const setting = useSelector((state: RootReducerState) => state.setting);

  React.useEffect(() => {
    setPrice(addComma(getValues('price') || ''));
  }, [getValues]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const formattedValue = removeComma(rawValue);
    setPrice(addComma(formattedValue));
    setValue('price', formattedValue);
  };


    return (
      <FormContainer>
        <Form>
          <FormRow>
            <Label>Price</Label>
            <InputWrapper>
            <Input
              type="text"
              value={price}
              onChange={handleChange}
            />
            <Unit>{chainList[id].symbol}</Unit>
          </InputWrapper>
          </FormRow>
          <FormRow>
        <SSubSpan>* The transaction relay fee is {setting.fee}%.</SSubSpan>
        </FormRow>
        </Form>
      </FormContainer>
    );
  };


const FormContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

const Form = styled.form`
padding: 20px;
border-radius: 8px;
text-align: center;
width: 100%;

`;

const FormRow = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 20px;
gap: 10px;
@media (max-width: 680px) {
  margin-bottom: 10px;
  }
`;

const Label = styled.label`
font-size: 14px;
flex: 1;
text-align: start;
width: 150px;
color: #fff;
`;

const Input = styled.input`
flex: 2;
padding: 8px;
border: 1px solid #4B4F6B;
border-radius: 4px;
background-color: #4B4F6B;
color: #fff;
`;
const InputWrapper = styled.div`
  position: relative;
  flex: 2;
  display: flex;
  align-items: center;
`;

const Unit = styled.span`
  position: absolute;
  right: 10px; // input 요소의 오른쪽에 단위를 배치
  color: #acacac;
  font-weight: bold;
  font-size: 14px;
`;


const SSubSpan = styled.span`
  padding: 3px 0;
  font-size: 12px;
  color: ${theme.colors.amount};
`;