import { ImageItemList, NFTItemProps, SelectBoxItemProps, TraitItemProps } from "./interface/components/interface";
import theme from "./theme";
import { assets } from "./theme/assets";

export const homeImageData: ImageItemList[] = [
    {
      image: assets.images.homeTop,
      path: '',
      decorate: true,
      background: theme.colors.primary,
    },
    {
      image: assets.images.homeBottom,
      path: '',
      decorate: false,
      background: theme.colors.secondary,
    }
  ];


  export const nftData: NFTItemProps[] = [
    {
      pk: 1,
      network: 'ETH',
      owner: '0x94EA19828564CDEF4aF792e6F77c8b79F0004A21',
      tokenAddress: '0x94EA19828564CDEF4aF792e6F77c8b79F0004A21',
      tokenId: 1,
      price: '0.0000001',
      name: 'Burst Drop',
      image: 'mVH2V64QVt4XxD6s67DkY5cUSaP38TKdqevrTC6jHtmTG', category: "",
      description: "",
      series: "",
      userWalletAddress: "",
      chainId: 0,
      lastDate: 0,
      anotherList: [],
      tokenName: ""
    },
    {
      pk: 2,
      network: 'ETH',
      owner: '0x94EA19828564CDEF4aF792e6F77c8b79F0004A21',
      tokenAddress: '0x94EA19828564CDEF4aF792e6F77c8b79F0004A21',
      tokenId: 2,
      price: '0.0000002',
      image: 'mVH2V64QVt4XxD6s67DkY5cUSaP38TKdqevrTC6jHtmTG', category: "",
      description: "",
      name: "",
      series: "",
      userWalletAddress: "",
      chainId: 0,
      lastDate: 0,
      anotherList: [],
      tokenName: ""
    },
   
  ]
  

  export const chartData = [
    { name: '7월 22일', volume: 1.2, averagePrice: 0.8 },
  { name: '7월 23일', volume: 0.8, averagePrice: 0.4 },
  { name: '7월 24일', volume: 1.5, averagePrice: 0.9 },
  { name: '7월 25일', volume: 1.0, averagePrice: 0.5 }
  ]
  

  export const tableOfferData = [
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.2',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1231',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '3.1',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
    {
      price: '00.00',
      usdPrice: '8762.52',
      quantity: 1,
      floorDifference: 'about 1 month',
      expiration: 'about 1 month',
      from: '78264543',
    },
  ];
  

  export const traitItemData:TraitItemProps[] = [
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait"
    },
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait"
    },
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait"
    },
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait"
    },
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait"
    },
    {
      label: "artifact",
      title: "Magic Power",
      sub: "0.45% have this trait123123123123123123123123"
    },
  ];

